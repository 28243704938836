import { createReducer } from '@reduxjs/toolkit'
import {
  GetCreateEventEventAction,
  GetHomeStackEventAction,
  GetMoreStackEventAction,
  GetOpenSubscriptionModalEventAction,
  GetPetProfileSectionsEventAction,
  GetPetsSharingEventAction,
  GetPetsSharingInAppEventAction,
  GetPetsStackEventAction,
  GetProfileSectionsEventAction,
  GetServicesEventAction,
  GetServicesStackEventAction,
} from './action'
import { TInitialState } from './types'

const InitialState: TInitialState = {
  moreStack: null,
  servicesStack: null,
  petsStack: null,
  profileSections: null,
  services: null,
  homeStack: null,
  petProfileSections: null,
  openSubscriptionModal: null,
  petsSharing: null,
  createEvent: null,
  petsSharingInApp: null,
}

export const eventsboardReducer = createReducer<TInitialState>(
  InitialState,
  builder => {
    builder.addCase(GetMoreStackEventAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(GetMoreStackEventAction.success, (state, { payload }) => ({
      ...state,
      moreStack: payload,
      loading: false,
    }))
    builder.addCase(GetMoreStackEventAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(GetServicesStackEventAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(
      GetServicesStackEventAction.success,
      (state, { payload }) => ({
        ...state,
        servicesStack: payload,
        loading: false,
      }),
    )
    builder.addCase(
      GetServicesStackEventAction.failure,
      (state, { payload }) => ({
        ...state,
        error: payload,
        loading: false,
      }),
    )

    builder.addCase(GetPetsStackEventAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(GetPetsStackEventAction.success, (state, { payload }) => ({
      ...state,
      petsStack: payload,
      loading: false,
    }))
    builder.addCase(GetPetsStackEventAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(GetProfileSectionsEventAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(
      GetProfileSectionsEventAction.success,
      (state, { payload }) => ({
        ...state,
        profileSections: payload,
        loading: false,
      }),
    )
    builder.addCase(
      GetProfileSectionsEventAction.failure,
      (state, { payload }) => ({
        ...state,
        error: payload,
        loading: false,
      }),
    )

    builder.addCase(GetServicesEventAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(GetServicesEventAction.success, (state, { payload }) => ({
      ...state,
      services: payload,
      loading: false,
    }))
    builder.addCase(GetServicesEventAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(GetHomeStackEventAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(GetHomeStackEventAction.success, (state, { payload }) => ({
      ...state,
      homeStack: payload,
      loading: false,
    }))
    builder.addCase(GetHomeStackEventAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(GetPetProfileSectionsEventAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(
      GetPetProfileSectionsEventAction.success,
      (state, { payload }) => ({
        ...state,
        petProfileSections: payload,
        loading: false,
      }),
    )
    builder.addCase(
      GetPetProfileSectionsEventAction.failure,
      (state, { payload }) => ({
        ...state,
        error: payload,
        loading: false,
      }),
    )

    builder.addCase(GetOpenSubscriptionModalEventAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(
      GetOpenSubscriptionModalEventAction.success,
      (state, { payload }) => ({
        ...state,
        openSubscriptionModal: payload,
        loading: false,
      }),
    )
    builder.addCase(
      GetOpenSubscriptionModalEventAction.failure,
      (state, { payload }) => ({
        ...state,
        error: payload,
        loading: false,
      }),
    )

    builder.addCase(GetPetsSharingEventAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(
      GetPetsSharingEventAction.success,
      (state, { payload }) => ({
        ...state,
        petsSharing: payload,
        loading: false,
      }),
    )
    builder.addCase(
      GetPetsSharingEventAction.failure,
      (state, { payload }) => ({
        ...state,
        error: payload,
        loading: false,
      }),
    )

    builder.addCase(GetCreateEventEventAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(
      GetCreateEventEventAction.success,
      (state, { payload }) => ({
        ...state,
        createEvent: payload,
        loading: false,
      }),
    )
    builder.addCase(
      GetCreateEventEventAction.failure,
      (state, { payload }) => ({
        ...state,
        error: payload,
        loading: false,
      }),
    )

    builder.addCase(GetPetsSharingInAppEventAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(
      GetPetsSharingInAppEventAction.success,
      (state, { payload }) => ({
        ...state,
        petsSharingInApp: payload,
        loading: false,
      }),
    )
    builder.addCase(
      GetPetsSharingInAppEventAction.failure,
      (state, { payload }) => ({
        ...state,
        error: payload,
        loading: false,
      }),
    )
  },
)
