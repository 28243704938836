import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { usePlacesWidget } from 'react-google-autocomplete'
import { useDispatch } from 'react-redux'

import { Assets } from '../../assets'
import { Divider, EInputTemplate, Input } from '../../components'
import { useTypedSelector, useValidation } from '../../hooks'
import {
  getCategoriesAdsAction,
  getSubCategoriesAdsAction,
} from '../../store/categoriesAds'
import { getCustomersAction, getOwnPetsAction } from '../../store'

import {
  Container,
  StyledLinkDiv,
  StyledTitle,
  Image,
  Title,
  Div,
  Label,
  StyledEntity,
  StyledFormWrapper,
  StyledInputWrapper,
  StyledTextArea,
  StyledSaveButton,
  StyledContainer,
  DeleteButton,
  SaveButton,
  BottomDiv,
  Select,
  InputAccess,
  InputRef,
  ButtonCreate,
  HeaderModal,
  HeaderTitle,
  Close,
  BodyModal,
  FooterModal,
} from './styled'
import { TForm } from './types'
import {
  createAdsAction,
  deleteAdsAction,
  getAdsByIdAction,
  updateAdsAction,
} from '../../store/ads'
import { ErrorBlock, Error } from '../CategoriesAds/styled'
import { Modal } from '../../components/Modal'

export const AdvertisementProfile = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const dispatch = useDispatch()

  const { subCategoriesAds, categoriesAds } = useTypedSelector(
    state => state.categories,
  )
  const { adsOne } = useTypedSelector(state => state.ads)
  const { customers } = useTypedSelector(state => state.customers)
  const { ownPets } = useTypedSelector(state => state.pets)

  const [openModal, setOpenModal] = useState(false)
  const [commetComeration, setCommentComeration] = useState('')

  const [access, setAccess] = useState<
    {
      value: string
      name: string
      checked: boolean
    }[]
  >([
    { value: 'passportInfo', name: 'Паспорт - сведения', checked: false },
    { value: 'vaccination', name: 'Вакцинация', checked: false },
    { value: 'allergy', name: 'Аллергии', checked: false },
    {
      value: 'document',
      name: 'Документы в “Паспорт питомца”',
      checked: false,
    },
    { value: 'doctorConsultation', name: 'Визиты к врачу', checked: false },
    { value: 'medicalDoc', name: 'Мед. документы', checked: false },
    { value: 'indicator', name: 'Отслеживание показателей', checked: false },
  ])

  const [form, setForm] = useState<TForm>({
    description: '',
    title: '',
    latitude: 0,
    longitude: 0,
    country: '',
    subCategory: '',
    category: '',
    currency: '',
    price: '0',
    owner: '',
    pet: '',
  })

  const schema = useMemo<any>(
    () => ({
      subCategory: {
        condition: !!form?.subCategory,
        error: 'Выберите подкатегорию',
      },
      description: {
        condition: form?.description?.length > 16,
        error: 'Описание должно содержать более 16 символов',
      },
      price: {
        condition: form?.price?.toString()?.length <= 7,
        error: 'Цена должна быть не больше 7 чисел',
      },
    }),
    [form],
  )

  const {
    validationSchema,
    validation,
    enableValidation,
    isEnabled,
    disableValidation,
    //eslint-disable-next-line
  } = useValidation(schema)

  const handleBack = () => {
    history.goBack()
  }

  const inputChangeHandler = (e: any) => {
    const { value, name } = e.target
    setForm(prevState => ({ ...prevState, [name]: value }))
  }

  const { ref }: any = usePlacesWidget({
    apiKey: 'AIzaSyDiVh218waTaUJqMExezt3uALFphAmjwsM',
    options: {
      types: ['country', 'locality'],
    },
    language: 'uk',
    onPlaceSelected: place => {
      const addressComponents = place.address_components
      let country = ''
      let city = ''

      addressComponents?.forEach((component: any) => {
        if (component.types.includes('country')) {
          country = component.long_name
        }
        if (component.types.includes('locality')) {
          city = component.long_name
        }
      })

      const formattedAddress = `${country}, ${city}`
      setForm((prevState: any) => ({ ...prevState, country: formattedAddress }))
      setForm((prevState: any) => ({
        ...prevState,
        latitude: place?.geometry?.location?.lat(),
      }))
      setForm((prevState: any) => ({
        ...prevState,
        longitude: place?.geometry?.location?.lng(),
      }))
    },
  })

  useEffect(() => {
    dispatch(
      getCategoriesAdsAction.request({
        limit: 500,
        page: 1,
        sortBy: '',
      }),
    )
  }, [])

  useEffect(() => {
    if (id !== 'undefined') {
      dispatch(getAdsByIdAction.request({ id }))
    }
  }, [])

  useEffect(() => {
    dispatch(
      getSubCategoriesAdsAction.request({
        limit: 500,
        page: 1,
        sortBy: '',
        category: form.category,
      }),
    )
  }, [form.category])

  useEffect(() => {
    dispatch(
      getCustomersAction.request({
        limit: 4000,
        sortBy: ' ',
        page: 1,
      }),
    )
  }, [])

  useEffect(() => {
    adsOne?.owner?.id &&
      dispatch(getOwnPetsAction.request({ id: adsOne?.owner?.id }))
  }, [adsOne?.owner?.id])

  useEffect(() => {
    if (id !== 'undefined') {
      setForm({
        currency: adsOne?.currency,
        description: adsOne?.description,
        subCategory: adsOne?.subcategory?._id,
        category: adsOne?.category?._id,
        title: adsOne?.title,
        owner: adsOne?.owner?.id,
        country: adsOne?.city,
        pet: adsOne?.pet?._id ?? '',
        longitude: adsOne?.location?.coordinates[0],
        latitude: adsOne?.location?.coordinates[1],
        price: adsOne?.price,
      })
      setAccess(prevState =>
        prevState.map(item =>
          adsOne?.access?.includes(item.value)
            ? { ...item, checked: true }
            : item,
        ),
      )
    }
  }, [id, adsOne])

  const selectedAccess = access.filter(item => item.checked)
  const accessValues = selectedAccess.map(item => item.value)

  const createAdsHandler = async () => {
    enableValidation()
    await validation()

    const formData = new FormData()
    formData.append('title', form.title)
    formData.append('description', form.description)
    formData.append('price', form.price)
    formData.append('city', form.country)
    formData.append('category', form.category)
    formData.append('subcategory', form.subCategory)
    formData.append('currency', form.currency)
    form.pet && formData.append('pet', form.pet)
    formData.append('owner', form.owner)
    formData.append('longitude', form.longitude as any)
    formData.append('latitude', form.latitude as any)
    if (accessValues.length) {
      accessValues.map(el => formData.append('access[]', el))
    }
    dispatch(createAdsAction.request({ data: formData }))
    disableValidation()
    history.push('/advertisement')
  }

  console.log(form.pet, 'pet')
  console.log('form', form)

  console.log(adsOne, 'adsOne')

  const onActiveStatus = async () => {
    enableValidation()
    await validation()

    const formData = new FormData()
    formData.append('title', form.title)
    formData.append('description', form.description)
    formData.append('price', form.price)
    formData.append('city', form.country)
    formData.append('category', form.category)
    formData.append('subcategory', form.subCategory)
    formData.append('currency', form.currency)
    formData.append('owner', form.owner)
    formData.append('longitude', form.longitude as any)
    formData.append('latitude', form.latitude as any)
    formData.append('status', 'active')
    if (accessValues.length) {
      accessValues.map(el => formData.append('access[]', el))
    }
    dispatch(updateAdsAction.request({ data: formData, id }))
    disableValidation()
    history.push('/advertisement')
  }

  const onPressSaveChanges = async () => {
    enableValidation()
    await validation()

    const formData = new FormData()
    formData.append('title', form.title)
    formData.append('description', form.description)
    formData.append('price', form.price)
    formData.append('city', form.country)
    formData.append('category', form.category)
    formData.append('subcategory', form.subCategory)
    formData.append('currency', form.currency)
    form.pet && formData.append('pet', form.pet)
    formData.append('owner', form.owner)
    formData.append('longitude', form.longitude as any)
    formData.append('latitude', form.latitude as any)
    if (accessValues.length) {
      accessValues.map(el => formData.append('access[]', el))
    }
    dispatch(updateAdsAction.request({ data: formData, id }))
    disableValidation()
    history.push('/advertisement')
  }

  const onModerationStatusChanges = async () => {
    enableValidation()
    await validation()

    const formData = new FormData()
    formData.append('title', form.title)
    formData.append('description', form.description)
    formData.append('price', form.price)
    formData.append('city', form.country)
    formData.append('category', form.category)
    formData.append('subcategory', form.subCategory)
    formData.append('currency', form.currency)
    formData.append('owner', form.owner)
    formData.append('longitude', form.longitude as any)
    formData.append('latitude', form.latitude as any)
    formData.append('moderatorComment', commetComeration)
    formData.append('status', 'moderation')
    if (accessValues.length) {
      accessValues.map(el => formData.append('access[]', el))
    }
    dispatch(updateAdsAction.request({ data: formData, id }))
    disableValidation()
    history.push('/advertisement')
  }

  const onPressDeleteClinic = () => {
    dispatch(deleteAdsAction({ id }))
    history.push('/advertisement')
  }

  return (
    <>
      <Container>
        <StyledTitle>
          {Assets.LEFT_ARROW_ICON ? (
            <StyledLinkDiv onClick={handleBack}>
              <Image src={Assets.LEFT_ARROW_ICON} />
            </StyledLinkDiv>
          ) : (
            ''
          )}

          <Title>Объявление</Title>

          <Div> </Div>
        </StyledTitle>

        <Divider height={10} />

        <StyledEntity>
          <Input
            label={'Название'}
            name="title"
            value={form.title ?? ''}
            placeholder="Введите название"
            template={EInputTemplate.NAME}
            onChange={inputChangeHandler}
          />
        </StyledEntity>

        <Divider height={10} />

        <StyledInputWrapper>
          <Label>Описание</Label>

          <Divider height={10} />

          <StyledTextArea
            required
            name="description"
            placeholder="Введите описание"
            value={form.description ?? ''}
            onChange={inputChangeHandler}
          ></StyledTextArea>
          <ErrorBlock>
            {!(!isEnabled || validationSchema.description) && (
              <Error>{schema.description.error}</Error>
            )}
          </ErrorBlock>
        </StyledInputWrapper>

        <StyledFormWrapper>
          <StyledEntity>
            <Input
              label={'Цена'}
              name="price"
              value={form.price ?? ''}
              placeholder="Введите цену"
              template={EInputTemplate.NUMBER}
              onChange={inputChangeHandler}
              error={schema.price.error || ''}
              isValid={!isEnabled || validationSchema.price}
            />
          </StyledEntity>
          <StyledEntity>
            <Label>Валюта</Label>
            <Select
              name="currency"
              value={form.currency ?? ''}
              placeholder="Введите валюту"
              onChange={inputChangeHandler}
              defaultValue={''}
            >
              <option disabled value=""></option>
              <option value="UAH">UAH</option>
              <option value="EUR">EUR</option>
              <option value="USD">USD</option>
            </Select>
          </StyledEntity>
        </StyledFormWrapper>

        <StyledFormWrapper>
          <StyledEntity>
            <Label>Локация</Label>
            <InputRef
              ref={ref}
              defaultValue={form.country}
              placeholder="Выбрать локацию"
            />
          </StyledEntity>
        </StyledFormWrapper>

        <StyledFormWrapper>
          <StyledEntity>
            <Label>Категория</Label>
            <Select
              name="category"
              value={form.category ?? ''}
              placeholder="Выберите подкатегорию"
              onChange={e =>
                setForm(prevState => ({
                  ...prevState,
                  category: e.target.value,
                  subCategory: '',
                }))
              }
            >
              <option disabled value=""></option>
              {categoriesAds?.map(el => (
                <option value={el?._id}>{el?.title[0]?.value}</option>
              ))}
            </Select>
          </StyledEntity>
          <StyledEntity>
            <Label>Подкатегория</Label>
            <Select
              name="subCategory"
              value={form.subCategory ?? ''}
              placeholder="Выберите подкатегорию"
              disabled={!form.category}
              onChange={inputChangeHandler}
            >
              <option disabled value=""></option>
              {subCategoriesAds?.map((el: any) => (
                <option value={el?._id}>{el?.title[0]?.value}</option>
              ))}
            </Select>
            <ErrorBlock>
              {!(!isEnabled || validationSchema.subCategory) && (
                <Error>{schema.subCategory.error}</Error>
              )}
            </ErrorBlock>
          </StyledEntity>
        </StyledFormWrapper>

        <StyledFormWrapper>
          <StyledEntity>
            <Label>Владелец</Label>
            <Select
              name="owner"
              value={form.owner ?? ''}
              placeholder="Выберите владельца"
              onChange={e =>
                setForm(prevState => ({
                  ...prevState,
                  owner: e.target.value,
                  pet: '',
                }))
              }
            >
              <option disabled value=""></option>
              {customers?.map(el => (
                <option value={el?._id}>{el?.name}</option>
              ))}
            </Select>
          </StyledEntity>
          <StyledEntity>
            <Label>Домашний питомец</Label>
            <Select
              name="pet"
              value={form.pet ?? ''}
              placeholder="Выберите подкатегорию"
              onChange={inputChangeHandler}
            >
              <option disabled value=""></option>
              {ownPets?.map((el: any) => (
                <option value={el?._id}>{el?.name}</option>
              ))}
            </Select>
          </StyledEntity>
        </StyledFormWrapper>
        <Divider height={20} />

        {adsOne?.moderatorComment && (
          <StyledTextArea
            required
            disabled
            placeholder="Введите описание"
            value={adsOne?.moderatorComment ?? ''}
          ></StyledTextArea>
        )}

        <Divider height={20} />

        {form.pet && (
          <>
            <Label>Доступ к просмотру данных</Label>
            <Divider height={15} />
            {access.map(item => (
              <div key={item.value}>
                <label>
                  <InputAccess
                    type="checkbox"
                    checked={item.checked}
                    onChange={() => {
                      const newAccess = [...access]
                      const index = newAccess.findIndex(
                        a => a.value === item.value,
                      )
                      newAccess[index].checked = !newAccess[index].checked
                      setAccess(newAccess)
                    }}
                  />
                  {item.name}
                </label>
              </div>
            ))}
          </>
        )}

        <Divider height={20} />

        {id === 'undefined' ? (
          <StyledSaveButton onClick={createAdsHandler}>
            Создать
          </StyledSaveButton>
        ) : (
          <StyledContainer>
            <DeleteButton onClick={onPressDeleteClinic}>Удалить</DeleteButton>
            {adsOne?.status !== 'active' && adsOne?.status !== 'archived' && (
              <ButtonCreate onClick={() => onActiveStatus()}>
                Опубликовать
              </ButtonCreate>
            )}
            {adsOne?.status !== 'active' &&
              !adsOne?.moderatorComment &&
              adsOne?.status !== 'archived' && (
                <ButtonCreate onClick={() => setOpenModal(true)}>
                  Отказ
                </ButtonCreate>
              )}

            <SaveButton onClick={() => onPressSaveChanges()}>
              Сохранить
            </SaveButton>
          </StyledContainer>
        )}

        {openModal && (
          <Modal>
            <HeaderModal>
              <HeaderTitle>Причина</HeaderTitle>
              <Close onClick={() => setOpenModal(false)}>&#x2716;</Close>
            </HeaderModal>
            <BodyModal>
              <StyledTextArea
                required
                name="commmetComeration"
                placeholder="Введите причину, по которой объявление не прошло модерацию"
                value={commetComeration}
                onChange={e => setCommentComeration(e.target.value)}
              ></StyledTextArea>
            </BodyModal>
            <FooterModal>
              <ButtonCreate onClick={onModerationStatusChanges}>
                Отправить
              </ButtonCreate>
            </FooterModal>
          </Modal>
        )}
        <Divider height={10} />
        <BottomDiv />
      </Container>
    </>
  )
}
