import { createActionTypes, createApiActions } from '../actions-util'
import { createAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import {
  GetAdminsActionResponse,
  CreateAdminActionPayload,
  UpdateAdminActionPayload,
  DeleteAdminActionPayload,
  GetAdminByIdActionPayload,
} from './types'

export const getAdminsAction = createApiActions<
  void,
  GetAdminsActionResponse,
  AxiosError
>(createActionTypes('ADMINS/GET_ADMINS'))

export const createAdminAction = createAction<
  CreateAdminActionPayload,
  'ADMINS/CREATE_ADMIN'
>('ADMINS/CREATE_ADMIN')

export const getAdminByIdAction = createApiActions<
  GetAdminByIdActionPayload,
  GetAdminsActionResponse,
  AxiosError
>(createActionTypes('ADMINS/GET_ADMIN_BY_ID'))

export const clearAdminAction = createAction<void, 'ADMINS/CLEAR_ADMIN'>(
  'ADMINS/CLEAR_ADMIN',
)

export const updateAdminAction = createAction<
  UpdateAdminActionPayload,
  'ADMINS/UPDATE_ADMIN'
>('ADMINS/UPDATE_ADMIN')

export const deleteAdminAction = createAction<
  DeleteAdminActionPayload,
  'ADMINS/DELETE_ADMIN'
>('ADMINS/DELETE_ADMIN')
