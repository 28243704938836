import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Table from 'rc-table'
import { Divider, EInputTemplate, Input } from '../../components'
import {
  Container,
  FullWidth,
  Header,
  StyledButton,
  StyledButtonEnd,
  StyledHeader,
  StyledNumberLink,
  StyledPagination,
  StyledRow,
  Image,
  StyledTable,
  TableWrapper,
  Title,
  StyledEntity,
  SortImage,
  StyledSortName,
} from './styled'
import { getClinicsAction } from '../../store'
import { useTypedSelector } from '../../hooks'
import { TypedClinics, TSort } from './types'
import { RoutesTree } from '../../router'
import { Redirect } from 'react-router-dom'
import { Assets } from '../../assets'

const SocialClinics: React.FC = () => {
  const [typedClinics, setTypedClinics] = useState<TypedClinics[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [paginationLimit, setPaginationLimit] = useState<number>(20)
  const [searchPage, setSearchPage] = useState<number>(1)
  const [sort, setSort] = useState<TSort>({
    title: false,
    type: false,
    rating: false,
  })
  const [sortName, setSortName] = useState<string>('')
  const [search, setSearch] = useState<string>('')

  const dispatch = useDispatch()

  const { clinics, total } = useTypedSelector(state => state.clinics)
  const { user } = useTypedSelector(state => state.user)

  if (!user?.access?.clinics) {
    return <Redirect to={RoutesTree.notFound.path} />
  }
  // eslint-disable-next-line
  useEffect(() => {
    const localValues = localStorage.getItem('clinics') ?? ''
    const parseLocalValues = !!localValues ? JSON.parse(localValues) : ''
    // eslint-disable-next-line
    !!localValues
      ? (setCurrentPage(parseLocalValues.page),
        setSearchPage(parseLocalValues.page),
        setSort(parseLocalValues.sort),
        setSortName(parseLocalValues.sortName),
        setSearch(parseLocalValues.search),
        dispatch(
          getClinicsAction.request({
            page: parseLocalValues.page,
            limit: paginationLimit,
            search: parseLocalValues.search,
            sortBy: parseLocalValues.sortName,
            type: 'SOCIAL',
          }),
        ))
      : dispatch(
          getClinicsAction.request({
            page: currentPage,
            limit: paginationLimit,
            search,
            sortBy: '',
            type: 'SOCIAL',
          }),
        )
  }, [])

  const sortByParam = (name: string) => {
    setSortName(sort[name as keyof TSort] ? name + 'ASC' : name + 'DESC')

    setSort(prev => ({ ...prev, [name]: !prev[name as keyof TSort] }))

    localStorage.setItem(
      'clinics',
      JSON.stringify({
        page: currentPage,
        sort: { ...sort, [name]: !sort[name as keyof TSort] },
        sortName: sort[name as keyof TSort] ? name + 'ASC' : name + 'DESC',
        search,
      }),
    )

    dispatch(
      getClinicsAction.request({
        page: currentPage === 0 ? 1 : currentPage,
        limit: paginationLimit,
        search,
        sortBy: sort[name as keyof TSort] ? name + 'ASC' : name + 'DESC',
        type: 'SOCIAL',
      }),
    )
  }

  const columns = [
    {
      title: (
        <StyledSortName onClick={() => sortByParam('title')}>
          <div>Название</div>

          <Divider width={10} />

          <SortImage
            src={sort.title ? Assets.UP_ARROW_ICON : Assets.DOWN_ARROW_ICON}
          />
        </StyledSortName>
      ),
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: (
        <StyledSortName onClick={() => sortByParam('type')}>
          <div>Тип</div>

          <Divider width={10} />

          <SortImage
            src={sort.type ? Assets.UP_ARROW_ICON : Assets.DOWN_ARROW_ICON}
          />
        </StyledSortName>
      ),
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: (
        <StyledSortName onClick={() => sortByParam('rating')}>
          <div>Рейтинг клиники</div>

          <Divider width={10} />

          <SortImage
            src={sort.rating ? Assets.UP_ARROW_ICON : Assets.DOWN_ARROW_ICON}
          />
        </StyledSortName>
      ),
      dataIndex: 'rating',
      key: 'rating',
    },
    {
      title: 'Город',
      dataIndex: 'city',
      key: 'city',
    },
    {
      title: 'Страна',
      dataIndex: 'country',
      key: 'country',
    },
    {
      title: 'Местоположение',
      dataIndex: 'location',
      key: 'location',
    },
  ]

  const choosePage = (page: number) => {
    localStorage.setItem(
      'clinics',
      JSON.stringify({
        page: page === 0 ? 1 : page,
        sort: sort,
        sortName: sortName,
        search,
      }),
    )

    dispatch(
      getClinicsAction.request({
        page: page === 0 ? 1 : page,
        limit: paginationLimit,
        search,
        sortBy: sortName,
        type: 'SOCIAL',
      }),
    )

    setCurrentPage(page === 0 ? 1 : page)
  }

  const putSearchPage = (e: React.SyntheticEvent<Element, Event> | any) => {
    setSearchPage(isNaN(+e.target.value) ? 1 : +e.target.value)
  }

  const enterKeyPress = (e: any) => {
    e?.code === 'Enter' &&
      choosePage(
        searchPage <= (total === 0 ? 1 : Math.ceil(total / paginationLimit))
          ? searchPage !== currentPage
            ? searchPage
            : 1
          : 1,
      )
  }

  const putTitle = (e: any) => {
    setSearch(e.target.value)
  }

  const data = () => {
    return clinics?.map(clinic => {
      return {
        title: (
          <StyledNumberLink
            to={{ pathname: `/bisnes-cliniks-info/${clinic?._id}` }}
          >
            {clinic?.name?.ru || clinic?.name?.uk || clinic?.name?.en || '-'}
          </StyledNumberLink>
        ),
        type: (
          <StyledNumberLink
            to={{ pathname: `/bisnes-cliniks-info/${clinic?._id}` }}
          >
            {clinic?.specialisation?.ru ||
              clinic?.specialisation?.en ||
              clinic?.specialisation.uk ||
              '-'}
          </StyledNumberLink>
        ),
        rating: (
          <StyledNumberLink
            to={{ pathname: `/bisnes-cliniks-info/${clinic?._id}` }}
          >
            {/* {(clinic?.schedule?.details?.allDay ? 'круглосуточно; ' : '') +
              (clinic?.schedule?.details?.byAppointment
                ? 'по предварительной записи; '
                : '') +
              (clinic?.schedule?.details?.everyDay ? 'ежедневно' : '')} */}
            {clinic.rating ? clinic.rating.toFixed(1) : 'Нет рейтинга'}
          </StyledNumberLink>
        ),
        city: (
          <StyledNumberLink
            to={{ pathname: `/bisnes-cliniks-info/${clinic?._id}` }}
          >
            {clinic?.contactInfo?.location?.district?.ru ||
              clinic?.contactInfo?.location?.district?.uk ||
              clinic?.contactInfo?.location?.district?.en ||
              '-'}
          </StyledNumberLink>
        ),
        country: (
          <StyledNumberLink
            to={{ pathname: `/bisnes-cliniks-info/${clinic?._id}` }}
          >
            {clinic?.contactInfo?.location?.country?.ru ||
              clinic?.contactInfo?.location?.country?.uk ||
              clinic?.contactInfo?.location?.country?.en ||
              '-'}
          </StyledNumberLink>
        ),
        location: (
          <StyledNumberLink
            to={{ pathname: `/bisnes-cliniks-info/${clinic?._id}` }}
          >
            {clinic?.contactInfo?.location?.address?.ru ||
              clinic?.contactInfo?.location?.address?.uk ||
              clinic?.contactInfo?.location?.address?.en ||
              '-'}
          </StyledNumberLink>
        ),
        key: clinic?._id,
      }
    })
  }

  // eslint-disable-next-line
  useEffect(() => {
    setTypedClinics(data())
  }, [clinics])

  return (
    <Container>
      <div>
        <Header>
          <Title>Приюты ({total ?? 0})</Title>

          <StyledPagination>
            <StyledEntity onKeyPress={enterKeyPress}>
              <Input
                label="Поиск"
                name="search"
                value={search}
                placeholder="Название"
                template={EInputTemplate.NAME}
                onChange={putTitle}
              />
            </StyledEntity>
            <Divider width={10} />
            <Image
              src={Assets.SEARCH_ICON}
              onClick={() =>
                choosePage(
                  searchPage <=
                    (total === 0 ? 1 : Math.ceil(total / paginationLimit))
                    ? searchPage
                    : 1,
                )
              }
            />
          </StyledPagination>

          <StyledPagination>
            <StyledEntity onKeyPress={enterKeyPress}>
              <Input
                label="Страница"
                name="searchPage"
                value={searchPage.toString()}
                placeholder="Страница"
                template={EInputTemplate.NAME}
                onChange={putSearchPage}
              />
            </StyledEntity>
            <Divider width={10} />
            <Image
              src={Assets.SEARCH_ICON}
              onClick={() =>
                choosePage(
                  searchPage <=
                    (total === 0 ? 1 : Math.ceil(total / paginationLimit))
                    ? searchPage
                    : 1,
                )
              }
            />
          </StyledPagination>
        </Header>

        <Divider height={30} />

        <TableWrapper>
          <Table
            columns={columns}
            data={typedClinics}
            scroll={{ y: 650 }}
            components={{
              body: {
                row: StyledRow,
              },
              header: {
                cell: StyledHeader,
              },
              table: StyledTable,
            }}
          />
        </TableWrapper>

        <Divider height={20} />
      </div>

      <FullWidth>
        <StyledPagination>
          {currentPage === 1 ? (
            <StyledButtonEnd>Назад</StyledButtonEnd>
          ) : (
            <StyledButton onClick={() => choosePage(currentPage - 1)}>
              Назад
            </StyledButton>
          )}

          <div>
            {currentPage}/
            {!total
              ? 1
              : Math.ceil(total / paginationLimit) === 0
              ? 1
              : Math.ceil(total / paginationLimit)}
          </div>

          {currentPage === (!total ? 1 : Math.ceil(total / paginationLimit)) ? (
            <StyledButtonEnd>Далее</StyledButtonEnd>
          ) : (
            <StyledButton onClick={() => choosePage(currentPage + 1)}>
              Далее
            </StyledButton>
          )}
        </StyledPagination>
        <Divider height={10} />
      </FullWidth>
    </Container>
  )
}

export default SocialClinics
