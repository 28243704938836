import { AxiosResponse } from 'axios'
import { setAuthorizationHeadersProp } from '../../helpers'
import { HttpService } from '../../services'
import { DASHBOARD_URL } from './config'
import {
  GetDashboardInfoActionResponse,
  GetYearInfoActionPayload,
  GetYearInfoActionResponse,
  GetDashboardInfoActionPayload,
  PayloadAuth,
  GetActiveIosUsersPayload,
  GetActiveIosUsersResponse,
  GetActiveAndroidUsersPayload,
  GetActiveAndroidUsersResponse,
  GetActiveUsersSummaryResponse,
  GetOSesResponse,
  GetOsesPayload,
  GetSessionCountsPayload,
  GetSessionCountsResponse,
  GetIosModelsPayload,
  GetIosModelsResponse,
  GetIosSessionDurationPayload,
  GetIosSessionDurationResponse,
  GetAndroidSessionDurationPayload,
  GetAndroidSessionDurationResponse,
  sessionDuration,
  GetVersionsPayload,
  GetVersionsResponse,
  GetAnalyticPayload,
  GetUsersByMonthResponse,
  GetUsersByDateResponse,
  GetPetsByMonthResponse,
  VersionsResponse,
} from './types'

export class DashboardAPI extends HttpService {
  static getDashboardInfo(
    request: GetDashboardInfoActionPayload & PayloadAuth,
  ): Promise<AxiosResponse<GetDashboardInfoActionResponse>> {
    return this.request('GET_STATISTICS', {
      url: DASHBOARD_URL.getDashboardInfo(request.year),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static getYearInfo(
    request: PayloadAuth & GetYearInfoActionPayload,
  ): Promise<AxiosResponse<GetYearInfoActionResponse>> {
    return this.request('GET_YEAR_INFO', {
      url: DASHBOARD_URL.getYearInfo(request.year),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static getActiveIosUsers(
    request: PayloadAuth & GetActiveIosUsersPayload,
  ): Promise<AxiosResponse<GetActiveIosUsersResponse[]>> {
    return this.request('GET_ACTIVE_IOS_USERS', {
      url: DASHBOARD_URL.getActiveIosUsers,
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
      params: {
        start: request.start,
        end: request.end,
      },
    })
  }

  static getActiveAndroidUsers(
    request: PayloadAuth & GetActiveAndroidUsersPayload,
  ): Promise<AxiosResponse<GetActiveAndroidUsersResponse[]>> {
    return this.request('GET_ACTIVE_ANDROID_USERS', {
      url: DASHBOARD_URL.getActiveAndroidUsers,
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
      params: {
        start: request.start,
        end: request.end,
        app: request.app,
      },
    })
  }

  static getActiveUsersSummary(
    request: PayloadAuth,
  ): Promise<AxiosResponse<GetActiveUsersSummaryResponse[]>> {
    return this.request('GET_ACTIVE_USERS_SUMMARY', {
      url: DASHBOARD_URL.getActiveUsersSummary,
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static getOses(
    request: PayloadAuth & GetOsesPayload,
  ): Promise<AxiosResponse<GetOSesResponse[]>> {
    return this.request('GET_OSES', {
      url: DASHBOARD_URL.getOses,
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
      params: {
        start: request.start,
        end: request.end,
        app: request.app,
      },
    })
  }

  static getSessionCounts(
    request: PayloadAuth & GetSessionCountsPayload,
  ): Promise<AxiosResponse<GetSessionCountsResponse[]>> {
    return this.request('GET_SESSION_COUNTS', {
      url: DASHBOARD_URL.getSessionCounts,
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
      params: {
        start: request.start,
        end: request.end,
        app: request.app,
      },
    })
  }

  static getIosModels(
    request: PayloadAuth & GetIosModelsPayload,
  ): Promise<AxiosResponse<GetIosModelsResponse[]>> {
    return this.request('GET_IOS_MODELS', {
      url: DASHBOARD_URL.getModels,
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
      params: {
        start: request.start,
        end: request.end,
      },
    })
  }

  static getAndroidModels(
    request: PayloadAuth & GetIosModelsPayload,
  ): Promise<AxiosResponse<GetIosModelsResponse[]>> {
    return this.request('GET_ANDROID_MODELS', {
      url: DASHBOARD_URL.getModels,
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
      params: {
        start: request.start,
        end: request.end,
        app: 'android',
      },
    })
  }

  static getIosSessionDuration(
    request: PayloadAuth & GetIosSessionDurationPayload,
  ): Promise<AxiosResponse<GetIosSessionDurationResponse[]>> {
    return this.request('GET_IOS_SESSION_DURATION', {
      url: DASHBOARD_URL.getSessionDuration,
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
      params: {
        start: request.start,
        end: request.end,
      },
    })
  }

  static getAndroidSessionDuration(
    request: PayloadAuth & GetAndroidSessionDurationPayload,
  ): Promise<AxiosResponse<GetAndroidSessionDurationResponse[]>> {
    return this.request('GET_ANDROID_SESSION_DURATION', {
      url: DASHBOARD_URL.getSessionDuration,
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
      params: {
        start: request.start,
        end: request.end,
        app: 'android',
      },
    })
  }

  static getIosVersions(
    request: PayloadAuth & GetVersionsPayload,
  ): Promise<AxiosResponse<GetVersionsResponse[]>> {
    return this.request('GET_IOS_VERSIONS', {
      url: DASHBOARD_URL.getVersions,
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
      params: {
        start: request.start,
        end: request.end,
      },
    })
  }

  static getAndroidVersions(
    request: PayloadAuth & GetVersionsPayload,
  ): Promise<AxiosResponse<GetVersionsResponse[]>> {
    return this.request('GET_ANDROID_VERSIONS', {
      url: DASHBOARD_URL.getVersions,
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
      params: {
        start: request.start,
        end: request.end,
        app: 'android',
      },
    })
  }

  static getUsersByMonth(
    request: PayloadAuth & GetAnalyticPayload,
  ): Promise<AxiosResponse<GetUsersByMonthResponse[]>> {
    return this.request('GET_USERS_BY_MONTH', {
      url: DASHBOARD_URL.getUsersByMonth,
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
      params: {
        start: request.start,
        end: request.end,
      },
    })
  }

  static getUsersByDate(
    request: PayloadAuth & GetAnalyticPayload,
  ): Promise<AxiosResponse<GetUsersByDateResponse[]>> {
    return this.request('GET_USERS_BY_DATE', {
      url: DASHBOARD_URL.getUsersByDate,
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
      params: {
        start: request.start,
        end: request.end,
      },
    })
  }

  static getPetsByMonth(
    request: PayloadAuth & GetAnalyticPayload,
  ): Promise<AxiosResponse<GetPetsByMonthResponse[]>> {
    return this.request('GET_PETS_BY_MONTH', {
      url: DASHBOARD_URL.getPetsByMonth,
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
      params: {
        start: request.start,
        end: request.end,
      },
    })
  }

  static getAllVersions(
    request: PayloadAuth & GetAnalyticPayload,
  ): Promise<AxiosResponse<VersionsResponse[]>> {
    return this.request('GET_ALL_VERSIONS', {
      url: DASHBOARD_URL.getAllVersions,
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
      params: {
        start: request.start,
        end: request.end,
        app: request.app,
      },
    })
  }
}
