import { COLORS } from './../../assets/colors'
import styled from 'styled-components'
import { TStyledButton } from './types'
import { FLEX, FONT } from '../../utils'

export const StyledButton = styled.div<TStyledButton>`
  cursor: pointer;

  padding: 14px 16px;

  border-radius: 3px;

  background: ${({ type }) => (type === 'submit' ? COLORS.green : COLORS.gray)};

  ${FLEX({})}
`

export const Label = styled.span`
  ${FONT({ size: '14px', weight: '700', color: COLORS.white })}
`
