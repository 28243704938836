import styled from 'styled-components'
import { COLORS } from '../../assets'
import { FLEX, FONT } from '../../utils'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  width: 100%;
  height: 100%;
`

export const Title = styled.div`
  ${FONT({ size: '32px', color: COLORS.lightBlue, weight: '700' })}

  width: 100%;

  ${FLEX({})}
`

export const StyledEntity = styled.div`
  width: 100%;

  ${FONT({ size: '18px', color: COLORS.lightBlue, weight: '700' })}
`

export const StyledCheckboxesContainer = styled.div`
  width: 100%;

  ${FLEX({ direction: 'row', justify: 'flex-start' })}
`

export const StyledText = styled.div`
  ${FONT({ size: '14px', color: COLORS.lightBlue, weight: '700' })}
`

export const CheckboxImage = styled.img`
  width: 20px;
  height: 20px;

  cursor: pointer;
`

export const StyledContainer = styled.div`
  width: 100%;

  ${FLEX({ align: 'flex-start', justify: 'space-around' })}
`

export const StyledButton = styled.div`
  cursor: pointer;

  padding: 10px 10px;

  border-radius: 3px;

  width: 100%;

  background: ${COLORS.green};

  ${FLEX({})}

  ${FONT({ size: '14px', weight: '700', color: COLORS.white })}
`
