import React, { FC } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { Container } from './styled'
import { labelData, options, YearArray } from './components'
import { BarChartProps } from './types'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const BarChart: FC<BarChartProps> = ({
  androidData,
  iosData,
  otherData,
  androidSessionDurationData,
  androidSessionDurationLabelsArray,
  iosSessionDurationData,
  iosSessionDurationLabelsArray,
  iosVersionsData,
  androidVersionsData,
  iosVersionsLabelsArray,
  androidVersionsLabelsArray,
  usersByMonthData,
  usersByDateData,
  usersByMonthLabelArray,
}) => {
  const barChartData = {
    labels: labelData,
    datasets: [
      {
        data: androidData,
        label: 'Android',
        borderColor: '#1fac18',
        backgroundColor: 'rgb(31,172,24)',
        fill: true,
      },
      {
        data: iosData,
        label: 'iOS',
        borderColor: '#2c7cb8',
        backgroundColor: 'rgb(44,124,184)',
        fill: true,
      },
    ],
  }

  const sessionDurationChartData = {
    labels: androidSessionDurationLabelsArray,
    datasets: [
      {
        label: 'iOS',
        data: iosSessionDurationData,
        borderColor: '#2c7cb8',
        backgroundColor: 'rgb(44,124,184)',
        stack: 'Stack 3',
      },
      {
        label: 'Android',
        data: androidSessionDurationData,
        borderColor: '#1fac18',
        backgroundColor: 'rgb(31,172,24)',
        stack: 'Stack 2',
      },
    ],
  }

  const sessionOptions = {
    plugins: {
      title: {
        display: true,
        text: 'Длительность сессии',
      },
    },
    responsive: true,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    scales: {
      x: {
        stacked: false,
      },
      y: {
        stacked: true,
      },
    },
    maintainAspectRatio: false,
  }

  const versionsData = {
    labels: ['2.0.2', '1.29'],
    datasets: [
      {
        // label: 'iOS',
        data: iosVersionsData?.concat(androidVersionsData as number[]),
        borderColor: ['#2c7cb8', '#1fac18', '#1fac18'],
        backgroundColor: [
          'rgb(44,124,184)',
          'rgb(31,172,24)',
          'rgb(31,172,24)',
        ],
      },
      // {
      //   label: 'Android',
      //   data: androidVersionsData,
      // borderColor: '#1fac18',
      // backgroundColor: 'rgb(31,172,24)',
      // },
    ],
  }

  const versionsOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Версии приложения',
        font: {
          size: 16,
        },
      },
      legend: {
        display: false,
        position: 'top' as const,
      },
    },
    maintainAspectRatio: false,
  }
  const userByMonthOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Пользователи по месяцам',
        font: {
          size: 16,
        },
      },
      legend: {
        display: false,
        position: 'top' as const,
      },
    },
    maintainAspectRatio: false,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  }

  const usersByMonthDataChart = {
    labels: usersByMonthLabelArray?.map(
      item =>
        item.slice(8, 10) + '/' + item.slice(5, 7) + '/' + item.slice(0, 4),
    ),
    datasets: [
      {
        label: 'Пользователи',
        data: usersByMonthData,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        fill: true,
        stack: '0',
      },
      {
        label: 'Прирост пользователей',
        data:
          usersByMonthData &&
          [0].concat(
            usersByMonthData
              ?.map((item, index) => item - usersByMonthData[index - 1])
              .slice(1),
          ),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        fill: true,
        stack: '0',
      },
    ],
  }

  const utils = {
    getWidth: () => {
      if (androidSessionDurationData || iosSessionDurationData) {
        return `35%`
      }

      if (androidVersionsData || iosVersionsData || usersByMonthData) {
        return `90%`
      }

      return `18%`
    },
  }

  return (
    <Container
      style={{
        width: utils.getWidth(),
      }}
    >
      {androidData && androidData?.length && (
        <Bar options={options} data={barChartData} height={350} />
      )}
      {(androidSessionDurationData || iosSessionDurationData) && (
        <Bar
          options={sessionOptions}
          data={sessionDurationChartData}
          height={350}
        />
      )}
      {(androidVersionsData || iosVersionsData) && (
        <Bar options={versionsOptions} data={versionsData} height={350} />
      )}
      {usersByMonthData && (
        <Bar
          options={userByMonthOptions}
          data={usersByMonthDataChart}
          height={350}
        />
      )}
    </Container>
  )
}

export default BarChart
