import { AxiosResponse } from 'axios'
import {
  DeleteClinicActionPayload,
  GetClinicsActionResponse,
  GetClinicsPayload,
  PayloadSearchById,
} from './types'
import { setAuthorizationHeadersProp } from '../../helpers'
import { HttpService } from '../../services'
import { FEEDBACK_URL } from './config'

export class FeedbackAPI extends HttpService {
  static getComplaint(
    request: GetClinicsPayload,
  ): Promise<AxiosResponse<GetClinicsActionResponse>> {
    return this.request<GetClinicsActionResponse>('GET_COMPLAINT', {
      url: FEEDBACK_URL.get_complaint(request.page, request.limit),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static getInaccuracy(
    request: GetClinicsPayload,
  ): Promise<AxiosResponse<GetClinicsActionResponse>> {
    return this.request<GetClinicsActionResponse>('GET_INACCURACY', {
      url: FEEDBACK_URL.get_inaccuracy(request.page, request.limit),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static deleteComplaint(
    request: DeleteClinicActionPayload,
  ): Promise<AxiosResponse<void>> {
    return this.request('DELETE_COMPLAINT', {
      url: FEEDBACK_URL.deleteComplaint(request.id),
      method: 'DELETE',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static getComplaintById(
    request: PayloadSearchById,
  ): Promise<AxiosResponse<GetClinicsActionResponse>> {
    return this.request<GetClinicsActionResponse>('GET_COMPLAINT_BY_ID', {
      url: FEEDBACK_URL.getComplaintById(request.id),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static getInaccuracyById(
    request: PayloadSearchById,
  ): Promise<AxiosResponse<GetClinicsActionResponse>> {
    return this.request<GetClinicsActionResponse>('GET_INACCURACY_BY_ID', {
      url: FEEDBACK_URL.getInaccuracyById(request.id),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static getProposal(
    request: GetClinicsPayload,
  ): Promise<AxiosResponse<GetClinicsActionResponse>> {
    return this.request<GetClinicsActionResponse>('GET_PROPOSAL', {
      url: FEEDBACK_URL.get_proposal(request.page, request.limit),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static deleteProposal(
    request: DeleteClinicActionPayload,
  ): Promise<AxiosResponse<void>> {
    return this.request('DELETE_PROPOSAL', {
      url: FEEDBACK_URL.deleteProposal(request.id),
      method: 'DELETE',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static getProposalById(
    request: PayloadSearchById,
  ): Promise<AxiosResponse<GetClinicsActionResponse>> {
    return this.request<GetClinicsActionResponse>('GET_PROPOSAL_BY_ID', {
      url: FEEDBACK_URL.getProposalById(request.id),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }
}
