import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';

import { useTypedSelector } from '../../hooks';
import { getSupport, supportActions } from '../../store/support';
import { Text } from '../../components';
import { Assets } from '../../assets';

import {
  Container,
  FormBlock,
  FormContainer,
  Item,
  TextArea,
  Image,
  DeleteButton,
  Header,
} from './styled';

export const SupportProfile = () => {
  const { id } = useParams<{ id: string }>()
  const dispatch = useDispatch();
  const history = useHistory();

  const { support } = useTypedSelector(getSupport);

  useEffect(() => {
    dispatch(supportActions.getSupport({ _id: id}))
  }, []);

  const navigateHandler = () => {
    history.goBack()
  };

  const onDeleteFeedback = async () => {
    try {
      const result = window.confirm('Удалить фидбек?')

      if (result) {
        dispatch(supportActions.deleteSupport({ _id: id}))
        history.goBack();
      }
    } catch (error) {}
  };

  return (
    <>
      <Header>
        <Image src={Assets.LEFT_ARROW_ICON} onClick={navigateHandler} />
        <DeleteButton onClick={onDeleteFeedback}>Удалить</DeleteButton>
      </Header>
      <Container>
        <Text
          color="#1F166A"
          fontSize="16px"
          text={`Дата написания: ${moment(support?.createdAt).format(
            'DD-MM-YYYY HH:mm',
          )}`}
        />
        <FormContainer>
          <FormBlock>
            <Text text="Тип проблемы" color="#1F166A" />
            <Item>{support?.type}</Item>
          </FormBlock>
          <FormBlock>
            <Text text="Электронная почта" color="#1F166A" />
            <Item>{support?.email}</Item>
          </FormBlock>
        </FormContainer>
        <FormBlock>
          <Text text="Текст" color="#1F166A" />
          <TextArea>{support?.message}</TextArea>
        </FormBlock>
      </Container>
    </>
  )
}
