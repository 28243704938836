import React, { FC } from 'react'
import { classNames } from '../../helpers'
import { TBackdropProps } from './types'
import { StyledBackdrop } from './styled'

export const Backdrop: FC<TBackdropProps> = ({
  className,
  children,
  onClick,
  fadeIn,
  zIndex,
}) => {
  // ! layout jumping fix
  /*
  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'unset'
    }
  }, []) */

  return (
    <StyledBackdrop
      className={classNames([className!, fadeIn!])}
      onClick={onClick}
      zIndex={zIndex}
    >
      {children}
    </StyledBackdrop>
  )
}
