import { createReducer } from '@reduxjs/toolkit'
import {
  GetMoreStackEventPropAction,
  GetStackEventsPropAction,
  SetPlatformAction,
} from './action'
import { TInitialState } from './types'

const InitialState: TInitialState = {
  OS: '',
  stackProps: null,
  allStackEvents: [],
}

export const stackpropsReducer = createReducer<TInitialState>(
  InitialState,
  builder => {
    builder.addCase(SetPlatformAction, (state, { payload }) => ({
      ...state,
      OS: payload,
    }))

    builder.addCase(GetMoreStackEventPropAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(
      GetMoreStackEventPropAction.success,
      (state, { payload }) => ({
        ...state,
        stackProps: payload,
        loading: false,
      }),
    )
    builder.addCase(
      GetMoreStackEventPropAction.failure,
      (state, { payload }) => ({
        ...state,
        error: payload,
        loading: false,
      }),
    )

    builder.addCase(GetStackEventsPropAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(GetStackEventsPropAction.success, (state, { payload }) => ({
      ...state,
      allStackEvents: payload,
      loading: false,
    }))
    builder.addCase(GetStackEventsPropAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))
  },
)
