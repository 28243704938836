import { api } from '../config'

export const PETS_URL = {
  get_pets: (page: number, limit: number, search: string, sortBy: string) =>
    `${api.withAPI}/admin/pets/pets-list/?page=${page}&limit=${limit}&search=${search}&sortBy=${sortBy}`,
  getOwnPets: (id: string) => `${api.withAPI}/admin/pets/all-pets/${id}`,
  getPetById: (id: string) => `${api.withAPI}/admin/pets/${id}`,
  updatePet: (id: string) => `${api.withAPI}/admin/pets/${id}`,
  deletePet: (userId: string, petId: string) =>
    `${api.withAPI}/admin/pets/${userId}/${petId}`,
}
