export const labelData = ['На данный момент']

export const options = {
  responsive: true,
  plugins: {
    title: {
      display: true,
      text: 'Использование Android/iOS',
      font: {
        size: 16,
      },
    },
    legend: {
      display: true,
      position: 'top' as const,
    },
  },
  maintainAspectRatio: false,
}

export const YearArray = {
  January: 'Январь',
  February: 'Февраль',
  March: 'Март',
  April: 'Апрель',
  May: 'Май',
  June: 'Июнь',
  July: 'Июль',
  August: 'Август',
  September: 'Сентябрь',
  October: 'Октябрь',
  November: 'Ноябрь',
  December: 'Декабрь',
}
