import { api } from '../config'

export const ADS_URL = {
  get_ads: (page: number, limit: number, sortBy: string) =>
    `${api.withAPI}/admin/ads/?page=${page}&limit=${limit}&sortBy=${sortBy}`,
  getAdsById: (id: string) => `${api.withAPI}/admin/ads/${id}`,
  createAds: `${api.withAPI}/admin/ads`,
  updateAds: (id: string) => `${api.withAPI}/admin/ads/${id}`,
  deleteAds: (id: string) => `${api.withAPI}/admin/ads/${id}`,
  getComplaints: (page: number, limit: number) =>
    `${api.withAPI}/admin/complaint/ads/?page=${page}&limit=${limit}`,
  deleteComplaintAds: (id: string) => `${api.withAPI}/admin/complaint/${id}`,
  getComplaintAdsById: (id: string) => `${api.withAPI}/admin/complaint/${id}`,
}
