import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import Table from 'rc-table'
import moment from 'moment'
import Dropdown from 'react-dropdown'

import { Divider, EInputTemplate, Input } from '../../components'
import { useTypedSelector, useValidation } from '../../hooks'
import { Assets } from '../../assets'
import {
  createSubCategoriesAdsAction,
  deleteSubCategoriesAdsAction,
  getCategoriesAdsAction,
  getSubCategoriesAdsAction,
  updateSubCategoriesAdsAction,
} from '../../store/categoriesAds'
import { Modal } from '../../components/Modal'

import {
  Header,
  StyledHeader,
  StyledRow,
  StyledTable,
  TableWrapper,
  Title,
  StyledPagination,
  Error,
  StyledButtonEnd,
  StyledButton,
  FullWidth,
  StyledEntity,
  Image,
  StyledSort,
  Select,
  HeaderTitle,
  HeaderModal,
  FooterModal,
  ButtonCreate,
  ButtonDelete,
  ButtonUpdate,
  Close,
  NavigateButton,
  BodyModal,
  DropdownBlock,
  StyledWrapper,
  ErrorBlock,
} from './styled'
import { PAGE_LiMIT } from './consts'

export const SubCategoriesTab = () => {
  const [typeModal, setTypeModal] = useState<string | null>(null)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [subCateoriesArray, setSubCategoriesArray] = useState<any>([])
  const [searchPage, setSearchPage] = useState<number>(1)
  const [currentData, setCurrentData] = useState<any>(null)
  const [formData, setFormData] = useState<{
    titleUk: string
    titleEn: string
    category: string
  }>({
    titleUk: '',
    titleEn: '',
    category: '',
  })
  const languages = ['uk', 'en']

  const [language, setLanguage] = useState<string>('uk')

  const dispatch = useDispatch()

  const schema = useMemo<any>(
    () => ({
      titleUk: {
        condition: !!formData.titleUk,
        error: 'Введите название на uk',
      },
      titleEn: {
        condition: !!formData.titleEn,
        error: 'Введите название на eng',
      },
    }),
    [formData],
  )

  const {
    validationSchema,
    validation,
    enableValidation,
    isEnabled,
    disableValidation,
    //eslint-disable-next-line
  } = useValidation(schema)

  const { subCategoryTotalCount, subCategoriesAds, categoriesAds, response } =
    useTypedSelector(state => state.categories)

  const columns = [
    {
      title: <div>Имя</div>,
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: <div>Категория</div>,
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: (
        <StyledSort>
          <div>Дата создания</div>

          <Divider width={10} />
        </StyledSort>
      ),
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Подробнее',
      dataIndex: 'details',
      key: 'details',
    },
  ]

  const choosePage = (page: number) => {
    dispatch(
      getSubCategoriesAdsAction.request({
        page: page === 0 ? 1 : page,
        limit: PAGE_LiMIT,
        sortBy: '',
      }),
    )
    setCurrentPage(page === 0 ? 1 : page)
  }

  const putSearchPage = (e: React.SyntheticEvent<Element, Event> | any) => {
    setSearchPage(isNaN(+e.target.value) ? 1 : +e.target.value)
  }

  const enterKeyPress = (e: any) => {
    e?.code === 'Enter' &&
      choosePage(
        searchPage <=
          (subCategoryTotalCount === 0
            ? 1
            : Math.ceil(subCategoryTotalCount / PAGE_LiMIT))
          ? searchPage !== currentPage
            ? searchPage
            : 1
          : 1,
      )
  }

  const createSubCategories = async () => {
    enableValidation()
    await validation()

    const data = {
      title: [
        { lang: 'uk', value: formData.titleUk },
        { lang: 'en', value: formData.titleEn },
      ],
      category: formData.category,
    }
    dispatch(createSubCategoriesAdsAction.request({ data }))
    disableValidation()
  }

  const updateSubCategories = async () => {
    enableValidation()
    await validation()

    const data = {
      title: [
        { lang: 'uk', value: formData.titleUk },
        { lang: 'en', value: formData.titleEn },
      ],
      category: formData.category,
    }

    dispatch(
      updateSubCategoriesAdsAction.request({ id: currentData._id, data }),
    )
    disableValidation()
  }

  const deleteSubCategories = () => {
    dispatch(deleteSubCategoriesAdsAction.request({ id: currentData._id }))
  }

  const handleInputChange = (e: any) => {
    const { value, name } = e.target as HTMLInputElement
    setFormData({ ...formData, [name]: value })
  }

  const getSubCategories = () => {
    dispatch(
      getSubCategoriesAdsAction.request({
        page: currentPage === 0 ? 1 : currentPage,
        limit: PAGE_LiMIT,
        sortBy: 'createdAt',
      }),
    )
  }
  // eslint-disable-next-line
  useEffect(() => {
    getSubCategories()
  }, [])

  useEffect(() => {
    if (
      response === 'CREATE_CATEGORIES' ||
      response === 'UPDATE_CATEGORIES' ||
      response === 'DELETE_CATEGORIES'
    ) {
      setTypeModal('')
      setFormData({
        titleUk: '',
        titleEn: '',
        category: '',
      })
      getSubCategories()
    }
  }, [response])

  const openCurrentCategory = (data: any) => {
    setTypeModal('update')
    setCurrentData(data)
  }

  const closeModal = () => {
    setTypeModal(null)
    setCurrentData(null)
    setFormData({
      titleUk: '',
      titleEn: '',
      category: '',
    })
  }

  useEffect(() => {
    setSubCategoriesArray(
      subCategoriesAds?.map((c: any) => ({
        title: c?.title[0].value ?? '-',
        createdAt: moment(c?.createdAt).isValid()
          ? moment(c?.createdAt).format('HH:mm DD.MM.YYYY')
          : '-',
        key: c?._id,
        category: c?.category.title[0].value,
        details: (
          <NavigateButton onClick={() => openCurrentCategory(c)}>
            Подробнее
          </NavigateButton>
        ),
      })),
    )
  }, [subCategoriesAds])

  useEffect(() => {
    if (currentData) {
      setFormData({
        titleUk: currentData?.title[0]?.value,
        titleEn: currentData?.title[1]?.value,
        category: currentData?.category?._id,
      })
    }
  }, [currentData])

  const getCategories = () => {
    dispatch(
      getCategoriesAdsAction.request({
        page: currentPage === 0 ? 1 : currentPage,
        limit: 500,
        sortBy: 'createdAt',
      }),
    )
  }
  // eslint-disable-next-line
  useEffect(() => {
    getCategories()
  }, [])

  useEffect(() => {
    if (typeModal === 'create') {
      setFormData((prevState: any) => ({
        ...prevState,
        category: categoriesAds[0]?._id,
      }))
    }
  }, [categoriesAds, typeModal])

  const changeType = (type: string) => {
    setLanguage(type)
  }

  return (
    <>
      <div>
        <Header>
          <Title>{`Подкатегории объвлений (${subCategoryTotalCount})`}</Title>
          <StyledPagination>
            <StyledEntity onKeyPress={enterKeyPress}>
              <Input
                label="Страница"
                name="searchPage"
                value={searchPage.toString()}
                placeholder="Страница"
                template={EInputTemplate.NAME}
                onChange={putSearchPage}
              />
            </StyledEntity>
            <Divider width={10} />
            <Image
              src={Assets.SEARCH_ICON}
              onClick={() =>
                choosePage(
                  searchPage <=
                    (subCategoryTotalCount === 0
                      ? 1
                      : Math.ceil(subCategoryTotalCount / PAGE_LiMIT))
                    ? searchPage
                    : 1,
                )
              }
            />
          </StyledPagination>
          <ButtonCreate onClick={() => setTypeModal('create')}>
            Создать
          </ButtonCreate>
        </Header>

        <Divider height={30} />

        <TableWrapper>
          <Table
            columns={columns}
            data={subCateoriesArray}
            scroll={{ y: 650 }}
            components={{
              body: {
                row: StyledRow,
              },
              header: {
                cell: StyledHeader,
              },
              table: StyledTable,
            }}
          />
        </TableWrapper>

        <Divider height={20} />
      </div>

      <FullWidth>
        <StyledPagination>
          {currentPage === 1 ? (
            <StyledButtonEnd>Назад</StyledButtonEnd>
          ) : (
            <StyledButton onClick={() => choosePage(currentPage - 1)}>
              Назад
            </StyledButton>
          )}

          <div>
            {currentPage}/
            {!subCategoryTotalCount
              ? 1
              : Math.ceil(subCategoryTotalCount / PAGE_LiMIT) === 0
              ? 1
              : Math.ceil(subCategoryTotalCount / PAGE_LiMIT)}
          </div>

          {currentPage ===
          (!subCategoryTotalCount
            ? 1
            : Math.ceil(subCategoryTotalCount / PAGE_LiMIT)) ? (
            <StyledButtonEnd>Далее</StyledButtonEnd>
          ) : (
            <StyledButton onClick={() => choosePage(currentPage + 1)}>
              Далее
            </StyledButton>
          )}
        </StyledPagination>
        <Divider height={10} />
      </FullWidth>
      {typeModal && (
        <Modal>
          <HeaderModal>
            <HeaderTitle>
              {typeModal === 'create'
                ? 'Создать подкатегорию'
                : 'Обновить подкатегорию'}
            </HeaderTitle>
            <Close onClick={closeModal}>&#x2716;</Close>
          </HeaderModal>
          <BodyModal>
            <StyledWrapper>
              <Dropdown
                options={languages}
                onChange={option => changeType(option.value)}
                value={language}
                placeholder="Виберите язык"
              />
            </StyledWrapper>
            {language === 'uk' && (
              <Input
                label="Название - uk"
                name="titleUk"
                value={formData.titleUk}
                placeholder="Введите имя"
                template={EInputTemplate.NAME}
                onChange={handleInputChange}
              />
            )}
            <ErrorBlock>
              {!(!isEnabled || validationSchema.titleUk) && (
                <Error>{schema.titleUk.error}</Error>
              )}
            </ErrorBlock>
            {language === 'en' && (
              <Input
                label="Название - eng"
                name="titleEn"
                value={formData.titleEn}
                placeholder="Введите имя"
                template={EInputTemplate.NAME}
                onChange={handleInputChange}
              />
            )}
            <ErrorBlock>
              {!(!isEnabled || validationSchema.titleEn) && (
                <Error>{schema.titleEn.error}</Error>
              )}
            </ErrorBlock>
            {categoriesAds && (
              <DropdownBlock>
                <Select
                  onChange={e =>
                    setFormData((prevState: any) => ({
                      ...prevState,
                      category: e.target.value,
                    }))
                  }
                  value={formData.category}
                >
                  {categoriesAds?.map(el => (
                    <option value={el?._id}>{el?.title[0]?.value}</option>
                  ))}
                </Select>
              </DropdownBlock>
            )}
          </BodyModal>
          <FooterModal>
            {typeModal === 'create' ? (
              <ButtonCreate onClick={createSubCategories}>Создать</ButtonCreate>
            ) : (
              <>
                <ButtonDelete onClick={deleteSubCategories}>
                  Удалить
                </ButtonDelete>
                <ButtonUpdate onClick={updateSubCategories}>
                  Обновить
                </ButtonUpdate>
              </>
            )}
          </FooterModal>
        </Modal>
      )}
    </>
  )
}
