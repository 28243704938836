import { createReducer } from '@reduxjs/toolkit'
import { TInitialState } from './types'

import {
  getControlAction,
  updateControlAction,
  updateDefaultPermissionsAction,
  updateRegionAction,
} from './actions'

const InitialState: TInitialState = {
  loading: false,
  controlInfo: null,
}

export const controlReducer = createReducer<TInitialState>(
  InitialState,
  builder => {
    builder.addCase(getControlAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getControlAction.success, (state, { payload }) => ({
      ...state,
      controlInfo: payload,
      loading: false,
    }))
    builder.addCase(getControlAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      controlInfo: null,
      loading: false,
    }))

    builder.addCase(updateControlAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(updateControlAction.success, state => ({
      ...state,
      loading: false,
    }))
    builder.addCase(updateControlAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(updateRegionAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(updateRegionAction.success, state => ({
      ...state,
      loading: false,
    }))
    builder.addCase(updateRegionAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(updateDefaultPermissionsAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(updateDefaultPermissionsAction.success, state => ({
      ...state,
      loading: false,
    }))
    builder.addCase(
      updateDefaultPermissionsAction.failure,
      (state, { payload }) => ({
        ...state,
        error: payload,
        loading: false,
      }),
    )
  },
)
