import { AxiosResponse } from 'axios';

import { HttpService } from '../http.service';

import { SUPPORT_URL } from './config';
import { TResponse } from './types';

export class ApiSupportService extends HttpService {
	static getSupports(
		{
			token,
			page,
			limit,
		}: any): Promise<AxiosResponse<TResponse>> {
		return this.request({
			url: `${SUPPORT_URL.getSupports}`,
			method: 'GET',
			headers: {
				Authorization: token
			},
			params: {
				limit,
				page,
			}
		});
	};

	static getSupport({ token, _id }: any): Promise<AxiosResponse<TResponse>> {
		return this.request({
			url: `${SUPPORT_URL.getSupport}/${_id}`,
			method: 'GET',
			headers: {
				Authorization: token
			},
		});
	};

	static getReview({ token, _id }: any): Promise<AxiosResponse<TResponse>> {
		return this.request({
			url: `${SUPPORT_URL.getReview}/${_id}`,
			method: 'GET',
			headers: {
				Authorization: token
			},
		});
	};

	static getReviews({ token, clinic, page, limit }: any): Promise<AxiosResponse<TResponse>> {
		return this.request({
			url: `${SUPPORT_URL.getReviews}`,
			method: 'GET',
			headers: {
				Authorization: token
			},
			params: {
				page,
				limit,
				clinic,
			}
		});
	};

	static deleteSupport({ token, _id } : any): Promise<AxiosResponse<TResponse>> {
		return this.request<TResponse>({
			url: `${SUPPORT_URL.deleteSupport}/${_id}`,
			method: 'DELETE',
			headers: {
				Authorization: token
			},
		});
	};
}
