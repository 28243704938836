import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Table from 'rc-table'
import moment from 'moment'
import { Redirect, useHistory } from 'react-router-dom'

import { Divider, EInputTemplate, Input } from '../../components'
import { useTypedSelector } from '../../hooks'
import { deleteAdsAction, getAdsAction } from '../../store/ads'
import { Assets } from '../../assets'
import { RoutesTree } from '../../router'

import {
  Container,
  Header,
  StyledHeader,
  StyledRow,
  StyledTable,
  TableWrapper,
  Title,
  StyledPagination,
  StyledButtonEnd,
  StyledButton,
  FullWidth,
  StyledEntity,
  Image,
  StyledSort,
  StyledLink,
  DeleteButton,
  BlockButton,
  ButtonCreate,
} from './styled'
import { LIMIT_PAGE } from './consts'

export const Advertisement: React.FC = () => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [searchPage, setSearchPage] = useState<number>(1)
  const [adsArrayData, setAdsArrayData] = useState<any>([])

  const dispatch = useDispatch()
  const history = useHistory()

  const { totalCount, ads, response } = useTypedSelector(state => state.ads)

  const { user } = useTypedSelector(state => state.user)

  const columns = [
    {
      title: (
        <StyledSort>
          <div>Название объявления</div>

          <Divider width={10} />
        </StyledSort>
      ),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: (
        <StyledSort>
          <div>Дата создания</div>

          <Divider width={10} />
        </StyledSort>
      ),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '170px',
    },
    {
      title: <div>Категория</div>,
      dataIndex: 'category',
      key: 'category',
      width: '170px',
    },
    {
      title: <div>Подкатегория</div>,
      dataIndex: 'subCategory',
      key: 'subCategory',
      width: '150px',
    },
    {
      title: <div>Статус</div>,
      dataIndex: 'status',
      key: 'status',
      width: '150px',
    },
    {
      title: 'Подробнее',
      dataIndex: 'details',
      key: 'details',
    },
  ]

  const choosePage = (page: number) => {
    dispatch(
      getAdsAction.request({
        page: page === 0 ? 1 : page,
        limit: LIMIT_PAGE,
        sortBy: 'createdAt',
      }),
    )

    setCurrentPage(page === 0 ? 1 : page)
  }

  const putSearchPage = (e: React.SyntheticEvent<Element, Event> | any) => {
    setSearchPage(isNaN(+e.target.value) ? 1 : +e.target.value)
  }

  const enterKeyPress = (e: any) => {
    e?.code === 'Enter' &&
      choosePage(
        searchPage <=
          (totalCount === 0 ? 1 : Math.ceil(totalCount / LIMIT_PAGE))
          ? searchPage !== currentPage
            ? searchPage
            : 1
          : 1,
      )
  }

  const getAds = () => {
    dispatch(
      getAdsAction.request({
        page: currentPage === 0 ? 1 : currentPage,
        limit: LIMIT_PAGE,
        sortBy: 'createdAt',
      }),
    )
  }

  const deleteAds = (id: string) => {
    dispatch(deleteAdsAction({ id }))
    getAds()
    getAds()
  }

  useEffect(() => {
    if (response === 'CREATE_ADS' || response === 'UPDATE_ADS') {
      getAds()
    }
  }, [response])

  // eslint-disable-next-line
  useEffect(() => {
    getAds()
  }, [])

  useEffect(() => {
    if (ads) {
      const newData = ads.map(a => ({
        name: a?.title ?? '-',
        createdAt: moment(a?.createdAt).isValid()
          ? moment(a?.createdAt).format('HH:mm DD.MM.YYYY')
          : '-',
        key: a?.id,
        category: a?.category?.title[0]?.value,
        subCategory: a?.subcategory?.title[0]?.value,
        status: (
          <>
            {!a?.moderatorComment
              ? a.status === 'moderation' && 'На модерации'
              : 'Отказ'}
            {a?.status === 'active' && 'Активный'}
            {a?.status === 'archived' && 'Завершено'}
          </>
        ),
        details: (
          <BlockButton>
            <StyledLink
              to={{
                pathname: `/advertisementProfile/${a?._id}`,
              }}
            >
              Подробнее
            </StyledLink>
            <DeleteButton onClick={() => deleteAds(a?._id)}>
              Удалить
            </DeleteButton>
          </BlockButton>
        ),
      }))

      setAdsArrayData(newData)
    } else {
      setAdsArrayData([])
    }
  }, [ads])

  if (!user?.access?.ads) {
    return <Redirect to={RoutesTree.notFound.path} />
  }

  return (
    <Container>
      <div>
        <Header>
          <Title>{`Объявления (${totalCount ?? 0})`}</Title>

          <StyledPagination>
            <StyledEntity onKeyPress={enterKeyPress}>
              <Input
                label="Страница"
                name="searchPage"
                value={searchPage.toString()}
                placeholder="Страница"
                template={EInputTemplate.NAME}
                onChange={putSearchPage}
              />
            </StyledEntity>
            <Divider width={10} />
            <Image
              src={Assets.SEARCH_ICON}
              onClick={() =>
                choosePage(
                  searchPage <=
                    (totalCount === 0 ? 1 : Math.ceil(totalCount / LIMIT_PAGE))
                    ? searchPage
                    : 1,
                )
              }
            />
          </StyledPagination>
          <ButtonCreate
            onClick={() => history.push('/advertisementProfile/undefined')}
          >
            Создать
          </ButtonCreate>
        </Header>

        <Divider height={30} />

        <TableWrapper>
          <Table
            columns={columns}
            data={adsArrayData}
            scroll={{ y: 650 }}
            components={{
              body: {
                row: StyledRow,
              },
              header: {
                cell: StyledHeader,
              },
              table: StyledTable,
            }}
          />
        </TableWrapper>

        <Divider height={20} />
      </div>

      <FullWidth>
        <StyledPagination>
          {currentPage === 1 ? (
            <StyledButtonEnd>Назад</StyledButtonEnd>
          ) : (
            <StyledButton onClick={() => choosePage(currentPage - 1)}>
              Назад
            </StyledButton>
          )}

          <div>
            {currentPage}/
            {!totalCount
              ? 1
              : Math.ceil(totalCount / LIMIT_PAGE) === 0
              ? 1
              : Math.ceil(totalCount / LIMIT_PAGE)}
          </div>

          {currentPage ===
          (!totalCount ? 1 : Math.ceil(totalCount / LIMIT_PAGE)) ? (
            <StyledButtonEnd>Далее</StyledButtonEnd>
          ) : (
            <StyledButton onClick={() => choosePage(currentPage + 1)}>
              Далее
            </StyledButton>
          )}
        </StyledPagination>
        <Divider height={10} />
      </FullWidth>
    </Container>
  )
}
