import { ReactElement } from 'react'

export type TInputProps = {
  template: EInputTemplate
  placeholder?: string
  label?: string
  error?: string
  value: string
  name?: string
  isValid?: boolean
  onChange?: (v: string) => void
  onBlur?: () => void
  disabled?: boolean
}

export enum EInputTemplate {
  DEFAULT = 'DEFAULT',
  PASSWORD = 'PASSWORD',
  TITLE = 'TITLE',
  NAME = 'NAME',
  NUMBER = 'NUMBER'
}

export type TInputTemplates = {
  [key in EInputTemplate]: (props: any) => ReactElement
}
