import React from 'react'
import { Assets } from '../../assets'
import { Divider } from '../../components'
import { useTypedSelector } from '../../hooks'
import { TopbarWrapper, Logo, LogoIcon, LogoContainer } from './styled'

const Topbar = () => {
  const { user } = useTypedSelector(state => state.user)

  return (
    <TopbarWrapper>
      <LogoContainer>
        <LogoIcon src={Assets.PETS_LOGO_ICON} />
        <Divider width={10} />
        <Logo>Pets Health</Logo>
      </LogoContainer>

      <div>{`Админ: ${user?.email ?? '-'}`}</div>
    </TopbarWrapper>
  )
}

export default Topbar
