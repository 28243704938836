import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Table from 'rc-table'
import { Divider } from '../../components'
import {
  Container,
  DeleteButton,
  FullWidth,
  Header,
  StyledButton,
  StyledButtonEnd,
  StyledHeader,
  StyledNumberLink,
  StyledPagination,
  StyledRow,
  StyledTable,
  TableWrapper,
  Title,
} from './styled'
import {
  deleteComplaintAction,
  getComplaintAction,
} from '../../store'
import { useTypedSelector } from '../../hooks'
import { TypedComplaint } from './types'
import { RoutesTree } from '../../router'
import moment from 'moment'
import { Redirect } from 'react-router-dom'
import { Log } from '../../utils'

const Complaint: React.FC = () => {
  const [typedComplaints, setTypedComplaints] = useState<TypedComplaint[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [paginationLimit, setPaginationLimit] = useState<number>(8)

  const dispatch = useDispatch()

  const { complaints, complaintTotal } = useTypedSelector(
    state => state.feedback,
  )
  const { user } = useTypedSelector(state => state.user)

  if (!user?.access?.complaint) {
    return <Redirect to={RoutesTree.notFound.path} />
  }
  // eslint-disable-next-line
  useEffect(() => {
    const localValues = localStorage.getItem('complaint') ?? ''
    const parseLocalValues = !!localValues ? JSON.parse(localValues) : ''
    // eslint-disable-next-line
    !!localValues
      ? (setCurrentPage(parseLocalValues.page),
        dispatch(
          getComplaintAction.request({
            page: parseLocalValues.page,
            limit: paginationLimit,
            authorization: '',
          }),
        ))
      : dispatch(
          getComplaintAction.request({
            page: currentPage,
            limit: paginationLimit,
            authorization: '',
          }),
        )
  }, [])

  const columns = [
    {
      title: 'Название',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Клиника',
      dataIndex: 'clinic',
      key: 'clinic',
    },
    {
      title: 'Дата создания',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: '',
      dataIndex: 'delete',
      key: 'delete',
    },
  ]

  const choosePage = (page: number) => {
    localStorage.setItem(
      'complaint',
      JSON.stringify({
        page: page === 0 ? 1 : page,
      }),
    )

    dispatch(
      getComplaintAction.request({
        page,
        limit: paginationLimit,
        authorization: '',
      }),
    )

    setCurrentPage(page)
  }

  const onPressDeleteComplaint = async (id: string) => {
    try {
      const result = window.confirm('Удалить жалобу?')

      if (result) {
        dispatch(
          deleteComplaintAction({
            authorization: '',
            id,
          }),
        )

        setCurrentPage(1)
      }
    } catch (error) {
      Log.ruddy('Event: onPressDeleteComplaint')
    }
  }

  const data = () => {
    return complaints?.map(complaint => {
      return {
        title: (
          <StyledNumberLink to={{ pathname: `/complaint/${complaint?._id}` }}>
            {complaint?.title ?? '-'}
          </StyledNumberLink>
        ),
        description: (
          <StyledNumberLink to={{ pathname: `/complaint/${complaint?._id}` }}>
            {complaint?.description ?? '-'}
          </StyledNumberLink>
        ),
        clinic: (
          <StyledNumberLink to={{ pathname: `/complaint/${complaint?._id}` }}>
            {complaint?.clinic?.name?.uk ?? '-'}
          </StyledNumberLink>
        ),
        createdAt: (
          <StyledNumberLink to={{ pathname: `/complaint/${complaint?._id}` }}>
            {moment(complaint?.createdAt).isValid()
              ? moment(complaint?.createdAt).format('HH:mm DD.MM.YYYY')
              : '-'}
          </StyledNumberLink>
        ),
        delete: (
          <DeleteButton onClick={() => onPressDeleteComplaint(complaint?._id)}>
            Удалить
          </DeleteButton>
        ),
        key: complaint?._id,
      }
    })
  }
  // eslint-disable-next-line
  useEffect(() => {
    setTypedComplaints(data())
  }, [complaints])

  return (
    <Container>
      <div>
        <Header>
          <Title>Жалобы ({complaintTotal ?? 0})</Title>
        </Header>

        <Divider height={30} />

        <TableWrapper>
          <Table
            columns={columns}
            data={typedComplaints}
            scroll={{ y: 650 }}
            components={{
              body: {
                row: StyledRow,
              },
              header: {
                cell: StyledHeader,
              },
              table: StyledTable,
            }}
          />
        </TableWrapper>

        <Divider height={20} />
      </div>

      <FullWidth>
        <StyledPagination>
          {currentPage === 1 ? (
            <StyledButtonEnd>Назад</StyledButtonEnd>
          ) : (
            <StyledButton onClick={() => choosePage(currentPage - 1)}>
              Назад
            </StyledButton>
          )}

          <div>
            {currentPage}/
            {Math.ceil(complaintTotal / paginationLimit) === 0
              ? 1
              : Math.ceil(complaintTotal / paginationLimit)}
          </div>

          {currentPage ===
          (complaintTotal === 0
            ? 1
            : Math.ceil(complaintTotal / paginationLimit)) ? (
            <StyledButtonEnd>Далее</StyledButtonEnd>
          ) : (
            <StyledButton onClick={() => choosePage(currentPage + 1)}>
              Далее
            </StyledButton>
          )}
        </StyledPagination>
        <Divider height={10} />
      </FullWidth>
    </Container>
  )
}

export default Complaint
