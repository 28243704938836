import { LocalizationProvider } from '@mui/lab'
import { FC, useState, useEffect } from 'react'
import {
  Container,
  StyledDropDown,
  StyledHeader,
  StyledNumberLink,
  StyledRow,
  StyledTable,
  TableWrapper,
  Title,
} from './styled'
import ruLocale from 'date-fns/locale/ru'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { Divider } from '../../components'
import Table from 'rc-table'
import { TSort, TypedPlaces } from './types'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../hooks'
import {
  GetCreateEventEventAction,
  GetHomeStackEventAction,
  GetMoreStackEventAction,
  GetOpenSubscriptionModalEventAction,
  GetPetProfileSectionsEventAction,
  GetPetsSharingEventAction,
  GetPetsSharingInAppEventAction,
  GetPetsStackEventAction,
  GetProfileSectionsEventAction,
  GetServicesEventAction,
  GetServicesStackEventAction,
} from '../../store/eventsboard'
import { EventResponse } from '../../store/eventsboard/types'
import Dropdown from 'react-dropdown'
import { SortImage, StyledSortName } from '../Places/styled'
import { Assets } from '../../assets'
import {
  GetStackEventsPropAction,
  SetPlatformAction,
} from '../../store/stackprops'

export const Eventsboard: FC = () => {
  const dispatch = useDispatch()
  const {
    moreStack,
    servicesStack,
    petsStack,
    profileSections,
    services,
    homeStack,
    petProfileSections,
    openSubscriptionModal,
    petsSharing,
    createEvent,
    petsSharingInApp,
  } = useTypedSelector(state => state.eventsboard)
  const { allStackEvents, OS } = useTypedSelector(state => state.stackprops)

  const [events] = useState(
    Object.keys(useTypedSelector(state => state.eventsboard)),
  )
  const [typedPlaces, setTypedPlaces] = useState<TypedPlaces[]>([])
  const [dropdownOption, setOption] = useState<string>('month')
  const [weekData, setWeekData] = useState<number[]>([])
  const [weekDataDevices, setWeekDataDevices] = useState<number[]>([])
  const [prevDayDataDevices, setPrevDayDataDevices] = useState<number[]>([])
  const [prevDayData, setPrevDayData] = useState<number[]>([])
  const [platform, setPlatform] = useState<string>(OS ? OS : 'ios')
  const [searchForm, setSearchForm] = useState({
    start: new Date(new Date().setMonth(new Date().getMonth() - 1))
      .toJSON()
      .split('T')[0],
    end: '',
  })
  const [sortBy, setSortBy] = useState<string>()
  const [sort, setSort] = useState<TSort>({
    count: false,
    trend: false,
    users: false,
    userChange: false,
    perUser: false,
  })
  const sortByParam = (name: string) => {
    setSort(prev => ({ ...prev, [name]: !sort[name as keyof TSort] }))
    setSortBy(name)
  }

  const dropdownOptions = [
    {
      label: 'Текущий день',
      value: 'day',
    },
    {
      label: 'Неделя',
      value: 'week',
    },
    { label: 'Месяц', value: 'month' },
    { label: 'Последние 3 мес', value: 'last3Month' },
    { label: 'Последние 6 мес', value: 'last6Month' },
    { label: 'Год', value: 'year' },
  ]

  const changePlatformOptions = [
    {
      label: 'iOS',
      value: 'ios',
    },
    {
      label: 'Android',
      value: 'android',
    },
  ]

  const columns = [
    {
      title: 'Название',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: (
        <StyledSortName
          onClick={() => utils.sort('count', sort.count ? 'ASC' : 'DESC')}
        >
          <div>Count</div>

          <Divider width={10} />

          <SortImage
            src={sort.count ? Assets.UP_ARROW_ICON : Assets.DOWN_ARROW_ICON}
          />
        </StyledSortName>
      ),
      dataIndex: 'count',
      key: 'count',
    },
    {
      title: (
        <StyledSortName
          onClick={() => utils.sort('trend', sort.trend ? 'ASC' : 'DESC')}
        >
          <div>Trend</div>

          <Divider width={10} />

          <SortImage
            src={sort.trend ? Assets.UP_ARROW_ICON : Assets.DOWN_ARROW_ICON}
          />
        </StyledSortName>
      ),
      dataIndex: 'trend',
      key: 'trend',
    },
    {
      title: (
        <StyledSortName
          onClick={() => utils.sort('users', sort.users ? 'ASC' : 'DESC')}
        >
          <div>Users</div>

          <Divider width={10} />

          <SortImage
            src={sort.users ? Assets.UP_ARROW_ICON : Assets.DOWN_ARROW_ICON}
          />
        </StyledSortName>
      ),
      dataIndex: 'users',
      key: 'users',
    },
    {
      title: (
        <StyledSortName
          onClick={() =>
            utils.sort('userChange', sort.userChange ? 'ASC' : 'DESC')
          }
        >
          <div>User change</div>

          <Divider width={10} />

          <SortImage
            src={
              sort.userChange ? Assets.UP_ARROW_ICON : Assets.DOWN_ARROW_ICON
            }
          />
        </StyledSortName>
      ),
      dataIndex: 'userChange',
      key: 'userChange',
    },
    {
      title: (
        <StyledSortName
          onClick={() => utils.sort('perUser', sort.perUser ? 'ASC' : 'DESC')}
        >
          <div>Per user</div>

          <Divider width={10} />

          <SortImage
            src={sort.perUser ? Assets.UP_ARROW_ICON : Assets.DOWN_ARROW_ICON}
          />
        </StyledSortName>
      ),
      dataIndex: 'perUser',
      key: 'perUser',
    },
  ]

  const data = () => {
    return [
      {
        title: (
          <StyledNumberLink
            to={{
              pathname: allStackEvents.map(item => {
                if (item.name == 'MoreStack') {
                  return item.length
                }
              })[0]
                ? `/eventsboard-info/${'MoreStack'}`
                : '',
            }}
          >
            Просмотры списка из "Больше"
          </StyledNumberLink>
        ),
        count: utils.getStackCount(moreStack as EventResponse[]),
        trend: dropdownOption == 'month' ? weekData[0] : prevDayData[0],
        users: utils.getDevices(moreStack as EventResponse[]),
        userChange:
          dropdownOption == 'month'
            ? weekDataDevices[0]
            : prevDayDataDevices[0],
        perUser: utils.getCountPerDevice(moreStack as EventResponse[]),
      },
      {
        title: (
          <StyledNumberLink
            to={{
              pathname: allStackEvents.map(item => {
                if (item.name == 'ServicesStack') {
                  return item.length
                }
              })[1]
                ? `/eventsboard-info/${'ServicesStack'}`
                : '',
            }}
          >
            Просмотры списка сервисов
          </StyledNumberLink>
        ),
        count: utils.getStackCount(servicesStack as EventResponse[]),
        trend: dropdownOption == 'month' ? weekData[1] : prevDayData[1],
        users: utils.getDevices(servicesStack as EventResponse[]),
        userChange:
          dropdownOption == 'month'
            ? weekDataDevices[1]
            : prevDayDataDevices[1],
        perUser: utils.getCountPerDevice(servicesStack as EventResponse[]),
      },
      {
        title: (
          <StyledNumberLink
            to={{
              pathname: allStackEvents.map(item => {
                if (item.name == 'PetsStack') {
                  return item.length
                }
              })[2]
                ? `/eventsboard-info/${'PetsStack'}`
                : '',
            }}
          >
            Просмотры списка питомцев
          </StyledNumberLink>
        ),
        count: utils.getStackCount(petsStack as EventResponse[]),
        trend: dropdownOption == 'month' ? weekData[2] : prevDayData[2],
        users: utils.getDevices(petsStack as EventResponse[]),
        userChange:
          dropdownOption == 'month'
            ? weekDataDevices[2]
            : prevDayDataDevices[2],
        perUser: utils.getCountPerDevice(petsStack as EventResponse[]),
      },
      {
        title: (
          <StyledNumberLink
            to={{
              pathname: allStackEvents.map(item => {
                if (item.name == 'ProfileSections') {
                  return item.length
                }
              })[3]
                ? `/eventsboard-info/${'ProfileSections'}`
                : '',
            }}
          >
            Просмотры "Профиль"
          </StyledNumberLink>
        ),
        count: utils.getStackCount(profileSections as EventResponse[]),
        trend: dropdownOption == 'month' ? weekData[3] : prevDayData[3],
        users: utils.getDevices(profileSections as EventResponse[]),
        userChange:
          dropdownOption == 'month' ? weekData[3] : prevDayDataDevices[3],
        perUser: utils.getCountPerDevice(profileSections as EventResponse[]),
      },
      {
        title: (
          <StyledNumberLink
            to={{
              pathname: allStackEvents.map(item => {
                if (item.name == 'Services') {
                  return item.length
                }
              })[4]
                ? `/eventsboard-info/${'Services'}`
                : '',
            }}
          >
            Клики на карточки сервисов поле
          </StyledNumberLink>
        ),
        count: utils.getStackCount(services as EventResponse[]),
        trend: dropdownOption == 'month' ? weekData[4] : prevDayData[4],
        users: utils.getDevices(services as EventResponse[]),
        userChange:
          dropdownOption == 'month'
            ? weekDataDevices[4]
            : prevDayDataDevices[4],
        perUser: utils.getCountPerDevice(services as EventResponse[]),
      },
      {
        title: (
          <StyledNumberLink
            to={{
              pathname: allStackEvents.map(item => {
                if (item.name == 'HomeStack') {
                  return item.length
                }
              })[5]
                ? `/eventsboard-info/${'HomeStack'}`
                : '',
            }}
          >
            Просмотры главного экрана
          </StyledNumberLink>
        ),
        count: utils.getStackCount(homeStack as EventResponse[]),
        trend: dropdownOption == 'month' ? weekData[5] : prevDayData[5],
        users: utils.getDevices(homeStack as EventResponse[]),
        userChange:
          dropdownOption == 'month'
            ? weekDataDevices[5]
            : prevDayDataDevices[5],
        perUser: utils.getCountPerDevice(homeStack as EventResponse[]),
      },
      {
        title: (
          <StyledNumberLink
            to={{
              pathname: allStackEvents.map(item => {
                if (item.name == 'PetProfileSections') {
                  return item.length
                }
              })[6]
                ? `/eventsboard-info/${'PetProfileSections'}`
                : '',
            }}
          >
            Просмотры "Профиль питомца"
          </StyledNumberLink>
        ),
        count: utils.getStackCount(petProfileSections as EventResponse[]),
        trend: dropdownOption == 'month' ? weekData[6] : prevDayData[6],
        users: utils.getDevices(petProfileSections as EventResponse[]),
        userChange:
          dropdownOption == 'month'
            ? weekDataDevices[6]
            : prevDayDataDevices[6],
        perUser: utils.getCountPerDevice(petProfileSections as EventResponse[]),
      },
      {
        title: (
          <StyledNumberLink
            to={{
              pathname: allStackEvents.map(item => {
                if (item.name == 'OpenSubscriptionModal') {
                  return item.length
                }
              })[7]
                ? `/eventsboard-info/${'OpenSubscriptionModal'}`
                : '',
            }}
          >
            Клик на открытие модалки с подпиской
          </StyledNumberLink>
        ),
        count: utils.getStackCount(openSubscriptionModal as EventResponse[]),
        trend: dropdownOption == 'month' ? weekData[7] : prevDayData[7],
        users: utils.getDevices(openSubscriptionModal as EventResponse[]),
        userChange:
          dropdownOption == 'month'
            ? weekDataDevices[7]
            : prevDayDataDevices[7],
        perUser: utils.getCountPerDevice(
          openSubscriptionModal as EventResponse[],
        ),
      },
      {
        title: (
          <StyledNumberLink
            to={{
              pathname: allStackEvents.map(item => {
                if (item.name == 'PetsSharing') {
                  return item.length
                }
              })[8]
                ? `/eventsboard-info/${'PetsSharing'}`
                : '',
            }}
          >
            Кол-во кликов на шеринг приложения
          </StyledNumberLink>
        ),
        count: utils.getStackCount(petsSharing as EventResponse[]),
        trend: dropdownOption == 'month' ? weekData[8] : prevDayData[8],
        users: utils.getDevices(petsSharing as EventResponse[]),
        userChange:
          dropdownOption == 'month'
            ? weekDataDevices[8]
            : prevDayDataDevices[8],
        perUser: utils.getCountPerDevice(petsSharing as EventResponse[]),
      },
      {
        title: (
          <StyledNumberLink
            to={{
              pathname: allStackEvents.map(item => {
                if (item.name == 'CreateEvent') {
                  return item.length
                }
              })[9]
                ? `/eventsboard-info/${'CreateEvent'}`
                : '',
            }}
          >
            Кол-во кликов на кнопку "Создать событие"
          </StyledNumberLink>
        ),
        count: utils.getStackCount(createEvent as EventResponse[]),
        trend: dropdownOption == 'month' ? weekData[9] : prevDayData[9],
        users: utils.getDevices(createEvent as EventResponse[]),
        userChange:
          dropdownOption == 'month'
            ? weekDataDevices[9]
            : prevDayDataDevices[9],
        perUser: utils.getCountPerDevice(createEvent as EventResponse[]),
      },
      {
        title: (
          <StyledNumberLink
            to={{
              pathname: allStackEvents.map(item => {
                if (item.name == 'PetsSharingInApp') {
                  return item.length
                }
              })[10]
                ? `/eventsboard-info/${'PetsSharingInApp'}`
                : '',
            }}
          >
            Кол-во кликов на кнопку шеринга питомца
          </StyledNumberLink>
        ),
        count: utils.getStackCount(petsSharingInApp as EventResponse[]),
        trend: dropdownOption == 'month' ? weekData[10] : prevDayData[10],
        users: utils.getDevices(petsSharingInApp as EventResponse[]),
        userChange:
          dropdownOption == 'month'
            ? weekDataDevices[10]
            : prevDayDataDevices[10],
        perUser: utils.getCountPerDevice(petsSharingInApp as EventResponse[]),
      },
    ]
  }

  const Events = {
    changeType: (type: string) => {
      if (type == 'week') {
        setSearchForm({
          start: new Date(new Date().setDate(new Date().getDate() - 7))
            .toJSON()
            .split('T')[0],
          end: '',
        })
      } else if (type == 'day') {
        setSearchForm({
          start: new Date().toJSON().split('T')[0],
          end: '',
        })
      } else if (type == 'month') {
        setSearchForm({
          start: new Date(new Date().setMonth(new Date().getMonth() - 1))
            .toJSON()
            .split('T')[0],
          end: '',
        })
      } else if (type == 'last3Month') {
        setSearchForm({
          start: new Date(new Date().setMonth(new Date().getMonth() - 3))
            .toJSON()
            .split('T')[0],
          end: new Date().toJSON().split('T')[0],
        })
      } else if (type == 'last6Month') {
        setSearchForm({
          start: new Date(new Date().setMonth(new Date().getMonth() - 6))
            .toJSON()
            .split('T')[0],
          end: new Date().toJSON().split('T')[0],
        })
      } else if (type == 'year') {
        setSearchForm({
          start: new Date(new Date().setFullYear(new Date().getFullYear() - 1))
            .toJSON()
            .split('T')[0],
          end: new Date().toJSON().split('T')[0],
        })
      }
      setOption(type)
    },
    changePlatform: (type: string) => {
      setPlatform(type)
      dispatch(SetPlatformAction(type))
    },
  }

  const Requests = {
    getMoreStackEvent: () => {
      dispatch(
        GetMoreStackEventAction.request({
          start: searchForm.start,
          end: searchForm.end,
          event: 'MoreStack',
          app: platform,
        }),
      )
    },
    getServicesStackEvent: () => {
      dispatch(
        GetServicesStackEventAction.request({
          start: searchForm.start,
          end: searchForm.end,
          event: 'ServicesStack',
          app: platform,
        }),
      )
    },
    getPetsStackEvent: () => {
      dispatch(
        GetPetsStackEventAction.request({
          start: searchForm.start,
          end: searchForm.end,
          event: 'PetsStack',
          app: platform,
        }),
      )
    },
    getProfileSections: () => {
      dispatch(
        GetProfileSectionsEventAction.request({
          start: searchForm.start,
          end: searchForm.end,
          event: 'ProfileSections',
          app: platform,
        }),
      )
    },
    getServices: () => {
      dispatch(
        GetServicesEventAction.request({
          start: searchForm.start,
          end: searchForm.end,
          event: 'Services',
          app: platform,
        }),
      )
    },
    getHomeStack: () => {
      dispatch(
        GetHomeStackEventAction.request({
          start: searchForm.start,
          end: searchForm.end,
          event: 'HomeStack',
          app: platform,
        }),
      )
    },
    getPetProfileSections: () => {
      dispatch(
        GetPetProfileSectionsEventAction.request({
          start: searchForm.start,
          end: searchForm.end,
          event: 'PetProfileSections',
          app: platform,
        }),
      )
    },
    getOpenSubscriptionModal: () => {
      dispatch(
        GetOpenSubscriptionModalEventAction.request({
          start: searchForm.start,
          end: searchForm.end,
          event: 'OpenSubscriptionModal',
          app: platform,
        }),
      )
    },
    getPetsSharing: () => {
      dispatch(
        GetPetsSharingEventAction.request({
          start: searchForm.start,
          end: searchForm.end,
          event: 'PetsSharing',
          app: platform,
        }),
      )
    },
    getCreateEvent: () => {
      dispatch(
        GetCreateEventEventAction.request({
          start: searchForm.start,
          end: searchForm.end,
          event: 'CreateEvent',
          app: platform,
        }),
      )
    },
    getPetsSharingInApp: () => {
      dispatch(
        GetPetsSharingInAppEventAction.request({
          start: searchForm.start,
          end: searchForm.end,
          event: 'PetsSharingInApp',
          app: platform,
        }),
      )
    },
    getAllEvents: () => {
      dispatch(
        GetStackEventsPropAction.request({
          start: searchForm.start,
          end: searchForm.end,
          stackEvents: events
            .slice(0, 11)
            .map(item => item[0].toLocaleUpperCase() + item.slice(1)),
          app: platform,
        }),
      )
    },
  }

  const utils = {
    getStackCount: (stack: EventResponse[]) => {
      if (stack) {
        if (stack.length > 2) {
          return stack
            .filter(item => item._id == 'count')
            .map(item => item.data.map(item => item.count))[0]
            .reduce((a, b) => {
              return a + b
            })
        } else {
          return 0
        }
      } else {
        return 1
      }
    },
    getCountPerDevice: (stack: EventResponse[]) => {
      if (stack && stack.length > 1) {
        const count: number = stack
          .filter(item => item._id == 'count')
          .map(item => item.data.map(item => item.count))[0]
          .reduce((a, b) => {
            return a + b
          })
        const devices: number = stack
          .filter(item => item._id == 'perDevice')
          .map(item => item.data.map(item => item.count))[0]
          .reduce((a, b) => {
            return a + b
          })

        if (count / devices) {
          return (count / devices).toFixed(1)
        } else {
          return 0
        }
      } else {
        return 1
      }
    },
    getDevices: (stack: EventResponse[]) => {
      if (stack) {
        if (stack.length > 1) {
          const devices: number = stack
            .filter(item => item._id == 'perDevice')
            .map(item => item.data.map(item => item.count))[0]
            .reduce((a, b) => {
              return a + b
            })
          return devices.toFixed() ?? '0'
        } else {
          return 0
        }
      }
    },
    getStackCountPrevWeek: (stack: EventResponse[]) => {
      if (stack) {
        if (stack.length > 10) {
          return stack
            .filter(item => item._id == 'count')
            .map(item => item.data.map(item => item.count))[0]
            .slice(8, 15)
            .reduce((a, b) => {
              return a + b
            })
        } else {
          return 0
        }
      } else {
        return 0
      }
    },
    getStackDevicePrevWeek: (stack: EventResponse[]) => {
      if (stack) {
        if (stack.length > 15) {
          return stack
            .filter(item => item._id == 'perDevice')
            .map(item => item.data.map(item => item.count))[0]
            .slice(8, 15)
            .reduce((a, b) => {
              return a + b
            })
        } else {
          return 0
        }
      } else {
        return 0
      }
    },
    getStackCountPrevDay: (stack: EventResponse[]) => {
      if (stack && stack.length > 2) {
        return stack
          .filter(item => item._id == 'count')
          .map(item => item.data.map(item => item.count))[0]
          .slice(2)
          .reduce((a, b) => {
            return a + b
          })
      } else {
        return 0
      }
    },
    getStackDevicePrevDay: (stack: EventResponse[]) => {
      if (stack && stack.length > 2) {
        return stack
          .filter(item => item._id == 'perDevice')
          .map(item => item.data.map(item => item.count))[0]
          .slice(2)
          .reduce((a, b) => {
            return a + b
          })
      } else {
        return 0
      }
    },
    getWeekTrendData: () => {
      const prevWeek: number[] = [
        utils.getStackCountPrevWeek(moreStack as EventResponse[]),
        utils.getStackCountPrevWeek(servicesStack as EventResponse[]),
        utils.getStackCountPrevWeek(petsStack as EventResponse[]),
        utils.getStackCountPrevWeek(profileSections as EventResponse[]),
        utils.getStackCountPrevWeek(services as EventResponse[]),
        utils.getStackCountPrevWeek(homeStack as EventResponse[]),
        utils.getStackCountPrevWeek(petProfileSections as EventResponse[]),
        utils.getStackCountPrevWeek(openSubscriptionModal as EventResponse[]),
        utils.getStackCountPrevWeek(petsSharing as EventResponse[]),
        utils.getStackCountPrevWeek(createEvent as EventResponse[]),
        utils.getStackCountPrevWeek(petsSharingInApp as EventResponse[]),
      ]
      const res: number[] = prevWeek.map(
        (item, index) => data().map(item => item.count)[index] - item,
      )
      return res
    },
    getWeekTrendDataDevices: () => {
      const prevWeek: number[] = [
        utils.getStackDevicePrevWeek(moreStack as EventResponse[]),
        utils.getStackDevicePrevWeek(servicesStack as EventResponse[]),
        utils.getStackDevicePrevWeek(petsStack as EventResponse[]),
        utils.getStackDevicePrevWeek(profileSections as EventResponse[]),
        utils.getStackDevicePrevWeek(services as EventResponse[]),
        utils.getStackDevicePrevWeek(homeStack as EventResponse[]),
        utils.getStackDevicePrevWeek(petProfileSections as EventResponse[]),
        utils.getStackDevicePrevWeek(openSubscriptionModal as EventResponse[]),
        utils.getStackDevicePrevWeek(petsSharing as EventResponse[]),
        utils.getStackDevicePrevWeek(createEvent as EventResponse[]),
        utils.getStackDevicePrevWeek(petsSharingInApp as EventResponse[]),
      ]
      const res: number[] = prevWeek.map(
        (item, index) => data().map(item => item.count)[index] - item,
      )
      return res
    },
    getDayTrendData: () => {
      const prevDay: number[] = [
        utils.getStackCountPrevDay(moreStack as EventResponse[]),
        utils.getStackCountPrevDay(servicesStack as EventResponse[]),
        utils.getStackCountPrevDay(petsStack as EventResponse[]),
        utils.getStackCountPrevDay(profileSections as EventResponse[]),
        utils.getStackCountPrevDay(services as EventResponse[]),
        utils.getStackCountPrevDay(homeStack as EventResponse[]),
        utils.getStackCountPrevDay(petProfileSections as EventResponse[]),
        utils.getStackCountPrevDay(openSubscriptionModal as EventResponse[]),
        utils.getStackCountPrevDay(petsSharing as EventResponse[]),
        utils.getStackCountPrevDay(createEvent as EventResponse[]),
        utils.getStackCountPrevDay(petsSharingInApp as EventResponse[]),
      ]

      const res: number[] = prevDay.map((item, index) =>
        Number((data().map(item => item.count)[index] - item).toFixed(2)),
      )

      return res
    },
    getDayTrendDataDevices: () => {
      const prevDay: number[] = [
        utils.getStackDevicePrevDay(moreStack as EventResponse[]),
        utils.getStackDevicePrevDay(servicesStack as EventResponse[]),
        utils.getStackDevicePrevDay(petsStack as EventResponse[]),
        utils.getStackDevicePrevDay(profileSections as EventResponse[]),
        utils.getStackDevicePrevDay(services as EventResponse[]),
        utils.getStackDevicePrevDay(homeStack as EventResponse[]),
        utils.getStackDevicePrevDay(petProfileSections as EventResponse[]),
        utils.getStackDevicePrevDay(openSubscriptionModal as EventResponse[]),
        utils.getStackDevicePrevDay(petsSharing as EventResponse[]),
        utils.getStackDevicePrevDay(createEvent as EventResponse[]),
        utils.getStackDevicePrevDay(petsSharingInApp as EventResponse[]),
      ]

      const res: number[] = prevDay.map((item, index) =>
        Number((data().map(item => item.count)[index] - item).toFixed(2)),
      )

      return res
    },
    sort: (name: string, tendention: string) => {
      if (tendention == 'ASC') {
        setTypedPlaces(
          data().sort(
            (a, b) =>
              (b[name as keyof TSort] as number) -
              (a[name as keyof TSort] as number),
          ),
        )
      } else {
        setTypedPlaces(
          data().sort(
            (a, b) =>
              (a[name as keyof TSort] as number) -
              (b[name as keyof TSort] as number),
          ),
        )
      }

      setSort(prev => ({ ...prev, [name]: !sort[name as keyof TSort] }))
    },
  }

  useEffect(() => {
    Requests.getMoreStackEvent()
    Requests.getServicesStackEvent()
    Requests.getPetsStackEvent()
    Requests.getProfileSections()
    Requests.getServices()
    Requests.getHomeStack()
    Requests.getPetProfileSections()
    Requests.getOpenSubscriptionModal()
    Requests.getPetsSharing()
    Requests.getCreateEvent()
    Requests.getPetsSharingInApp()
    Requests.getAllEvents()

    if (dropdownOption == 'month') {
      setWeekData(utils.getWeekTrendData())
    } else if (dropdownOption == 'week') {
      setPrevDayData(utils.getDayTrendData())
    }
    if (dropdownOption == 'month') {
      setWeekDataDevices(utils.getWeekTrendDataDevices())
    } else if (dropdownOption == 'week') {
      setPrevDayDataDevices(utils.getDayTrendDataDevices())
    }
  }, [dropdownOption, platform])

  // useEffect(() => {
  //   console.log(platform)
  //   dispatch(SetPlatformAction('android'))
  // }, [platform])

  useEffect(() => {
    setTypedPlaces(data())
  }, [
    moreStack,
    servicesStack,
    petsStack,
    profileSections,
    services,
    homeStack,
    petProfileSections,
    openSubscriptionModal,
    petsSharing,
    createEvent,
    petsSharingInApp,
  ])

  return (
    <Container>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
        <Title>Аналитика трендов</Title>

        <Divider height={60} />

        <StyledDropDown>
          <Dropdown
            options={dropdownOptions}
            onChange={option => Events.changeType(option.value)}
            value={dropdownOption}
            placeholder="Виберите период"
          />
          <Dropdown
            options={changePlatformOptions}
            onChange={option => Events.changePlatform(option.value)}
            value={platform}
            placeholder="Виберите платформу"
          />
        </StyledDropDown>

        <Divider height={30} />

        <TableWrapper>
          <Table
            columns={columns}
            data={typedPlaces}
            components={{
              body: {
                row: StyledRow,
              },
              header: {
                cell: StyledHeader,
              },
              table: StyledTable,
            }}
          />
        </TableWrapper>
      </LocalizationProvider>
    </Container>
  )
}
