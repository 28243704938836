import styled from "styled-components";

export const StarRatingContainer = styled.div`
  display: flex;
`;

export const Label = styled.label``;

export const StarImageBlock = styled.div`
  position: relative;
  margin-right: 20px;
  display: flex;
`;

export const StyledImage = styled.img`
  position: absolute;
  left: 50%;
  top: 50%;
`;