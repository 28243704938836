import { takeLatest, call, put } from '@redux-saga/core/effects'
import { select } from 'redux-saga/effects'
import { getInfoSectionsAction, updateInfoSectionAction } from './actions'
import { AuthorizationError } from '../../errors'
import { PromiseReturnType } from '../types'
import { InfoSectionAPI } from './api.service'
import { showToastAction } from '../toasts'
import { generateErrorToast, generateSuccessToast } from '../../helpers'
import { Log } from '../../utils'
import { getUserSelector } from '../user'
import { AxiosError } from 'axios'

function* verifyTokenWorker() {
  const { token }: ReturnType<typeof getUserSelector> = yield select(
    getUserSelector,
  )

  if (token) return token

  throw new AuthorizationError('verifyTokenWorker')
}

function* getInfoSectionsWorker() {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof InfoSectionAPI.getInfoSections>
    > = yield call([InfoSectionAPI, InfoSectionAPI.getInfoSections], {
      authorization: token,
    })

    yield put(getInfoSectionsAction.success(response.data.sectionInfo))
  } catch (e) {
    Log.ruddy('Error: getInfoSectionsWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка получения информации'),
      ),
    )

    yield put(getInfoSectionsAction.failure(e as AxiosError))
  }
}

function* updateInfoSectionWorker({
  payload,
}: ReturnType<typeof updateInfoSectionAction>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof InfoSectionAPI.updateInfoSection>
    > = yield call([InfoSectionAPI, InfoSectionAPI.updateInfoSection], {
      data: payload.data,
      authorization: token,
      id: payload.id,
    })

    yield put(
      showToastAction.request(
        generateSuccessToast('Информация обновлена успешно'),
      ),
    )
  } catch (e) {
    Log.ruddy('Error: updateInfoSectionWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка обновления информации'),
      ),
    )
  }
}

export function* infoSectionWatcher() {
  yield takeLatest(getInfoSectionsAction.request, getInfoSectionsWorker)
  yield takeLatest(updateInfoSectionAction, updateInfoSectionWorker)
}
