import { combineReducers } from '@reduxjs/toolkit'
import { toastsReducer } from './toasts'
import { userReducer } from './user'
import { customerReducer } from './customers'
import { petsReducer } from './pets'
import { adminsReducer } from './settings'
import { infoSectionReducer } from './infoSection'
import { clinicsReducer } from './clinics'
import { feedbackReducer } from './feedback'
import { controlReducer } from './control'
import { placesReducer } from './places'
import { notificationsReducer } from './notifications'
import { dashboardReducer } from './dashboard'
import { supportReducer } from './support'
import { eventsboardReducer } from './eventsboard'
import { stackpropsReducer } from './stackprops'
import { adsReducer } from './ads'
import { categoriesReducer } from './categoriesAds'
import { clinicsCategoryReducer } from './clinicsCategory'

export default combineReducers({
  toasts: toastsReducer,
  user: userReducer,
  customers: customerReducer,
  pets: petsReducer,
  admins: adminsReducer,
  info: infoSectionReducer,
  clinics: clinicsReducer,
  feedback: feedbackReducer,
  control: controlReducer,
  places: placesReducer,
  notifications: notificationsReducer,
  dashboard: dashboardReducer,
  support: supportReducer,
  eventsboard: eventsboardReducer,
  stackprops: stackpropsReducer,
  ads: adsReducer,
  categories: categoriesReducer,
  clinicsCategory: clinicsCategoryReducer 
})
