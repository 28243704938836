import { NavLink as NavLinks } from 'react-router-dom'
import styled from 'styled-components'
import { FLEX, FONT, SHADOW } from '../../utils'
import { COLORS } from '../../assets'

export const TopbarWrapper = styled.div`
  width: 100%;
  padding: 5px 60px;

  height: 80px;

  background-color: ${COLORS.white};

  ${SHADOW({})};

  ${FLEX({
    align: 'center',
    justify: 'space-between',
    direction: 'row',
    wrap: 'nowrap',
  })}
`

export const LogoIcon = styled.img`
  width: 70px;
  height: 70px;

  border-radius: 50%;
`

export const Logo = styled.p`
  ${FONT({
    size: '20px',
    weight: '700',
    align: 'center',
    color: COLORS.lightBlue,
  })}
`

export const LayoutWrapper = styled.div`
  width: 100%;

  height: calc(100vh - 80px);

  ${FLEX({ align: 'flex-start', wrap: 'nowrap' })}
`

export const ContentWrapper = styled.div`
  width: calc(100vw - 270px);

  height: 100%;
  background: ${COLORS.darkWhite};
`

export const ContentInnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 45px 30px;
  overflow: hidden;
  overflow-y: auto;

  background-color: white;
`

export const Image = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 15px;

  cursor: pointer;

  ${FLEX({})}
`

export const SidebarWrapper = styled.div`
  width: 270px;
  height: 100%;

  background: ${COLORS.white};

  ${FLEX({ direction: 'column', justify: 'space-between', wrap: 'nowrap' })}
`

export const MenuWrapper = styled.div`
  width: 100%;
  height: 100%;

  padding: 45px 0;

  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  ${FLEX({
    direction: 'column',
    align: 'flex-end',
    justify: 'flex-start',
    wrap: 'nowrap',
  })}
`

export const NavLink = styled(NavLinks)`
  width: calc(100% - 30px);
  outline: 0;
  padding: 20px 55px 20px 30px;

  cursor: pointer;

  text-decoration: none;
  transition: 0.15s ease-in-out;

  ${FLEX({
    align: 'flex-start',
    justify: 'flex-start',
    wrap: 'nowrap',
    direction: 'column',
  })}

  ${FONT({ weight: '700', size: '16px', color: COLORS.lightBlue })}

  &.active {
    color: ${COLORS.green};
    background-color: ${COLORS.darkWhite};
    border-radius: 50px 0 0 50px;
  }
`

export const LogoutBtn = styled.div`
  margin-bottom: 25px;
  padding: 20px 55px 20px 30px;

  transition: 0.15s ease-in-out;
  cursor: pointer;

  ${FONT({ weight: '700', size: '16px', color: COLORS.lightBlue })}

  ${FLEX({})}
`

export const LogoContainer = styled.div`
  ${FLEX({
    direction: 'row',
    wrap: 'nowrap',
    justify: 'flex-start',
    align: 'center',
  })}
`

export const StyledText = styled.p`
  ${FONT({ weight: '600', size: '14px' })}
  padding-left: 80px;
  cursor: pointer;
  &:hover {
    color: #1f166a;
    transition: 0.3s;
  }
`
