import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment'

import { Assets } from '../../assets'
import { StarRating } from '../../components/StarRating'
import { useTypedSelector } from '../../hooks'
import { getSupport, supportActions } from '../../store/support'

import {
  Avatar,
  Container,
  FullWidth,
  Header,
  Image,
  Message,
  ReviewBlock,
  ReviewsContainer,
  StarRatingBlock,
  StyledButton,
  StyledButtonEnd,
  StyledPagination,
  Text,
  ReviewInfo,
  ImageBlock,
  LikeIcon,
  Span,
  Likes,
} from './styled'
import { PAGINATION_LIMIT } from './consts'

export const Review = () => {
  const { id } = useParams<{ id: string }>()
  const dispatch = useDispatch()
  const history = useHistory()

  const { reviews } = useTypedSelector(getSupport)

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [reviewId, setReviewId] = useState('')

  const openCommentHandler = (id: string) => {
    setReviewId(id)
  }

  const getReviewstData = () => {
    dispatch(
      supportActions.getReviews({
        clinic: id,
        page: currentPage,
        limit: PAGINATION_LIMIT,
      }),
    )
  }

  useEffect(() => {
    getReviewstData()
  }, [currentPage])

  const choosePage = (page: number) => {
    setCurrentPage(page === 0 ? 1 : page)
  }

  const navigateHandler = () => {
    history.goBack()
  }

  return (
    <Container>
      <Image src={Assets.LEFT_ARROW_ICON} onClick={navigateHandler} />
      <ReviewsContainer>
        {reviews.docs.map((review: any) => (
          <>
           <ReviewBlock width={'100%'} padding='10px' borderRadius='12px' background='white' margin='0 0 10px 0'>
            <ReviewInfo>
              <Header>
                <Avatar src={review?.user?.photo} />
                <Text>{review?.user?.name}</Text>
              </Header>
              <StarRatingBlock>
                <StarRating rating={review?.rating} />
              </StarRatingBlock>
              <Message margin='30px 0 0 0'>{review?.comment}</Message>
            </ReviewInfo>
            <ReviewInfo>
              <Text>
                {moment(review?.createdAt).format('DD-MM-YYYY HH:mm')}
              </Text>
              <Likes margin='0 0 10px 0'>
                <ImageBlock>
                  <Span>{review?.likes}</Span>
                  <LikeIcon src={Assets.LIKE} />
                </ImageBlock>
                <ImageBlock>
                  <Span>{review?.dislikes}</Span>
                  <LikeIcon src={Assets.DIS_LIKE} />
                </ImageBlock>
              </Likes>
              {review?.reply?._id && (
                <Text onClick={() => openCommentHandler(review?._id)}>
                  Коментарии
                </Text>
              )}
            </ReviewInfo>
          </ReviewBlock>
           {review?._id === reviewId && (
              <ReviewBlock width={'90%'} padding='10px 5px' borderRadius='0' background='#E0E0E0' margin='-10px 0 50px 0'>
                <ReviewInfo>
                  <Header>
                    <Avatar src={review?.reply?.user?.photo} />
                    <Text>{review?.user?.name}</Text>
                  </Header>
                  <Message margin='0'>{review?.reply?.comment}</Message>
                </ReviewInfo>
                <ReviewInfo>
                  <Text>
                    {moment(review?.reply?.createdAt).format('DD-MM-YYYY HH:mm')}
                  </Text>
                  <Likes margin='0'>
                    <ImageBlock>
                      <Span>{review?.reply?.likes}</Span>
                      <LikeIcon src={Assets.LIKE} />
                    </ImageBlock>
                    <ImageBlock>
                      <Span>{review?.reply?.dislikes}</Span>
                      <LikeIcon src={Assets.DIS_LIKE} />
                    </ImageBlock>
                  </Likes>
                </ReviewInfo>
              </ReviewBlock>
            )}
          </>
        ))}
      </ReviewsContainer>
      <FullWidth>
        <StyledPagination>
          {currentPage === 1 ? (
            <StyledButtonEnd>Назад</StyledButtonEnd>
          ) : (
            <StyledButton onClick={() => choosePage(currentPage - 1)}>
              Назад
            </StyledButton>
          )}

          <div>
            {currentPage}/
            {!reviews.totalCount
              ? 1
              : Math.ceil(reviews.totalCount / PAGINATION_LIMIT) === 0
              ? 1
              : Math.ceil(reviews.totalCount / PAGINATION_LIMIT)}
          </div>

          {currentPage ===
          (!reviews.totalCount
            ? 1
            : Math.ceil(reviews.totalCount / PAGINATION_LIMIT)) ? (
            <StyledButtonEnd>Далее</StyledButtonEnd>
          ) : (
            <StyledButton onClick={() => choosePage(currentPage + 1)}>
              Далее
            </StyledButton>
          )}
        </StyledPagination>
      </FullWidth>
    </Container>
  )
}
