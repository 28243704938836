import React from 'react'
import { Divider } from '../../../components'
import { FullWidth, ProfileInput, StyledCategory } from '../styled'
import { TCategoryObject } from '../types'

export type TMultiLangForm = {
  description: {}
  name: {}
  specialisation: {}
}

export const makeMultiLangForm = (languages: string[]) => {
  return languages.reduce(
    (store, lang) => ({
      ...store,
      description: { ...store.description, [lang]: '' },
      name: { ...store.name, [lang]: '' },
      specialisation: { ...store.specialisation, [lang]: '' },
    }),
    {} as TMultiLangForm,
  )
}

export const generateCategory = (
  categoryObject: TCategoryObject,
  category: string,
  handleCheck: (name: string) => void,
) => {
  const categoryArray: any[] = []

  for (const [key, value] of Object.entries(categoryObject)) {
    categoryArray.push(
      <FullWidth key={key + value.en}>
        <ProfileInput
          name={key}
          type="radio"
          checked={category === key}
          onChange={() => handleCheck(key)}
        />

        <Divider width={10} />

        <StyledCategory>{value.ru}</StyledCategory>
      </FullWidth>,
    )
  }

  return categoryArray
}
