import { TClassNamesProps, TClassNamesWithConditionsProps } from './types'

export const classNames = (classes: TClassNamesProps) => classes.join(' ')

export const classNamesWithConditions = (
  classes: TClassNamesWithConditionsProps,
) =>
  Object.entries(classes)
    .filter(([, value]) => value)
    .map(([key]) => key)
    .join(' ')
