import styled from "styled-components";
import { COLORS } from "../../assets";
import { FLEX, FONT } from "../../utils";

export const Container = styled.div``;

export const Image = styled.img`
  width: 20px;
  height: 20px;

  cursor: pointer;
`;

export const ReviewsContainer = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ReviewBlock = styled.div<any>`
  display: flex;
  justify-content: space-between;
  padding: ${({ padding }) => padding};

  background-color: ${({ background }) => background};

  border-radius: ${({ borderRadius }) => borderRadius};

  margin: ${({ margin }) => margin};
  width: ${({ width }) => width};
`;

export const ReviewInfo = styled.div``;

export const Header = styled.div`
  display: flex;
  align-items: center;
`;

export const Message = styled.div<{margin: string}>`
  margin: ${({ margin }) => margin};
  font-size: 14px;
`;

export const Avatar = styled.img`
  width: 40px;
  height: 40px;

  border-radius: 50%;

  margin-right: 10px;
`;

export const Text = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  cursor: pointer;
`;

export const StarRatingBlock = styled.div`
  margin: 15px 0;
`;

export const StyledButton = styled.div`
  padding: 10px 10px 10px 10px;
  border: 1px solid;
  text-decoration: none;
  border-radius: 10px;

  width: 30%;

  transition: 0.15s ease-in-out;
  cursor: pointer;

  &:hover {
    background: ${COLORS.aqua};
  }

  ${FONT({ weight: '700', size: '16px', color: COLORS.lightBlue })}

  ${FLEX({})}
`

export const StyledButtonEnd = styled.div`
  padding: 10px 10px 10px 10px;
  border: 1px solid;
  text-decoration: none;
  border-radius: 10px;
  border-color: ${COLORS.lightGray};

  width: 30%;

  transition: 0.15s ease-in-out;

  ${FONT({ weight: '700', size: '16px', color: COLORS.lightGray })}

  ${FLEX({})}
`

export const StyledPagination = styled.div`
  margin-bottom: 10px;
  ${FLEX({ justify: 'space-between' })}
`

export const FullWidth = styled.div`
  width: 100%;

  margin-top: 40px;
`;

export const Date = styled.div`
  font-weight: 700;
`; 

export const Likes = styled.div<any>`
  display: flex;
  justify-content: space-between;

  margin: ${({ margin }) => margin}
`;

export const ImageBlock = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

export const LikeIcon = styled.img`
  width: 34px;
  height: 34px;
`;

export const Span = styled.span`
  font-weight: 700;
  font-size: 17px;

  margin-right: 8px;
`;