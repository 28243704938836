import { createReducer } from '@reduxjs/toolkit';

import { supportActions } from './actions';
import { TInitialState } from './types';

const InitialState: TInitialState = {
	supports: {
		docs: [],
		meta: null
	},
	review: {
		docs: [],
		meta: null
	},
	reviews: {
		docs: [],
		meta: null
	},
	support: null,
	loading: false,
	response: null,
}

export const supportReducer = createReducer<TInitialState>(InitialState, (builder) => {
	builder
		.addCase(supportActions.setSupports, (state, { payload }) => ({ ...state, supports: payload }))
		.addCase(supportActions.setSupport, (state, { payload }) => ({ ...state, support: payload}))
		.addCase(supportActions.setReviews, (state, { payload }) => ({ ...state, reviews: payload}))
		.addCase(supportActions.setReview, (state, { payload }) => ({ ...state, review: payload}))
		.addCase(supportActions.setSupporsState, (state, { payload }) => ({ ...state, response: null, ...payload }))		
})