import React, { Fragment, FC } from 'react'
import { getToastSelector } from '../../store'
import { useTypedSelector } from '../../hooks'
import { Divider } from '../../components'
import { AlertsBar } from './styled'
import Context from './context'
import Toast from './Toast'

const ToastsProvider: FC = ({ children }) => {
  const { toasts } = useTypedSelector(getToastSelector)

  return (
    <Context.Provider value={null}>
      {children}

      <AlertsBar>
        {toasts.map((toast, index) => (
          <Fragment key={`snackbar-alert-${toast.id}`}>
            {!!index && <Divider height={5} />}

            <Toast data={toast} />
          </Fragment>
        ))}
      </AlertsBar>
    </Context.Provider>
  )
}

export default ToastsProvider
