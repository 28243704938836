import React, { FC } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { RoutesTree } from './routes'

const NoAuthorizated: FC = () => {
  return (
    <Switch>
      <Route
        exact
        path={RoutesTree.login.path}
        component={RoutesTree.login.component}
      />
      <Route
        exact
        path={RoutesTree.changePassword.path}
        component={RoutesTree.changePassword.component}
      />

      <Redirect to={RoutesTree.login.path} />
    </Switch>
  )
}

export default NoAuthorizated
