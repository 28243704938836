import { createReducer } from '@reduxjs/toolkit'

import {
  createCategoriesClinicsCategoryAction,
  deleteCategoriesClinicsCategoryAction,
  getCategoriesClinicsCategoryAction,
  updateCategoriesClinicsCategoryAction,
} from './actions'
import { TInitialState } from './types'

const InitialState: TInitialState = {
  categoriesClinics: [],
  loading: false,
  totalCount: 0,
  response: null,
}

export const clinicsCategoryReducer = createReducer<TInitialState>(
  InitialState,
  builder => {
    builder.addCase(getCategoriesClinicsCategoryAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(
      getCategoriesClinicsCategoryAction.success,
      (state, { payload }) => ({
        ...state,
        categoriesClinics: payload.categoriesClinics,
        totalCount: payload.totalCount,
        loading: false,
        response: 'GET_CATEGORIES',
      }),
    )
    builder.addCase(
      getCategoriesClinicsCategoryAction.failure,
      (state, { payload }) => ({
        ...state,
        error: payload,
        loading: false,
        response: 'GET_CATEGORIES',
      }),
    )
    builder.addCase(createCategoriesClinicsCategoryAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(
      createCategoriesClinicsCategoryAction.success,
      (state, { payload }) => ({
        ...state,
        loading: false,
        response: 'CREATE_CATEGORIES',
      }),
    )
    builder.addCase(
      createCategoriesClinicsCategoryAction.failure,
      (state, { payload }) => ({
        ...state,
        error: payload,
        loading: false,
        response: 'CREATE_CATEGORIES',
      }),
    )

    builder.addCase(updateCategoriesClinicsCategoryAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(
      updateCategoriesClinicsCategoryAction.success,
      (state, { payload }) => ({
        ...state,
        loading: false,
        response: 'UPDATE_CATEGORIES',
      }),
    )
    builder.addCase(
      updateCategoriesClinicsCategoryAction.failure,
      (state, { payload }) => ({
        ...state,
        error: payload,
        loading: false,
        response: 'UPDATE_CATEGORIES',
      }),
    )

    builder.addCase(deleteCategoriesClinicsCategoryAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(
      deleteCategoriesClinicsCategoryAction.success,
      (state, { payload }) => ({
        ...state,
        loading: false,
        response: 'DELETE_CATEGORIES',
      }),
    )
    builder.addCase(
      deleteCategoriesClinicsCategoryAction.failure,
      (state, { payload }) => ({
        ...state,
        error: payload,
        loading: false,
        response: 'DELETE_CATEGORIES',
      }),
    )
  },
)
