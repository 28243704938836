export const genderType = { MALE: 'Мальчик', FEMALE: 'Девочка' }

export const options = {
  responsive: true,
  plugins: {
    title: {
      display: true,
      text: 'Пол питомцев',
      font: {
        size: 20,
      },
    },
    legend: {
      display: false,
      position: 'top' as const,
    },
  },
  maintainAspectRatio: false,
}
