import { AxiosError } from 'axios'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { AuthorizationError } from '../../errors'
import { generateErrorToast } from '../../helpers'
import { Log } from '../../utils'
import { showToastAction } from '../toasts'
import { PromiseReturnType } from '../types'
import { getUserSelector } from '../user'
import {
  GetCreateEventEventAction,
  GetHomeStackEventAction,
  GetMoreStackEventAction,
  GetOpenSubscriptionModalEventAction,
  GetPetProfileSectionsEventAction,
  GetPetsSharingEventAction,
  GetPetsSharingInAppEventAction,
  GetPetsStackEventAction,
  GetProfileSectionsEventAction,
  GetServicesEventAction,
  GetServicesStackEventAction,
} from './action'
import { EventsboardAPI } from './api.service'

function* verifyTokenWorker() {
  const { token }: ReturnType<typeof getUserSelector> = yield select(
    getUserSelector,
  )

  if (token) return token

  throw new AuthorizationError('verifyTokenWorker')
}

function* getMoreStackEventWorker({
  payload,
}: ReturnType<typeof GetMoreStackEventAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof EventsboardAPI.getStackEvent>
    > = yield call([EventsboardAPI, EventsboardAPI.getStackEvent], {
      authorization: token,
      start: payload.start,
      end: payload.end,
      event: payload.event,
      app: payload.app,
    })

    yield put(GetMoreStackEventAction.success(response.data))
  } catch (e) {
    Log.ruddy('Error: getMoreStackEventWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения MoreStack')),
    )

    yield put(GetMoreStackEventAction.failure(e as AxiosError))
  }
}

function* getServicesStackEventWorker({
  payload,
}: ReturnType<typeof GetServicesStackEventAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof EventsboardAPI.getStackEvent>
    > = yield call([EventsboardAPI, EventsboardAPI.getStackEvent], {
      authorization: token,
      start: payload.start,
      end: payload.end,
      event: payload.event,
      app: payload.app,
    })

    yield put(GetServicesStackEventAction.success(response.data))
  } catch (e) {
    Log.ruddy('Error: getServicesStackEventWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка получения ServicesStack'),
      ),
    )

    yield put(GetServicesStackEventAction.failure(e as AxiosError))
  }
}

function* getPetsStackEventWorker({
  payload,
}: ReturnType<typeof GetPetsStackEventAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof EventsboardAPI.getStackEvent>
    > = yield call([EventsboardAPI, EventsboardAPI.getStackEvent], {
      authorization: token,
      start: payload.start,
      end: payload.end,
      event: payload.event,
      app: payload.app,
    })

    yield put(GetPetsStackEventAction.success(response.data))
  } catch (e) {
    Log.ruddy('Error: getPetsStackEventWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения PetsStack')),
    )

    yield put(GetPetsStackEventAction.failure(e as AxiosError))
  }
}

function* getProfileSectionsEventWorker({
  payload,
}: ReturnType<typeof GetProfileSectionsEventAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof EventsboardAPI.getStackEvent>
    > = yield call([EventsboardAPI, EventsboardAPI.getStackEvent], {
      authorization: token,
      start: payload.start,
      end: payload.end,
      event: payload.event,
      app: payload.app,
    })

    yield put(GetProfileSectionsEventAction.success(response.data))
  } catch (e) {
    Log.ruddy('Error: getProfileSectionsEventWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка получения ProfileSections'),
      ),
    )

    yield put(GetProfileSectionsEventAction.failure(e as AxiosError))
  }
}

function* getServicesEventWorker({
  payload,
}: ReturnType<typeof GetServicesEventAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof EventsboardAPI.getStackEvent>
    > = yield call([EventsboardAPI, EventsboardAPI.getStackEvent], {
      authorization: token,
      start: payload.start,
      end: payload.end,
      event: payload.event,
      app: payload.app,
    })

    yield put(GetServicesEventAction.success(response.data))
  } catch (e) {
    Log.ruddy('Error: getServicesEventWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения Services')),
    )

    yield put(GetServicesEventAction.failure(e as AxiosError))
  }
}

function* getHomeStackEventWorker({
  payload,
}: ReturnType<typeof GetHomeStackEventAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof EventsboardAPI.getStackEvent>
    > = yield call([EventsboardAPI, EventsboardAPI.getStackEvent], {
      authorization: token,
      start: payload.start,
      end: payload.end,
      event: payload.event,
      app: payload.app,
    })

    yield put(GetHomeStackEventAction.success(response.data))
  } catch (e) {
    Log.ruddy('Error: getHomeStackEventWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения HomeStack')),
    )

    yield put(GetHomeStackEventAction.failure(e as AxiosError))
  }
}

function* getPetProfileSectionsEventWorker({
  payload,
}: ReturnType<typeof GetPetProfileSectionsEventAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof EventsboardAPI.getStackEvent>
    > = yield call([EventsboardAPI, EventsboardAPI.getStackEvent], {
      authorization: token,
      start: payload.start,
      end: payload.end,
      event: payload.event,
      app: payload.app,
    })

    yield put(GetPetProfileSectionsEventAction.success(response.data))
  } catch (e) {
    Log.ruddy('Error: getPetProfileSectionsEventWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка получения PetProfileSections'),
      ),
    )

    yield put(GetPetProfileSectionsEventAction.failure(e as AxiosError))
  }
}

function* getOpenSubscriptionModalEventWorker({
  payload,
}: ReturnType<typeof GetOpenSubscriptionModalEventAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof EventsboardAPI.getStackEvent>
    > = yield call([EventsboardAPI, EventsboardAPI.getStackEvent], {
      authorization: token,
      start: payload.start,
      end: payload.end,
      event: payload.event,
      app: payload.app,
    })

    yield put(GetOpenSubscriptionModalEventAction.success(response.data))
  } catch (e) {
    Log.ruddy('Error: getOpenSubscriptionModalEventWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка получения OpenSubscriptionModal'),
      ),
    )

    yield put(GetOpenSubscriptionModalEventAction.failure(e as AxiosError))
  }
}

function* getPetsSharingEventWorker({
  payload,
}: ReturnType<typeof GetPetsSharingEventAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof EventsboardAPI.getStackEvent>
    > = yield call([EventsboardAPI, EventsboardAPI.getStackEvent], {
      authorization: token,
      start: payload.start,
      end: payload.end,
      event: payload.event,
      app: payload.app,
    })

    yield put(GetPetsSharingEventAction.success(response.data))
  } catch (e) {
    Log.ruddy('Error: getPetsSharingEventWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка получения PetsSharing'),
      ),
    )

    yield put(GetPetsSharingEventAction.failure(e as AxiosError))
  }
}

function* getCreateEventEventWorker({
  payload,
}: ReturnType<typeof GetCreateEventEventAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof EventsboardAPI.getStackEvent>
    > = yield call([EventsboardAPI, EventsboardAPI.getStackEvent], {
      authorization: token,
      start: payload.start,
      end: payload.end,
      event: payload.event,
      app: payload.app,
    })

    yield put(GetCreateEventEventAction.success(response.data))
  } catch (e) {
    Log.ruddy('Error: getCreateEventEventWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка получения CreateEvent'),
      ),
    )

    yield put(GetCreateEventEventAction.failure(e as AxiosError))
  }
}

function* getPetsSharingInAppEventWorker({
  payload,
}: ReturnType<typeof GetPetsSharingInAppEventAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof EventsboardAPI.getStackEvent>
    > = yield call([EventsboardAPI, EventsboardAPI.getStackEvent], {
      authorization: token,
      start: payload.start,
      end: payload.end,
      event: payload.event,
      app: payload.app,
    })

    yield put(GetPetsSharingInAppEventAction.success(response.data))
  } catch (e) {
    Log.ruddy('Error: getPetsSharingInAppEventWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка получения CreateEvent'),
      ),
    )

    yield put(GetPetsSharingInAppEventAction.failure(e as AxiosError))
  }
}

export function* eventsboardWatcher() {
  yield takeLatest(GetMoreStackEventAction.request, getMoreStackEventWorker)
  yield takeLatest(
    GetServicesStackEventAction.request,
    getServicesStackEventWorker,
  )
  yield takeLatest(GetPetsStackEventAction.request, getPetsStackEventWorker)
  yield takeLatest(
    GetProfileSectionsEventAction.request,
    getProfileSectionsEventWorker,
  )
  yield takeLatest(GetServicesEventAction.request, getServicesEventWorker)
  yield takeLatest(GetHomeStackEventAction.request, getHomeStackEventWorker)
  yield takeLatest(
    GetPetProfileSectionsEventAction.request,
    getPetProfileSectionsEventWorker,
  )
  yield takeLatest(
    GetOpenSubscriptionModalEventAction.request,
    getOpenSubscriptionModalEventWorker,
  )
  yield takeLatest(GetPetsSharingEventAction.request, getPetsSharingEventWorker)
  yield takeLatest(GetCreateEventEventAction.request, getCreateEventEventWorker)
  yield takeLatest(
    GetPetsSharingInAppEventAction.request,
    getPetsSharingInAppEventWorker,
  )
}
