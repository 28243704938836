import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore/lite'

const firebaseConfig = {
  apiKey: 'AIzaSyAUgdiak8t5JwGMMNMf-HFNiASWRuCTQOk',
  authDomain: 'petshealth-dab2c.firebaseapp.com',
  projectId: 'petshealth-dab2c',
  storageBucket: 'petshealth-dab2c.appspot.com',
  messagingSenderId: '183796190270',
  appId: '1:183796190270:web:bb36bebe3581f14f88d56f',
}

const app = initializeApp(firebaseConfig)
export const db = getFirestore(app)
