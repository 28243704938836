import React, { FC, useEffect, useState } from 'react'
import { StyledAccordion } from '../styled'
import { Accordion } from 'react-bootstrap'
import { PhonesProps } from '../types'
import { useTypedSelector } from '../../../hooks'
import { CategoryObject } from './consts'
import { generateCategory } from './helpers'

export const Category: FC<PhonesProps> = ({ form, setForm }) => {
  const { clinic } = useTypedSelector(state => state.clinics)

  const [category, setCategory] = useState<string>('')

  const handleCheck = (name: string) => {
    setCategory(name)

    setForm(prev => ({
      ...prev,
      specialisation: CategoryObject[name as keyof typeof CategoryObject],
    }))
  }

  useEffect(() => {
    setCategory(
      Object.keys(CategoryObject).find(el =>
        Object.values(
          CategoryObject[el as keyof typeof CategoryObject],
        ).includes(clinic?.specialisation?.ru ?? ''),
      ) ?? '',
    )
  }, [clinic])

  return (
    <StyledAccordion>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="1">
          <Accordion.Header>Основной профиль клиники</Accordion.Header>
          <Accordion.Body>
            {generateCategory(CategoryObject, category, handleCheck)}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </StyledAccordion>
  )
}
