export const petType = {
  cat: 'Кот',
  dog: 'Собака',
  bird: 'Птица',
  humster: 'Хомяк',
  ferret: 'Хорек',
  toad: 'Рептилия',
  snail: 'Беспозвоночный питомец',
  fish: 'Рыбки',
  spider: 'Членистоногий питомец',
  amphibian: 'Амфибия',
  monkey: 'Экзотический питомец',
  dinosaur: 'Особенный питомец',
  rabbit: 'Кролик',
}

export const options = {
  indexAxis: 'y' as const,
  responsive: true,
  plugins: {
    title: {
      display: true,
      text: 'Типы питомцев',
      font: {
        size: 20,
      },
    },
    legend: {
      display: false,
      position: 'top' as const,
    },
  },
  maintainAspectRatio: false,
}
