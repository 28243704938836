import styled from 'styled-components'

export const AlertsBar = styled.aside`
  max-height: 100vh;
  padding: 30px 10px;

  position: fixed;
  z-index: 1002;
  bottom: 0;
  right: 0;

  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`
