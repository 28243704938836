import { createActionTypes, createApiActions } from '../actions-util'
import { createAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import {
  GetInfoSectionsActionResponse,
  UpdateInfoSectionActionPayload,
} from './types'

export const getInfoSectionsAction = createApiActions<
  void,
  GetInfoSectionsActionResponse,
  AxiosError
>(createActionTypes('INFO/GET_INFO_SECTIONS'))

export const updateInfoSectionAction = createAction<
  UpdateInfoSectionActionPayload,
  'INFO/UPDATE_INFO_SECTION'
>('INFO/UPDATE_INFO_SECTION')
