import { createActionTypes, createApiActions } from '../actions-util'
import { createAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import {
  CreatePlaceActionPayload,
  DeletePlaceActionPayload,
  DeleteImageFromPlaceActionPayload,
  GetPlaceByIdActionPayload,
  GetPlaceByIdActionResponse,
  GetPlacesActionResponse,
  GetPlacesPayload,
  putPlaceLogoActionPayload,
  PutData,
  SearchPlaceActionPayload,
  UpdatePlaceActionPayload,
} from './types'

export const getPlacesAction = createApiActions<
  GetPlacesPayload,
  GetPlacesActionResponse,
  AxiosError
>(createActionTypes('PLACES/GET_PLACES'))

export const createPlaceAction = createApiActions<
  CreatePlaceActionPayload,
  GetPlacesActionResponse,
  AxiosError
>(createActionTypes('PLACES/CREATE_PLACE'))

export const updatePlaceAction = createApiActions<
  UpdatePlaceActionPayload,
  GetPlacesActionResponse,
  AxiosError
>(createActionTypes('PLACES/UPDATE_PLACE'))

export const putPlaceLogoAction = createApiActions<
  putPlaceLogoActionPayload,
  GetPlacesActionResponse,
  AxiosError
>(createActionTypes('PLACES/PUT_PLACE_LOGO'))

export const addPhotoToPlaceAction = createApiActions<
  putPlaceLogoActionPayload,
  GetPlacesActionResponse,
  AxiosError
>(createActionTypes('PLACES/ADD_PHOTO_TO_PLACE'))

export const addDocumentToPlaceAction = createApiActions<
  putPlaceLogoActionPayload,
  GetPlacesActionResponse,
  AxiosError
>(createActionTypes('PLACES/ADD_DOCUMENT_TO_PLACE'))

export const searchPlaceAction = createApiActions<
  SearchPlaceActionPayload,
  GetPlacesActionResponse,
  AxiosError
>(createActionTypes('PLACES/SEARCH_PLACE'))

export const deleteImageFromPlaceAction = createApiActions<
  DeleteImageFromPlaceActionPayload,
  GetPlacesActionResponse,
  AxiosError
>(createActionTypes('PLACES/DELETE_IMAGE_FROM_PLACE'))

export const deleteDocumentFromPlaceAction = createApiActions<
  DeleteImageFromPlaceActionPayload,
  GetPlacesActionResponse,
  AxiosError
>(createActionTypes('PLACES/DELETE_DOCUMENT_FROM_PLACE'))

export const putDataToPlaceAction = createAction<
  PutData,
  'PLACES/PUT_WORKING_TIME_TO_PLACE'
>('PLACES/PUT_WORKING_TIME_TO_PLACE')

export const getPlaceByIdAction = createApiActions<
  GetPlaceByIdActionPayload,
  GetPlaceByIdActionResponse,
  AxiosError
>(createActionTypes('PLACES/GET_PLACE_BY_ID'))

export const clearPlaceAction = createAction<void, 'PLACES/CLEAR_PLACE'>(
  'PLACES/CLEAR_PLACE',
)

export const deletePlaceAction = createAction<
  DeletePlaceActionPayload,
  'PLACES/DELETE_PLACE'
>('PLACES/DELETE_PLACE')
