import { api } from '../config'

export const CLINICS_URL = {
  get_clinics: (page: number, limit: number, search: string, sortBy: string, type: string) =>
    `${api.withAPI}/admin/clinics/?page=${page}&limit=${limit}&search=${search}&sortBy=${sortBy}&type=${type}`,
  getClinicById: (id: string) => `${api.withAPI}/admin/clinics/${id}`,
  createClinic: `${api.withAPI}/admin/clinics`,
  updateClinic: (id: string) => `${api.withAPI}/admin/clinics/${id}`,
  putClinicLogo: (id: string) =>
    `${api.withImageAPI}/admin/clinics/update-logo/${id}`,
  deleteClinic: (id: string) => `${api.withAPI}/admin/clinics/${id}`,
  addImageToClinic: (id: string) =>
    `${api.withImageAPI}/admin/clinics/add-photo/${id}`,
  addDocumentToClinic: (id: string) =>
    `${api.withImageAPI}/admin/clinics/add-document/${id}`,
  deleteImageFromClinic: (id: string, url: string) =>
    `${api.withAPI}/admin/clinics/delete-photo/${id}/${url}`,
  deleteDocumentFromClinic: (id: string, url: string) =>
    `${api.withAPI}/admin/clinics/delete-document/${id}/${url}`,
  searchClinic: (limit: number, page: number, title: string) =>
    `${api.withAPI}/admin/clinics/?limit=${limit}&page=${page}&title=${title}`,
}
