import React from 'react'
import { withRouter } from 'react-router-dom'
import {
  SidebarWrapper,
  NavLink,
  MenuWrapper,
  Image,
  LogoutBtn,
} from './styled'

import { useDispatch } from 'react-redux'
import { Assets } from '../../assets'
import { logoutAction } from '../../store'
import { useTypedSelector } from '../../hooks'

const fieldName = {
  Питомцы: 'pets',
  Пользователи: 'customers',
  Настройки: 'settings',
  Информация: 'info',
  Клиники: 'clinics',
  Бизнес: 'clinics',
  Приюты: 'clinics',
  Жалобы: 'complaint',
  Предложения: 'proposal',
  Неточности: 'inaccuracy',
  Контроль: 'control',
  Места: 'places',
  // 'Категории клиник': 'categories',
  'Категории объявлений': 'categories',
  Объявления: 'ads',
  'Жалобы на объявления': 'ads',
  Уведомления: 'notifications',
  'Аналит. графики': 'dashboard',
  'Аналит. клики': 'eventsboard',
  'Управл. ф-налом': 'regions',
  'Обратная связь': 'feedback',
}

export default withRouter(function Sidebar({ onMenuItemClick }: any) {
  const dispatch = useDispatch()

  const { user } = useTypedSelector(store => store.user)

  const signout = () => {
    dispatch(logoutAction())
  }

  const sidebarMenus = [
    {
      name: 'Аналит. графики',
      path: '/dashboard',
      exact: false,
      icon: Assets.DASHBOARD_ICON,
    },
    {
      name: 'Аналит. клики',
      path: '/eventsboard',
      exact: false,
      icon: Assets.DASHBOARD_ICON,
    },
    {
      name: 'Пользователи',
      path: '/customers',
      exact: false,
      icon: Assets.CUSTOMERS_ICON,
    },
    {
      name: 'Питомцы',
      path: '/pets',
      exact: false,
      icon: Assets.PETS_ICON,
    },
    {
      name: 'Информация',
      path: '/info-section',
      exact: false,
      icon: Assets.INFO_SECTION_ICON,
    },
    {
      name: 'Настройки',
      path: '/settings',
      exact: false,
      icon: Assets.SETTINGS_ICON,
    },
    {
      name: 'Клиники',
      path: '/clinics',
      exact: false,
      icon: Assets.CLINICS_ICON,
    },
    {
      name: 'Бизнес',
      path: '/bisnes-clinks',
      exact: false,
      icon: Assets.CLINICS_ICON,
    },
    {
      name: 'Приюты',
      path: '/social-clinks',
      exact: false,
      icon: Assets.CLINICS_ICON,
    },
    {
      name: 'Места',
      path: '/places',
      exact: false,
      icon: Assets.PLACES_ICON,
    },
    {
      name: 'Обратная связь',
      path: '/feedback',
      exact: false,
      icon: Assets.PROPOSAL_ICON,
    },
    {
      name: 'Категории объявлений',
      path: '/categories',
      exact: false,
      icon: Assets.CATEGORIES_ICON,
    },
    // {
    //   name: 'Категории клиник',
    //   path: '/clinicsCategory',
    //   exact: false,
    //   icon: Assets.CATEGORIES_ICON,
    // },
    {
      name: 'Жалобы',
      path: '/complaint',
      exact: false,
      icon: Assets.COMPLAINT_ICON,
    },
    {
      name: 'Предложения',
      path: '/proposal',
      exact: false,
      icon: Assets.PROPOSAL_ICON,
    },
    {
      name: 'Неточности',
      path: '/inaccuracy',
      exact: false,
      icon: Assets.INACCURACY_ICON,
    },
    {
      name: 'Объявления',
      path: '/advertisement',
      exact: false,
      icon: Assets.NOTIFICATION_ICON,
    },
    {
      name: 'Жалобы на объявления',
      path: '/complaintsAds',
      exact: false,
      icon: Assets.INACCURACY_ICON,
    },
    {
      name: 'Контроль',
      path: '/control',
      exact: false,
      icon: Assets.CONTROL_ICON,
    },
    {
      name: 'Уведомления',
      path: '/notifications',
      exact: false,
      icon: Assets.NOTIFICATION_ICON,
    },
    {
      name: 'Управл. ф-налом',
      path: '/regions',
      exact: false,
      icon: Assets.REGIONS_ICON,
    },
  ]

  return (
    <SidebarWrapper>
      <MenuWrapper>
        {sidebarMenus.map((menu: any, index: number) => {
          {
            return (
              // @ts-ignore
              user?.access?.[fieldName[menu.name]] && (
                <NavLink
                  key={menu.name + index}
                  to={menu.path}
                  exact={menu.exact}
                  onClick={onMenuItemClick}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {menu.icon ? (
                      <Image
                        style={{ justifySelf: 'center' }}
                        src={menu.icon}
                      />
                    ) : (
                      ''
                    )}
                    {menu.name}
                  </div>
                </NavLink>
              )
            )
          }
        })}
      </MenuWrapper>

      <LogoutBtn onClick={signout}>
        <Image src={Assets.LOGOUT_ICON} />
        Выйти
      </LogoutBtn>
    </SidebarWrapper>
  )
})
