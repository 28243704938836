import React, { FC, useEffect, useState } from 'react'
import {
  AddButton,
  ButtonField,
  CheckboxImage,
  ContainerAccordion,
  StyledAccordion,
  StyledDeleteButton,
  StyledEntity,
  StyledPhoneNumber,
} from '../styled'
import { Accordion } from 'react-bootstrap'
import { Divider, EInputTemplate, Input } from '../../../components'
import { PhonesProps, TService } from '../types'
import { ServicesArray } from './consts'
import { Assets } from '../../../assets'
import { useTypedSelector } from '../../../hooks'
import _ from 'lodash'

export const Services: FC<PhonesProps> = ({ form, setForm }) => {
  const { place } = useTypedSelector(state => state.places)

  const [temporaryParsedServices, setTemporaryParsedServices] =
    useState<string>('')
  const [addParsedServicesIndicator, setAddParsedServicesIndicator] =
    useState<boolean>(false)
  const [services, setServices] = useState<TService[]>(ServicesArray)

  const addParsedServicesHandler = () => {
    setAddParsedServicesIndicator(prev => !prev)
  }

  const temporaryParsedServicesHandler = (e: any) => {
    setTemporaryParsedServices(e.target.value)
  }

  const changeService = (element: TService) => {
    return () => {
      if (
        !!form.medicalDirections.filter(el => el.name.ru === element.name.ru)
          .length
      ) {
        setForm(prev => ({
          ...prev,
          medicalDirections: prev.medicalDirections.filter(
            el => el.name.ru !== element.name.ru,
          ),
        }))
      } else {
        setForm(prev => ({
          ...prev,
          medicalDirections: [...prev.medicalDirections, element],
        }))
      }
    }
  }

  const addtemporaryParsedServicesToStack = (name: string) => {
    if (
      !!temporaryParsedServices &&
      !!form.medicalDirections.filter(el => el.name.ru === name).length &&
      !form.medicalDirections
        .filter(el => el.name.ru === name)[0]
        .services.filter(el => el === temporaryParsedServices).length
    ) {
      setServices(prev =>
        prev.map(el => {
          if (el.name.ru === name) {
            return {
              ...el,
              services: [...el.services, temporaryParsedServices],
            }
          } else {
            return el
          }
        }),
      )

      setForm(prev => ({
        ...prev,
        medicalDirections: prev.medicalDirections.map(v => {
          if (v.name.ru === name) {
            return {
              ...v,
              services: [...v.services, temporaryParsedServices],
            }
          } else {
            return v
          }
        }),
      }))

      setTemporaryParsedServices('')
    }
  }

  const deleteParsedServices = (element: string, servicesName: string) => {
    setServices(prev =>
      prev.map(el => {
        if (el.name.ru === servicesName) {
          return {
            ...el,
            services: el.services.filter(temp => temp !== element),
          }
        } else {
          return el
        }
      }),
    )

    setForm(prev => ({
      ...prev,
      medicalDirections: prev.medicalDirections.map(v => {
        if (v.name.ru === servicesName) {
          return {
            ...v,
            services: v.services.filter(temp => temp !== element),
          }
        } else {
          return v
        }
      }),
    }))

    setTemporaryParsedServices('')
  }

  useEffect(() => {
    setServices(prev =>
      _.uniqBy([...(place?.medicalDirections ?? []), ...prev], 'name'),
    )
  }, [place])

  return (
    <StyledAccordion>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="1">
          <Accordion.Header>Сервисы (услуги) клиники</Accordion.Header>
          <Accordion.Body>
            <Accordion defaultActiveKey="0">
              {services.map((el, index) => (
                <ContainerAccordion key={el?.name?.ru + index}>
                  <CheckboxImage
                    onClick={changeService(el)}
                    src={
                      !!form.medicalDirections.filter(
                        service => service.name.ru === el?.name?.ru,
                      ).length
                        ? Assets.CHECKBOX_ACTIVE_ICON
                        : Assets.CHECKBOX_ICON
                    }
                  />

                  <StyledAccordion>
                    <Accordion.Item eventKey={(index + 1).toString()}>
                      <Accordion.Header>{el?.name?.ru}</Accordion.Header>
                      <Accordion.Body>
                        {el?.services.length !== 0 ? (
                          <>
                            {el?.services.map((v, ind) => (
                              <div key={v + index + 1 + ind}>
                                <StyledPhoneNumber>
                                  <div>{v}</div>
                                  <Divider width={10} />
                                  <StyledDeleteButton
                                    onClick={() =>
                                      deleteParsedServices(v, el?.name?.ru)
                                    }
                                  >
                                    Удалить
                                  </StyledDeleteButton>
                                </StyledPhoneNumber>

                                <Divider height={10} />
                              </div>
                            ))}
                            {addParsedServicesIndicator ? (
                              <>
                                <StyledEntity>
                                  <Input
                                    label="Услуга"
                                    value={temporaryParsedServices ?? ''}
                                    placeholder="Введите услугу"
                                    template={EInputTemplate.NAME}
                                    onChange={v =>
                                      temporaryParsedServicesHandler(v)
                                    }
                                  />
                                </StyledEntity>
                                <ButtonField>
                                  <AddButton
                                    onClick={() =>
                                      addtemporaryParsedServicesToStack(
                                        el.name.ru,
                                      )
                                    }
                                  >
                                    Добавить
                                  </AddButton>

                                  <Divider width={10} />

                                  <StyledDeleteButton
                                    onClick={addParsedServicesHandler}
                                  >
                                    Отмена
                                  </StyledDeleteButton>
                                </ButtonField>
                              </>
                            ) : (
                              <AddButton onClick={addParsedServicesHandler}>
                                Добавить услугу
                              </AddButton>
                            )}
                          </>
                        ) : addParsedServicesIndicator ? (
                          <>
                            <StyledEntity>
                              <Input
                                label="Услуга"
                                value={temporaryParsedServices ?? ''}
                                placeholder="Введите услугу"
                                template={EInputTemplate.NAME}
                                onChange={v =>
                                  temporaryParsedServicesHandler(v)
                                }
                              />
                            </StyledEntity>
                            <ButtonField>
                              <AddButton
                                onClick={() =>
                                  addtemporaryParsedServicesToStack(el.name.ru)
                                }
                              >
                                Добавить
                              </AddButton>

                              <Divider width={10} />

                              <StyledDeleteButton
                                onClick={addParsedServicesHandler}
                              >
                                Отмена
                              </StyledDeleteButton>
                            </ButtonField>
                          </>
                        ) : (
                          <AddButton onClick={addParsedServicesHandler}>
                            Добавить услугу
                          </AddButton>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  </StyledAccordion>
                </ContainerAccordion>
              ))}
            </Accordion>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </StyledAccordion>
  )
}
