import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import { Divider, EInputTemplate, Input } from '../../components'
import {
  Container,
  StyledData,
  StyledEntity,
  Image,
  Title,
  StyledTitle,
  Div,
  StyledField,
  StyledUnionField,
  StyledFieldText,
  StyledNameFieldText,
  StyledButton,
  StyledDiv,
} from './styled'
import { Assets } from '../../assets'
import { TForm, TChangeManagerValidationSchema, TAccess } from './types'
import { useTypedSelector, useValidation } from '../../hooks'
import { Log } from '../../utils'
import { RoutesTree } from '../../router'
import { createAdminAction } from '../../store'

const fieldName = {
  pets: 'Питомцы',
  customers: 'Пользователи',
  settings: 'Настройки',
  info: 'Информация',
  clinics: 'Клиники',
  complaint: 'Жалобы',
  proposal: 'Предложения',
  inaccuracy: 'Неточности',
  control: 'Контроль',
  places: 'Места',
  notifications: 'Уведомления',
  dashboard: 'Панель',
  regions: 'Регионы',
}


const AdminCreate: React.FC = () => {
  const [form, setForm] = useState<TForm>({
    name: '',
    email: '',
    password: '',
  })
  const [access, setAccess] = useState<TAccess>({
    pets: false,
    customers: false,
    settings: false,
    info: false,
    clinics: false,
    complaint: false,
    proposal: false,
    inaccuracy: false,
    control: false,
    places: false,
    notifications: false,
    dashboard: false,
    regions: false,
  })

  const dispatch = useDispatch()

  const { user } = useTypedSelector(state => state.user)

  if (!user?.access?.settings) {
    return <Redirect to={RoutesTree.notFound.path} />
  }
  // eslint-disable-next-line
  const inputHandler = useCallback(
    e => {
      setForm(prev => ({ ...prev, [e.target.name]: e.target.value }))
    },
    [form],
  )
  // eslint-disable-next-line
  const schema = useMemo<TChangeManagerValidationSchema>(
    () => ({
      name: {
        condition: !!form.name && form.name.length >= 2,
        error: `Введите имя`,
      },
      email: {
        condition:
          !!form.email && form.email.length >= 5 && form.email?.includes('@'),
        error: `Неверный формат email`,
      },
      password: {
        condition: !!form.password && form.password.length >= 6,
        error: `Неверный формат пароля`,
      },
    }),
    [form],
  )

  const {
    validationSchema,
    disableValidation,
    validation,
    enableValidation,
    isEnabled,
    // eslint-disable-next-line
  } = useValidation(schema)

  const choose = (key: string, value: boolean) => {
    setAccess(prev => ({ ...prev, [key]: value }))
  }

  const generateAccess = () => {
    const adminAccess = []

    for (const [key, value] of Object.entries(access)) {
      adminAccess.push(
        <StyledField key={key}>
          {/* @ts-ignore */}
          <StyledFieldText>{fieldName[key]}</StyledFieldText>

          <StyledDiv>
            <input
              name={key}
              type="radio"
              checked={value}
              onChange={() => choose(key, true)}
            />
            <Divider width={190} />
            <input
              name={key}
              type="radio"
              onChange={() => choose(key, false)}
              checked={!value}
            />
          </StyledDiv>
        </StyledField>,
      )
    }
    return adminAccess
  }

  const onPressCreateAdmin = async () => {
    try {
      enableValidation()
      await validation()

      const result = window.confirm('Создать админа?')

      if (result) {
        dispatch(
          createAdminAction({
            access,
            email: form.email as string,
            name: form.name as string,
            password: form.password,
            authorization: '',
          }),
        )
      }

      disableValidation()
    } catch (error) {
      Log.ruddy('Event: onPressSaveChanges')
    }
  }

  return (
    <Container>
      <StyledTitle>
        {Assets.LEFT_ARROW_ICON ? (
          <Link
            to={{
              pathname: RoutesTree.settings.path,
            }}
          >
            <Image src={Assets.LEFT_ARROW_ICON} />
          </Link>
        ) : (
          ''
        )}

        <Title>Создание Админа</Title>

        <Div> </Div>
      </StyledTitle>

      <Divider height={10} />

      <StyledData>
        <StyledEntity>
          <Input
            label="Имя"
            name="name"
            value={form.name ?? ''}
            placeholder="Your name"
            template={EInputTemplate.NAME}
            error={schema.name.error || ''}
            onChange={v => inputHandler(v)}
            isValid={!isEnabled || validationSchema.name}
          />
        </StyledEntity>

        <StyledEntity>
          <Input
            label="E-mail"
            name="email"
            value={form.email ?? ''}
            placeholder="Your Email"
            error={schema.email.error || ''}
            template={EInputTemplate.DEFAULT}
            onChange={v => inputHandler(v)}
            isValid={!isEnabled || validationSchema.email}
          />
        </StyledEntity>

        <StyledEntity>
          <Input
            label="Пароль"
            name="password"
            value={form.password ?? ''}
            placeholder="Your Password"
            error={schema.password.error || ''}
            template={EInputTemplate.PASSWORD}
            onChange={v => inputHandler(v)}
            isValid={!isEnabled || validationSchema.password}
          />
        </StyledEntity>

        <StyledUnionField>
          <StyledField>
            <StyledFieldText>Доступ</StyledFieldText>
            <>
              <StyledNameFieldText>Разрешен</StyledNameFieldText>
              <StyledNameFieldText>Запрещен</StyledNameFieldText>
            </>
          </StyledField>
        </StyledUnionField>

        <Divider height={10} />

        <StyledUnionField>{generateAccess()}</StyledUnionField>

        <Divider height={10} />

        <StyledButton onClick={onPressCreateAdmin}>Создать</StyledButton>

        <Divider height={10} />
      </StyledData>
    </Container>
  )
}

export default AdminCreate
