import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, Redirect, useHistory, useParams } from 'react-router-dom'
import { Divider, EInputTemplate, Input } from '../../components'
import {
  Container,
  StyledData,
  StyledEntity,
  Image,
  Title,
  StyledTitle,
  DeleteButton,
  Div,
  StyledInputWrapper,
  StyledTextArea,
  Label,
  ButtonContainer,
  StyledLink,
} from './styled'
import { Assets } from '../../assets'
import { TForm } from './types'
import { useTypedSelector } from '../../hooks'
import { Log } from '../../utils'
import { RoutesTree } from '../../router'
import {
  deleteComplaintAction,
  clearInaccuracyAction,
  getInaccuracyByIdAction,
} from '../../store'
import moment from 'moment'

// @ts-ignore
const InaccuracyInfo: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const [form, setForm] = useState<TForm>({
    title: '',
    description: '',
  })

  const history = useHistory()

  const dispatch = useDispatch()

  const { inaccur } = useTypedSelector(store => store.feedback)
  const { user } = useTypedSelector(state => state.user)

  if (!user?.access?.inaccuracy) {
    return <Redirect to={RoutesTree.notFound.path} />
  }

  const onPressDeleteInaccuracy = async () => {
    try {
      const result = window.confirm('Удалить неточность?')

      if (result) {
        dispatch(
          deleteComplaintAction({
            authorization: '',
            id,
          }),
        )

        history.goBack()
      }
    } catch (error) {
      Log.ruddy('Event: onPressDeleteInaccuracy')
    }
  }
  // eslint-disable-next-line
  useEffect(() => {
    dispatch(getInaccuracyByIdAction.request({ id }))
    return () => {
      dispatch(clearInaccuracyAction())
    }
  }, [id])
  // eslint-disable-next-line
  useEffect(() => {
    setForm({
      title: !!inaccur?.title ? inaccur?.title : '-',
      description: !!inaccur?.description ? inaccur?.description : '-',
    })
  }, [inaccur])

  return (
    <Container>
      <StyledTitle>
        {Assets.LEFT_ARROW_ICON ? (
          <Link
            to={{
              pathname: RoutesTree.inaccuracy.path,
            }}
          >
            <Image src={Assets.LEFT_ARROW_ICON} />
          </Link>
        ) : (
          ''
        )}

        <Title>Неточность</Title>

        <Div> </Div>
      </StyledTitle>

      <Divider height={10} />

      <StyledData>
        <StyledEntity>
          <Input
            label="Название"
            name="title"
            value={form.title ?? '-'}
            template={EInputTemplate.DEFAULT}
            onChange={() => {}}
            disabled
          />
        </StyledEntity>

        <StyledInputWrapper>
          <Label>Описание</Label>

          <Divider height={10} />

          <StyledTextArea
            name="description"
            value={form.description ?? '-'}
            onChange={() => {}}
            disabled
          />

          <Divider height={5} />
        </StyledInputWrapper>

        <Divider height={10} />

        <StyledEntity>
          <Input
            label="Название клиники"
            name="clinicTitle"
            value={inaccur?.clinic?.Title ?? '-'}
            template={EInputTemplate.DEFAULT}
            onChange={() => {}}
            disabled
          />
        </StyledEntity>

        <Divider height={10} />

        <StyledEntity>
          <Input
            label="Дата и время создания"
            name="date"
            value={moment(inaccur?.createdAt ?? '-').format('HH:mm DD.MM.YYYY')}
            template={EInputTemplate.DEFAULT}
            onChange={() => {}}
            disabled
          />
        </StyledEntity>

        <Divider height={20} />

        <ButtonContainer>
          <StyledLink
            to={{ pathname: `/clinic-info/${inaccur?.clinic?._id ?? ''}` }}
          >
            Перейти к клинике
          </StyledLink>

          <DeleteButton onClick={onPressDeleteInaccuracy}>Удалить</DeleteButton>
        </ButtonContainer>
        <Divider height={10} />
      </StyledData>
    </Container>
  )
}

export default InaccuracyInfo
