export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Пользователи и питомцы',
      font: {
        size: 20,
      },
    },
  },
  maintainAspectRatio: false,
}

export const sessionCountsOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Количество сессий за день',
      font: {
        size: 20,
      },
    },
  },
  maintainAspectRatio: false,
}

export const YearArray = {
  March: 'Март',
  April: 'Апрель',
  May: 'Май',
  June: 'Июнь',
  July: 'Июль',
  August: 'Август',
  September: 'Сентябрь',
  October: 'Октябрь',
  November: 'Ноябрь',
  December: 'Декабрь',
  January: 'Январь',
  February: 'Февраль',
}
