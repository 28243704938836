import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Redirect,
  useHistory,
  useParams,
} from 'react-router-dom'
import { Divider, EInputTemplate, Input } from '../../components'
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'
import {
  Container,
  StyledData,
  StyledEntity,
  Image,
  Title,
  StyledTitle,
  SaveButton,
  DeleteButton,
  StyledContainer,
  ProfileImage,
  Div,
  StyledGender,
  StyledSort,
  StyledText,
  StyledAllergiesContainer,
  StyledVaccinesContainer,
  StyledDropDown,
  StyledAccordion,
  StyledRedText,
  StyledLink,
} from './styled'
import { TTypes } from './types'
import { Assets } from '../../assets'
import { TForm, TChangeManagerValidationSchema } from './types'
import { useTypedSelector, useValidation } from '../../hooks'
import { Log } from '../../utils'
import { RoutesTree } from '../../router'
import {
  getPetByIdAction,
  clearPetAction,
  updatePetAction,
  deletePetAction,
} from '../../store'
import moment from 'moment'
import { TAllergie } from '../../store/pets/types'
import { Accordion } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

const typeForDropDown = [
  'cat',
  'dog',
  'bird',
  'humster',
  'ferret',
  'toad',
  'snail',
  'fish',
  'spider',
  'amphibian',
  'monkey',
  'dinosaur',
  'rabbit'
]

const petType = [
  'Кот',
  'Собака',
  'Птица',
  'Хомяк',
  'Хорек',
  'Рептилия',
  'Беспозвоночный питомец',
  'Рыбки',
  'Членистоногий питомец',
  'Амфибия',
  'Экзотический питомец',
  'Особенный питомец',
  'Кролик'
]

const gender = ['Мальчик', 'Девочка']

const genderType = ['MALE', 'FEMALE']

const genderTypeObject = {
  MALE: 'Мальчик',
  FEMALE: 'Девочка',
}

const petTypeObject = {
  cat: 'Кот',
  dog: 'Собака',
  bird: 'Птица',
  humster: 'Хомяк',
  ferret: 'Хорек',
  toad: 'Рептилия',
  snail: 'Беспозвоночный питомец',
  fish: 'Рыбки',
  spider: 'Членистоногий питомец',
  amphibian: 'Амфибия',
  monkey: 'Экзотический питомец',
  dinosaur: 'Особенный питомец',
  rabbit: 'Кролик'
}

const PetDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const [form, setForm] = useState<TForm>({
    allergies: [],
    weight: '',
    breed: '',
    birthDate: moment().format('YYYY-MM-DD'),
    gender: genderTypeObject.MALE,
    vaccines: {
      RABIES: [],
      GERPES_ETC: [],
      VIRUS_ETC: [],
      OTHER: [],
    },
    name: '',
    type: petTypeObject.cat,
  })

  const history = useHistory()

  const dispatch = useDispatch()

  const { pet } = useTypedSelector(store => store.pets)
  const { user } = useTypedSelector(state => state.user)

  if (!user?.access?.pets) {
    return <Redirect to={RoutesTree.notFound.path} />
  }
  // eslint-disable-next-line
  const inputHandler = useCallback(
    e => {
      setForm(prev => ({ ...prev, [e.target.name]: e.target.value }))
    },
    [form],
  )
  // eslint-disable-next-line
  const schema = useMemo<TChangeManagerValidationSchema>(
    () => ({
      name: {
        condition: form.name
          ? form.name.length
            ? form.name.length >= 1
            : true
          : true,
        error: `Введите имя`,
      },
      breed: {
        condition: form.breed
          ? form.breed.length
            ? form.breed.length >= 1
            : true
          : true,
        error: `Введите породу`,
      },
      weight: {
        condition: form.weight ? !isNaN(Number(form.weight)) : true,
        error: `Введите вес`,
      },
    }),
    [form],
  )

  const {
    validationSchema,
    disableValidation,
    validation,
    enableValidation,
    isEnabled,
    // eslint-disable-next-line
  } = useValidation(schema)

  const changeGender = (type: any) => {
    //@ts-ignore
    if (type.value === 'Мальчик') {
      setForm(prev => ({ ...prev, gender: 'Мальчик' }))
    } else if (type.value === 'Девочка') {
      setForm(prev => ({ ...prev, gender: 'Девочка' }))
    }
  }

  const changeType = (type: TTypes) => {
    //@ts-ignore
    setForm(prev => ({ ...prev, type: type.value }))
  }

  const changeAllergies = (
    id: string,
    value: string,
    type: 'allergens' | 'category',
  ) => {
    let currentAllergia: TAllergie[] = form.allergies.filter(
      elem => elem.id === id,
    )

    const tempAllergie: TAllergie = {
      allergens: currentAllergia[0].allergens,
      category: currentAllergia[0].category,
      id: currentAllergia[0].id,
    }
    tempAllergie[type] = value

    setForm(prev => ({
      ...prev,
      allergies: [
        ...form.allergies.map(elem =>
          elem.id === id ? (tempAllergie as any) : elem,
        ),
      ],
    }))
  }

  const onPressSaveChanges = async () => {
    try {
      enableValidation()
      await validation()

      const result = window.confirm('Обновить питомца?')

      if (result) {
        form.type = typeForDropDown[petType.indexOf(form.type as string)]
        form.gender = genderType[gender.indexOf(form.gender as string)]

        dispatch(
          updatePetAction({
            data: form,
            authorization: '',
            id,
          }),
        )
        form.type = petType[typeForDropDown.indexOf(form.type as string)]
        form.gender = gender[genderType.indexOf(form.gender as string)]
      }

      disableValidation()
    } catch (error) {
      Log.ruddy('Event: onPressSaveChanges')
    }
  }

  const onPressDeletePet = () => {
    try {
      const result = window.confirm('Удалить питомца?')

      if (result) {
        dispatch(
          deletePetAction({
            authorization: '',
            id,
            userId: pet?.user ?? '',
          }),
        )

        history.goBack()
      }
    } catch (error) {
      Log.ruddy('Event: onPressDeletePet')
    }
  }
  // eslint-disable-next-line
  useEffect(() => {
    dispatch(getPetByIdAction.request({ id, authorization: '' }))

    return () => {
      dispatch(clearPetAction())
    }
  }, [dispatch, id])
  // eslint-disable-next-line
  useEffect(() => {
    setForm({
      weight: pet?.weight ?? '',
      breed: pet?.breed ?? '',
      birthDate:
        moment(pet?.birthDate).format('YYYY-MM-DD') ??
        moment().format('YYYY-MM-DD'),
      //@ts-ignore
      gender: genderTypeObject[pet?.gender ?? 'MALE'] ?? 'Мальчик',
      vaccines: pet?.vaccines ?? {
        RABIES: [],
        GERPES_ETC: [],
        VIRUS_ETC: [],
        OTHER: [],
      },
      name: pet?.name ?? '',
      type: petTypeObject[pet?.type ?? 'cat'] ?? petTypeObject.cat,
      allergies: pet?.allergies ?? [],
    })
  }, [pet])

  const getVaccineName = (key: string) => {
    let name = ''
    switch (key) {
      case 'RABIES':
        name = 'От бешенства'
        break
      case 'GERPES_ETC':
        name = 'От панлейкопении, калицивироза и герпеса'
        break
      case 'VIRUS_ETC':
        name = 'От вируса лейкемии и хламидиоза'
        break
      case 'OTHER':
        name = 'Другие прививки'
        break
      default:
        break
    }

    return name
  }

  const generateVacines = (ind: number) => {
    const vaccines = []

    for (const [key, value] of Object.entries(form.vaccines)) {
      ind = ind + 1
      vaccines.push(
        <Accordion.Item eventKey={ind.toString()} key={ind}>
          <Accordion.Header>{getVaccineName(key)}</Accordion.Header>
          <Accordion.Body>
            {value.length !== 0 ? (
              value.map((vac: any, ind: number) => {
                return (
                  <StyledVaccinesContainer key={ind}>
                    <div>Марка: {vac.brand}</div>
                    {vac?.purpose ? <div>От чего: {vac?.purpose}</div> : ''}
                    {vac?.serialNumber ? (
                      <div>Серийный номер: {vac?.serialNumber}</div>
                    ) : (
                      ''
                    )}
                    {vac?.periodicity ? (
                      <div>Переодичность: {vac?.periodicity}</div>
                    ) : (
                      ''
                    )}
                    {vac?.location ? <div>Место: {vac?.location}</div> : ''}
                    {vac?.executor ? (
                      <div>Исполнитель: {vac?.executor}</div>
                    ) : (
                      ''
                    )}
                    <div>
                      Дата: {moment(vac.vaccinationDate).format('DD.MM.YYYY')}
                    </div>
                    <Divider height={10} />
                  </StyledVaccinesContainer>
                )
              })
            ) : (
              <StyledRedText key={ind}>Нет такой вакцины</StyledRedText>
            )}
          </Accordion.Body>
        </Accordion.Item>,
      )
    }
    return vaccines
  }

  const exit = () => {
    history.goBack()
  }

  return (
    <Container>
      <StyledTitle>
        {Assets.LEFT_ARROW_ICON ? (
          <div onClick={exit}>
            <Image src={Assets.LEFT_ARROW_ICON} />
          </div>
        ) : (
          ''
        )}

        <Title>Информация</Title>

        <Div> </Div>
      </StyledTitle>

      <Divider height={10} />

      {pet?.photo ? (
        <ProfileImage src={pet?.photo} />
      ) : (
        <ProfileImage
          src={
            'https://cdn.pixabay.com/photo/2013/07/13/12/07/avatar-159236_1280.png'
          }
        />
      )}

      <Divider height={10} />

      <StyledData>
        <StyledEntity>
          <Input
            label="Имя"
            name="name"
            value={form.name ?? ''}
            placeholder="Введите Имя"
            template={EInputTemplate.NAME}
            error={schema.name.error || ''}
            onChange={v => inputHandler(v)}
            isValid={!isEnabled || validationSchema.name}
          />
        </StyledEntity>

        <StyledSort>
          <StyledText>Тип</StyledText>
          <Divider width={10} />
          <StyledDropDown>
            <Dropdown
              options={petType}
              onChange={value => changeType(value as any)}
              value={form.type as any}
              placeholder="Виберите тип"
            />
          </StyledDropDown>
        </StyledSort>

        <Divider height={20} />

        <StyledEntity>
          <Input
            label="Порода"
            name="breed"
            value={form.breed ?? ''}
            placeholder="Введите Породу"
            template={EInputTemplate.NAME}
            error={schema.breed.error || ''}
            onChange={v => inputHandler(v)}
            isValid={!isEnabled || validationSchema.breed}
          />
        </StyledEntity>

        <StyledGender>
          <StyledText>Пол</StyledText>

          <Divider width={10} />

          <StyledDropDown>
            <Dropdown
              options={gender}
              onChange={value => changeGender(value as any)}
              value={form.gender as any}
              placeholder="Виберите пол"
            />
          </StyledDropDown>
        </StyledGender>

        <Divider height={10} />

        <StyledEntity>
          <Input
            label="Вес"
            name="weight"
            value={form.weight ?? ''}
            placeholder="Введите Вес"
            template={EInputTemplate.NAME}
            error={schema.weight.error || ''}
            onChange={v => inputHandler(v)}
            isValid={!isEnabled || validationSchema.weight}
          />
        </StyledEntity>

        <StyledSort>
          <StyledText>День рождения</StyledText>
          <Divider width={10} />
          <input
            type="date"
            id="birthDate"
            name="birthDate"
            value={
              moment(form.birthDate).format('YYYY-MM-DD') ??
              moment().format('YYYY-MM-DD')
            }
            onChange={v => inputHandler(v)}
            max={new Date().toISOString()}
          />
        </StyledSort>

        <Divider height={20} />

        <StyledAccordion>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
              <Accordion.Header>Аллергии</Accordion.Header>
              <Accordion.Body>
                {form?.allergies && form.allergies.length !== 0 ? (
                  form.allergies.map(allergy => {
                    return (
                      <StyledAllergiesContainer key={allergy.id}>
                        <StyledEntity>
                          <Input
                            label="Категория"
                            name="category"
                            value={allergy.category ?? ''}
                            placeholder="Введите Категорию"
                            template={EInputTemplate.NAME}
                            error={
                              allergy.category
                                ? allergy.category.length >= 1
                                  ? ''
                                  : 'Введите Категорию'
                                : 'Введите Категорию' || ''
                            }
                            onChange={v =>
                              changeAllergies(
                                allergy.id as string,
                                //@ts-ignore
                                v.target.value,
                                'category',
                              )
                            }
                            isValid={!isEnabled}
                          />
                        </StyledEntity>
                        <StyledEntity>
                          <Input
                            label="Аллергены"
                            name="allergens"
                            value={allergy.allergens ?? ''}
                            placeholder="Введите Алергены"
                            template={EInputTemplate.NAME}
                            error={
                              allergy.allergens
                                ? allergy.allergens.length >= 1
                                  ? ''
                                  : 'Введите Аллергены'
                                : 'Введите Аллергены' || ''
                            }
                            onChange={v =>
                              changeAllergies(
                                allergy.id as string,
                                //@ts-ignore
                                v.target.value,
                                'allergens',
                              )
                            }
                            isValid={!isEnabled}
                          />
                        </StyledEntity>
                      </StyledAllergiesContainer>
                    )
                  })
                ) : (
                  <StyledRedText>Нет Аллергий</StyledRedText>
                )}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Вакцины</Accordion.Header>
              <Accordion.Body>
                {form?.vaccines ? (
                  <Accordion defaultActiveKey="0">
                    {generateVacines(2)}
                    {/*// Сюда прокинуть eventKey который выше */}
                  </Accordion>
                ) : (
                  <StyledText>Нет Вакцин</StyledText>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </StyledAccordion>

        <Divider height={10} />

        <StyledContainer>
          <StyledLink to={{ pathname: `/profile/${pet?.user ?? ''}` }}>
            Перейти к владельцу
          </StyledLink>

          <DeleteButton onClick={onPressDeletePet}>Удалить</DeleteButton>

          <SaveButton onClick={onPressSaveChanges}>Сохранить</SaveButton>
        </StyledContainer>

        <Divider height={10} />
      </StyledData>
    </Container>
  )
}

export default PetDetails
