import styled from 'styled-components'
import { FLEX, FONT } from '../../utils'
import { COLORS } from '../../assets'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  width: 100vw;
  height: 100vh;

  background: ${COLORS.gray};

  ${FLEX({})}
`

export const LoginWrapper = styled.div`
  width: 470px;

  padding: 50px;

  border-radius: 3px;

  background: ${COLORS.white};
`

export const Title = styled.h1`
  ${FONT({
    size: '20px',
    weight: '700',
    align: 'center',
    color: COLORS.lightBlue,
  })}
`

export const StyledLink = styled(Link)`
  ${FONT({
    size: '20px',
    weight: '400',
    align: 'center',
    color: COLORS.lightBlue,
  })}

  text-decoration: none;

  ${FLEX({})}
`
