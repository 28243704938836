import { AxiosResponse } from 'axios'
import {
  DeleteClinicActionPayload,
  DeleteImageFromClinicActionPayload,
  GetClinicByIdActionResponse,
  GetClinicsActionResponse,
  GetClinicsPayload,
  PayloadSearchById,
  putClinicLogoActionPayload,
  SearchClinicActionPayload,
} from './types'
import { setAuthorizationHeadersProp } from '../../helpers'
import { PayloadAuth } from '../types'
import { HttpService } from '../../services'
import { CLINICS_URL } from './config'

export class ClinicsAPI extends HttpService {
  static getClinics(
    request: GetClinicsPayload & PayloadAuth,
  ): Promise<AxiosResponse<GetClinicsActionResponse>> {
    return this.request<GetClinicsActionResponse>('GET_CLINICS', {
      url: CLINICS_URL.get_clinics(
        request.page,
        request.limit,
        request.search,
        request.sortBy,
        request.type,
      ),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static createClinic({
    authorization,
    data,
  }: PayloadAuth & any): Promise<AxiosResponse<GetClinicsActionResponse>> {
    return this.request<GetClinicsActionResponse>('CREATE_CLINIC', {
      url: CLINICS_URL.createClinic,
      method: 'POST',
      data,
      headers: {
        // 'Content-Type': 'form-data',
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static updateClinic({
    authorization,
    data,
    id,
  }: PayloadAuth & any): Promise<AxiosResponse<GetClinicsActionResponse>> {
    return this.request<GetClinicsActionResponse>('UPDATE_CLINIC', {
      url: CLINICS_URL.updateClinic(id),
      method: 'PUT',
      data,
      headers: {
        // 'Content-Type': 'form-data',
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static putClinicLogo({
    authorization,
    data,
    id,
  }: PayloadAuth & any): Promise<AxiosResponse<GetClinicsActionResponse>> {
    return this.request<GetClinicsActionResponse>('PUT_CLINIC_LOGO', {
      url: CLINICS_URL.putClinicLogo(id),
      method: 'PUT',
      data,
      headers: {
        'Content-Type': 'form-data',
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static addImageToClinic({
    authorization,
    data,
    id,
  }: PayloadAuth & putClinicLogoActionPayload): Promise<
    AxiosResponse<GetClinicsActionResponse>
  > {
    return this.request<GetClinicsActionResponse>('ADD_PHOTO_TO_CLINIC', {
      url: CLINICS_URL.addImageToClinic(id),
      method: 'PUT',
      data,
      headers: {
        'Content-Type': 'form-data',
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static addDocumentToClinic({
    authorization,
    data,
    id,
  }: PayloadAuth & putClinicLogoActionPayload): Promise<
    AxiosResponse<GetClinicsActionResponse>
  > {
    return this.request<GetClinicsActionResponse>('ADD_DOCUMENT_TO_CLINIC', {
      url: CLINICS_URL.addDocumentToClinic(id),
      method: 'PUT',
      data,
      headers: {
        'Content-Type': 'form-data',
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static deleteImageFromClinic({
    authorization,
    image,
    id,
  }: PayloadAuth & DeleteImageFromClinicActionPayload): Promise<
    AxiosResponse<GetClinicsActionResponse>
  > {
    return this.request<GetClinicsActionResponse>('DELETE_IMAGE_FROM_CLINIC', {
      url: CLINICS_URL.deleteImageFromClinic(id, image),
      method: 'DELETE',
      headers: {
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static deleteDocumentFromClinic({
    authorization,
    image,
    id,
  }: PayloadAuth & DeleteImageFromClinicActionPayload): Promise<
    AxiosResponse<GetClinicsActionResponse>
  > {
    return this.request<GetClinicsActionResponse>(
      'DELETE_DOCUMENT_FROM_CLINIC',
      {
        url: CLINICS_URL.deleteDocumentFromClinic(id, image),
        method: 'DELETE',
        headers: {
          ...setAuthorizationHeadersProp(authorization),
        },
      },
    )
  }

  static searchClinic(
    request: PayloadAuth & SearchClinicActionPayload,
  ): Promise<AxiosResponse<GetClinicsActionResponse>> {
    return this.request<GetClinicsActionResponse>('SEARCH_CLINIC', {
      url: CLINICS_URL.searchClinic(request.limit, request.page, request.title),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static getClinicById(
    request: PayloadSearchById,
  ): Promise<AxiosResponse<GetClinicByIdActionResponse>> {
    return this.request<GetClinicByIdActionResponse>('GET_CLINIC_BY_ID', {
      url: CLINICS_URL.getClinicById(request.id),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static deleteClinic(
    request: DeleteClinicActionPayload,
  ): Promise<AxiosResponse<void>> {
    return this.request('DELETE_CLINIC', {
      url: CLINICS_URL.deleteClinic(request.id),
      method: 'DELETE',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }
}
