import { AxiosError } from 'axios'
import { createActionTypes, createApiActions } from '../actions-util'
import { EventPropResponse, EventResponse, GetAnalyticPayload } from './types'

export const GetMoreStackEventAction = createApiActions<
  GetAnalyticPayload,
  EventResponse[],
  AxiosError
>(createActionTypes('EVENTSBOARD/GET_MORE_STACK_EVENT'))

export const GetServicesStackEventAction = createApiActions<
  GetAnalyticPayload,
  EventResponse[],
  AxiosError
>(createActionTypes('EVENTSBOARD/GET_SERVICES_STACK_EVENT'))

export const GetPetsStackEventAction = createApiActions<
  GetAnalyticPayload,
  EventResponse[],
  AxiosError
>(createActionTypes('EVENTSBOARD/GET_PETS_STACK_EVENT'))

export const GetProfileSectionsEventAction = createApiActions<
  GetAnalyticPayload,
  EventResponse[],
  AxiosError
>(createActionTypes('EVENTSBOARD/GET_PROFILE_SECTIONS_EVENT'))

export const GetServicesEventAction = createApiActions<
  GetAnalyticPayload,
  EventResponse[],
  AxiosError
>(createActionTypes('EVENTSBOARD/GET_SERVICES_EVENT'))

export const GetHomeStackEventAction = createApiActions<
  GetAnalyticPayload,
  EventResponse[],
  AxiosError
>(createActionTypes('EVENTSBOARD/GET_HOME_STACK_EVENT'))

export const GetPetProfileSectionsEventAction = createApiActions<
  GetAnalyticPayload,
  EventResponse[],
  AxiosError
>(createActionTypes('EVENTSBOARD/GET_PET_PROFILE_SECTIONS_EVENT'))

export const GetOpenSubscriptionModalEventAction = createApiActions<
  GetAnalyticPayload,
  EventResponse[],
  AxiosError
>(createActionTypes('EVENTSBOARD/GET_OPEN_SUBSCRIPTION_MODAL_SECTIONS_EVENT'))

export const GetPetsSharingEventAction = createApiActions<
  GetAnalyticPayload,
  EventResponse[],
  AxiosError
>(createActionTypes('EVENTSBOARD/GET_PETS_SHARING_EVENT'))

export const GetCreateEventEventAction = createApiActions<
  GetAnalyticPayload,
  EventResponse[],
  AxiosError
>(createActionTypes('EVENTSBOARD/GET_CREATE_EVENT_EVENT'))

export const GetPetsSharingInAppEventAction = createApiActions<
  GetAnalyticPayload,
  EventResponse[],
  AxiosError
>(createActionTypes('EVENTSBOARD/GET_PETS_SHARING_IN_APP_EVENT'))
