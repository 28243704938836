import { takeLatest, call, put } from '@redux-saga/core/effects'
import { select } from 'redux-saga/effects'
import {
  getActiveAndroidUsersAction,
  getActiveIosUsersAction,
  getActiveUsersSummary,
  GetAndroidModelsAction,
  GetAndroidSessionDurationAction,
  getDashboardInfoAction,
  GetIosModelsAction,
  GetIosSessionDurationAction,
  GetOsesAction,
  GetSessionCountsAction,
  GetIosVersionsAction,
  getYearInfoAction,
  GetAndroidVersionsAction,
  getPrevYearInfoAction,
  GetActiveIosUsersMonthAction,
  GetActiveAndroidUsersMonthAction,
  GetUsersByMonthAction,
  GetUsersByDateAction,
  GetPetsByMonthAction,
  GetAllIosVersionsAction,
  GetAllAndroidVersionsAction,
} from './actions'
import { AuthorizationError } from '../../errors'
import { PromiseReturnType } from '../types'
import { DashboardAPI } from './api.service'
import { showToastAction } from '../toasts'
import { generateErrorToast } from '../../helpers'
import { Log } from '../../utils'
import { getUserSelector } from '../user'
import { AxiosError } from 'axios'

function* verifyTokenWorker() {
  const { token }: ReturnType<typeof getUserSelector> = yield select(
    getUserSelector,
  )

  if (token) return token

  throw new AuthorizationError('verifyTokenWorker')
}

function* getDashboardInfoWorker({
  payload,
}: ReturnType<typeof getDashboardInfoAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof DashboardAPI.getDashboardInfo>
    > = yield call([DashboardAPI, DashboardAPI.getDashboardInfo], {
      authorization: token,
      year: payload.year,
    })

    yield put(getDashboardInfoAction.success(response.data))
    yield put(getYearInfoAction.success(response.data.yearsInfo))
  } catch (e) {
    Log.ruddy('Error: getDashboardInfoWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка получения статистики'),
      ),
    )

    yield put(getDashboardInfoAction.failure(e as AxiosError))
  }
}

function* getYearInfoWorker({
  payload,
}: ReturnType<typeof getYearInfoAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof DashboardAPI.getYearInfo>
    > = yield call([DashboardAPI, DashboardAPI.getYearInfo], {
      authorization: token,
      year: payload.year,
    })

    yield put(getYearInfoAction.success(response.data))
  } catch (e) {
    Log.ruddy('Error: getYearInfoWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка получения годовых значений'),
      ),
    )

    yield put(getYearInfoAction.failure(e as AxiosError))
  }
}

function* getPrevYearInfoWorker({
  payload,
}: ReturnType<typeof getPrevYearInfoAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof DashboardAPI.getYearInfo>
    > = yield call([DashboardAPI, DashboardAPI.getYearInfo], {
      authorization: token,
      year: payload.year,
    })

    yield put(getPrevYearInfoAction.success(response.data))
  } catch (e) {
    Log.ruddy('Error: getPrevYearInfoWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка получения годовых значений'),
      ),
    )

    yield put(getPrevYearInfoAction.failure(e as AxiosError))
  }
}

function* getActiveIosUsersWorker({
  payload,
}: ReturnType<typeof getActiveIosUsersAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof DashboardAPI.getActiveIosUsers>
    > = yield call([DashboardAPI, DashboardAPI.getActiveIosUsers], {
      authorization: token,
      start: payload.start,
      end: payload.end,
    })

    yield put(getActiveIosUsersAction.success(response.data))
  } catch (e) {
    Log.ruddy('Error: getActiveIosUsersWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка получения активных юзеров'),
      ),
    )

    yield put(getActiveIosUsersAction.failure(e as AxiosError))
  }
}

function* getActiveIosUsersMonthWorker({
  payload,
}: ReturnType<typeof GetActiveIosUsersMonthAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof DashboardAPI.getActiveIosUsers>
    > = yield call([DashboardAPI, DashboardAPI.getActiveIosUsers], {
      authorization: token,
      start: payload.start,
      end: payload.end,
    })

    yield put(GetActiveIosUsersMonthAction.success(response.data))
  } catch (e) {
    Log.ruddy('Error: GetActiveIosUsersMonthAction', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка получения активных юзеров'),
      ),
    )

    yield put(GetActiveIosUsersMonthAction.failure(e as AxiosError))
  }
}

function* getActiveAndroidUsersWorker({
  payload,
}: ReturnType<typeof getActiveAndroidUsersAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof DashboardAPI.getActiveAndroidUsers>
    > = yield call([DashboardAPI, DashboardAPI.getActiveAndroidUsers], {
      authorization: token,
      start: payload.start,
      end: payload.end,
      app: payload.app,
    })

    yield put(getActiveAndroidUsersAction.success(response.data))
  } catch (e) {
    Log.ruddy('Error: getActiveIosUsersWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка получения активных юзеров'),
      ),
    )

    yield put(getActiveAndroidUsersAction.failure(e as AxiosError))
  }
}

function* getActiveAndroidUsersMonthWorker({
  payload,
}: ReturnType<typeof GetActiveAndroidUsersMonthAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof DashboardAPI.getActiveAndroidUsers>
    > = yield call([DashboardAPI, DashboardAPI.getActiveAndroidUsers], {
      authorization: token,
      start: payload.start,
      end: payload.end,
      app: payload.app,
    })

    yield put(GetActiveAndroidUsersMonthAction.success(response.data))
  } catch (e) {
    Log.ruddy('Error: getActiveIosUsersWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка получения активных юзеров'),
      ),
    )

    yield put(GetActiveAndroidUsersMonthAction.failure(e as AxiosError))
  }
}

function* getActiveUsersSummaryWorker({}: ReturnType<
  typeof getActiveUsersSummary['request']
>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof DashboardAPI.getActiveUsersSummary>
    > = yield call([DashboardAPI, DashboardAPI.getActiveUsersSummary], {
      authorization: token,
    })

    yield put(getActiveUsersSummary.success(response.data))
  } catch (e) {
    Log.ruddy('Error: getActiveIosUsersWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка получения активных юзеров'),
      ),
    )

    yield put(getActiveUsersSummary.failure(e as AxiosError))
  }
}

function* getOSesWorker({
  payload,
}: ReturnType<typeof GetOsesAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<ReturnType<typeof DashboardAPI.getOses>> =
      yield call([DashboardAPI, DashboardAPI.getOses], {
        authorization: token,
        start: payload.start,
        end: payload.end,
        app: payload.app,
      })

    yield put(GetOsesAction.success(response.data))
  } catch (e) {
    Log.ruddy('Error: getActiveIosUsersWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения типов ОС')),
    )

    yield put(GetOsesAction.failure(e as AxiosError))
  }
}

function* getSessionCountsWorker({
  payload,
}: ReturnType<typeof GetSessionCountsAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof DashboardAPI.getSessionCounts>
    > = yield call([DashboardAPI, DashboardAPI.getSessionCounts], {
      authorization: token,
      start: payload.start,
      end: payload.end,
      app: payload.app,
    })

    yield put(GetSessionCountsAction.success(response.data))
  } catch (e) {
    Log.ruddy('Error: getActiveIosUsersWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения сессий')),
    )

    yield put(GetSessionCountsAction.failure(e as AxiosError))
  }
}

function* getIosModelsWorker({
  payload,
}: ReturnType<typeof GetIosModelsAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof DashboardAPI.getIosModels>
    > = yield call([DashboardAPI, DashboardAPI.getIosModels], {
      authorization: token,
      start: payload.start,
      end: payload.end,
    })

    yield put(GetIosModelsAction.success(response.data))
  } catch (e) {
    Log.ruddy('Error: getActiveIosUsersWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка получения моделей устройств'),
      ),
    )

    yield put(GetIosModelsAction.failure(e as AxiosError))
  }
}

function* getAndroidModelsWorker({
  payload,
}: ReturnType<typeof GetAndroidModelsAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof DashboardAPI.getAndroidModels>
    > = yield call([DashboardAPI, DashboardAPI.getAndroidModels], {
      authorization: token,
      start: payload.start,
      end: payload.end,
      app: payload.app,
    })

    yield put(GetAndroidModelsAction.success(response.data))
  } catch (e) {
    Log.ruddy('Error: getActiveIosUsersWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка получения моделей устройств'),
      ),
    )

    yield put(GetAndroidModelsAction.failure(e as AxiosError))
  }
}

function* getAndroidSessionDurationWorker({
  payload,
}: ReturnType<typeof GetAndroidSessionDurationAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof DashboardAPI.getAndroidSessionDuration>
    > = yield call([DashboardAPI, DashboardAPI.getAndroidSessionDuration], {
      authorization: token,
      start: payload.start,
      end: payload.end,
    })

    yield put(GetAndroidSessionDurationAction.success(response.data))
  } catch (e) {
    Log.ruddy('Error: getActiveIosUsersWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка получения длительности сессий (андроид)'),
      ),
    )

    yield put(GetAndroidSessionDurationAction.failure(e as AxiosError))
  }
}

function* getIosSessionDurationWorker({
  payload,
}: ReturnType<typeof GetIosSessionDurationAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof DashboardAPI.getIosSessionDuration>
    > = yield call([DashboardAPI, DashboardAPI.getIosSessionDuration], {
      authorization: token,
      start: payload.start,
      end: payload.end,
      app: payload.app,
    })

    yield put(GetIosSessionDurationAction.success(response.data))
  } catch (e) {
    Log.ruddy('Error: getActiveIosUsersWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка получения длительности сессий (айос)'),
      ),
    )

    yield put(GetIosSessionDurationAction.failure(e as AxiosError))
  }
}

function* getIosVersionsWorker({
  payload,
}: ReturnType<typeof GetIosVersionsAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof DashboardAPI.getIosVersions>
    > = yield call([DashboardAPI, DashboardAPI.getIosVersions], {
      authorization: token,
      start: payload.start,
      end: payload.end,
    })

    yield put(GetIosVersionsAction.success(response.data))
  } catch (e) {
    Log.ruddy('Error: getActiveIosUsersWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка получения версий приложения'),
      ),
    )

    yield put(GetIosVersionsAction.failure(e as AxiosError))
  }
}

function* getAndroidVersionsWorker({
  payload,
}: ReturnType<typeof GetAndroidVersionsAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof DashboardAPI.getAndroidVersions>
    > = yield call([DashboardAPI, DashboardAPI.getAndroidVersions], {
      authorization: token,
      start: payload.start,
      end: payload.end,
    })

    yield put(GetAndroidVersionsAction.success(response.data))
  } catch (e) {
    Log.ruddy('Error: getActiveIosUsersWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка получения версий приложения'),
      ),
    )

    yield put(GetAndroidVersionsAction.failure(e as AxiosError))
  }
}

function* getUsersByMonthWorker({
  payload,
}: ReturnType<typeof GetUsersByMonthAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof DashboardAPI.getUsersByMonth>
    > = yield call([DashboardAPI, DashboardAPI.getUsersByMonth], {
      authorization: token,
      start: payload.start,
      end: payload.end,
    })

    yield put(GetUsersByMonthAction.success(response.data))
  } catch (e) {
    Log.ruddy('Error: getUsersByMonthWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка получения версий приложения'),
      ),
    )

    yield put(GetUsersByMonthAction.failure(e as AxiosError))
  }
}

function* getUsersByDateWorker({
  payload,
}: ReturnType<typeof GetUsersByDateAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof DashboardAPI.getUsersByDate>
    > = yield call([DashboardAPI, DashboardAPI.getUsersByDate], {
      authorization: token,
      start: payload.start,
      end: payload.end,
    })

    yield put(GetUsersByDateAction.success(response.data))
  } catch (e) {
    Log.ruddy('Error: getUsersByDateWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка получения версий приложения'),
      ),
    )

    yield put(GetUsersByDateAction.failure(e as AxiosError))
  }
}

function* getPetsByMonthWorker({
  payload,
}: ReturnType<typeof GetPetsByMonthAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof DashboardAPI.getPetsByMonth>
    > = yield call([DashboardAPI, DashboardAPI.getPetsByMonth], {
      authorization: token,
      start: payload.start,
      end: payload.end,
    })

    yield put(GetPetsByMonthAction.success(response.data))
  } catch (e) {
    Log.ruddy('Error: getPetsByMonthWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка получения питомцев за год'),
      ),
    )

    yield put(GetPetsByMonthAction.failure(e as AxiosError))
  }
}

function* getAllIosVersionsWorker({
  payload,
}: ReturnType<typeof GetAllIosVersionsAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof DashboardAPI.getAllVersions>
    > = yield call([DashboardAPI, DashboardAPI.getAllVersions], {
      authorization: token,
      start: payload.start,
      end: payload.end,
      app: payload.app,
    })

    yield put(GetAllIosVersionsAction.success(response.data))
  } catch (e) {
    Log.ruddy('Error: getAllIosVersionsWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка получения всех версий (айос)'),
      ),
    )

    yield put(GetAllIosVersionsAction.failure(e as AxiosError))
  }
}

function* getAllAndroidVersionsWorker({
  payload,
}: ReturnType<typeof GetAllAndroidVersionsAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof DashboardAPI.getAllVersions>
    > = yield call([DashboardAPI, DashboardAPI.getAllVersions], {
      authorization: token,
      start: payload.start,
      end: payload.end,
      app: payload.app,
    })

    yield put(GetAllAndroidVersionsAction.success(response.data))
  } catch (e) {
    Log.ruddy('Error: getAllAndroidVersionsWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка получения всех версий (android)'),
      ),
    )

    yield put(GetAllAndroidVersionsAction.failure(e as AxiosError))
  }
}

export function* dashboardWatcher() {
  yield takeLatest(getDashboardInfoAction.request, getDashboardInfoWorker)
  yield takeLatest(getYearInfoAction.request, getYearInfoWorker)
  yield takeLatest(getPrevYearInfoAction.request, getPrevYearInfoWorker)
  yield takeLatest(getActiveIosUsersAction.request, getActiveIosUsersWorker)
  yield takeLatest(
    getActiveAndroidUsersAction.request,
    getActiveAndroidUsersWorker,
  )
  yield takeLatest(
    GetActiveIosUsersMonthAction.request,
    getActiveIosUsersMonthWorker,
  )
  yield takeLatest(
    GetActiveAndroidUsersMonthAction.request,
    getActiveAndroidUsersMonthWorker,
  )
  yield takeLatest(getActiveUsersSummary.request, getActiveUsersSummaryWorker)
  yield takeLatest(GetOsesAction.request, getOSesWorker)
  yield takeLatest(GetSessionCountsAction.request, getSessionCountsWorker)
  yield takeLatest(GetIosModelsAction.request, getIosModelsWorker)
  yield takeLatest(GetAndroidModelsAction.request, getAndroidModelsWorker)
  yield takeLatest(
    GetAndroidSessionDurationAction.request,
    getAndroidSessionDurationWorker,
  )
  yield takeLatest(
    GetIosSessionDurationAction.request,
    getIosSessionDurationWorker,
  )
  yield takeLatest(GetIosVersionsAction.request, getIosVersionsWorker)
  yield takeLatest(GetAndroidVersionsAction.request, getAndroidVersionsWorker)
  yield takeLatest(GetUsersByMonthAction.request, getUsersByMonthWorker)
  yield takeLatest(GetUsersByDateAction.request, getUsersByDateWorker)
  yield takeLatest(GetPetsByMonthAction.request, getPetsByMonthWorker)
  yield takeLatest(GetAllIosVersionsAction.request, getAllIosVersionsWorker)
  yield takeLatest(
    GetAllAndroidVersionsAction.request,
    getAllAndroidVersionsWorker,
  )
}
