import { createAction } from "@reduxjs/toolkit";

import {
	GET_SUPPORTS,
	SET_SUPPORTS,
	SET_SUPPORT,
	GET_SUPPORT,
	SET_SUPPORTS_STATE,
	DELETE_SUPPORT,
	GET_REVIEWS,
	SET_REVIEWS,
	GET_REVIEW,
	SET_REVIEW,
} from "./consts";

export const supportActions = {
	getSupports: createAction(GET_SUPPORTS, (payload) => ({ payload })),
	setSupports: createAction(SET_SUPPORTS, (payload) => ({payload})),
	setSupport: createAction(SET_SUPPORT, (payload) => ({payload})),
	getSupport: createAction(GET_SUPPORT, (payload) => ({payload})),
	getReviews: createAction(GET_REVIEWS, (payload) => ({payload})),
	setReviews: createAction(SET_REVIEWS, (payload) => ({payload})),
	getReview: createAction(GET_REVIEW, (payload) => ({payload})),
	setReview: createAction(SET_REVIEW, (payload) => ({payload})),
	deleteSupport: createAction(DELETE_SUPPORT, (payload) => ({payload})),
	setSupporsState: createAction(SET_SUPPORTS_STATE, (payload) => ({payload})),
}