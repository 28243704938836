// export const protocolWS: string = 'ws://'
// export const protocolHTTP: string = 'https://'

// export const host: string = 'petshealth-dab2c.web.app'

// export const portAPI: string = '8085'
// export const portWS: string = '8086'

// export const api = {
//   ws: `${protocolWS}${host}:${portWS}`,
//   withAPI: `${protocolHTTP}${host}`,
//   withoutAPI: `${protocolHTTP}${host}`,
//   withImageAPI: `${protocolHTTP}${host}`,
// }

export const protocolWS: string = 'ws://'
export const protocolHTTPS: string = 'https://' // http:// https://
export const protocolImageHTTPS: string = 'https://'

// export const host: string = 'production.api.petshealth.app' //  144.76.103.235:5800 api.petshealth.app production.api.petshealth.app
export const hostImage: string = 'production.api.petshealth.app' // 144.76.103.235:5800 petshealth-13659.web.app
export const hostImageDev: string = 'dev-petshealth.api.kitg.com.ua/v2'
export const host: string = 'https://production.api.petshealth.app/v2'
// export const host: string = 'preproduction-petshealth.kitg.com.ua' //  144.76.103.235:5800 api.petshealth.app production.api.petshealth.app
// export const hostImage: string = 'production.api.petshealth.app' // 144.76.103.235:5800 petshealth-13659.web.app

export const hostDev: string = 'https://dev-petshealth.api.kitg.com.ua/v2'

export const portAPI: string = '8085'
export const portWS: string = '8086'

export const api = {
  ws: `${protocolWS}${hostDev}:${portWS}`,
  withAPI: `${hostDev}`,
  withoutAPI: `${protocolHTTPS}${hostDev}`,
  withImageAPI: `${protocolImageHTTPS}${hostImageDev}`,
}
