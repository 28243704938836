import React, { FC } from 'react'
import { StyledButton, Label } from './styled'
import { TButtonProps } from './types'

export const Button: FC<TButtonProps> = ({ onClick, title, type }) => {
  return (
    <StyledButton type={type} onClick={onClick}>
      <Label>{title}</Label>
    </StyledButton>
  )
}
