import React from "react";

import { BlurContainer, Container, ModalContainer } from "./styled";
import { TModal } from "./typed";

export const Modal = ({
  children,
  background = "white",
  padding = "18px",
}: TModal) => {
  return (
    <Container>
      <ModalContainer padding={padding} background={background}>
        {children}
      </ModalContainer>
      <BlurContainer />
    </Container>
  );
};
