import React, { FC } from 'react'
import { Divider } from '../Divider'
import { Label, StyledInputWrapper, Error } from './styled'
import { InputTemplates } from './templates'
import { TInputProps } from './types'

export const Input: FC<TInputProps> = ({
  label,
  error,
  template,
  isValid,
  ...props
}) => {
  return (
    <StyledInputWrapper>
      {label && <Label>{label}</Label>}

      <Divider height={10} />

      {InputTemplates[template](props)}

      <Divider height={5} />

      <Error>{!isValid ? error : ''}</Error>
    </StyledInputWrapper>
  )
}
