import { api } from '../config'

export const CUSTOMER_URL = {
  get_customers: (
    page: number,
    limit: number,
    search: string,
    sortBy: string,
  ) =>
    `${api.withAPI}/admin/user/?page=${page}&limit=${limit}&search=${search}&sortBy=${sortBy}`,
  update_customer: `${api.withAPI}/admin/user/`,
  delete_customer: `${api.withAPI}/admin/user/`,
  getCustomerById: (id: string) => `${api.withAPI}/admin/user/${id}`,
  getCustomerEvents: (id: string) => `${api.withAPI}/admin/event/by-user/${id}`,
}
