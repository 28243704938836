import { AxiosResponse } from 'axios'
// import {} from './types'
import { setAuthorizationHeadersProp } from '../../helpers'
import { PayloadAuth } from '../types'
import { HttpService } from '../../services'
import { INFO_SECTION_URL } from './config'
import {
  GetInfoSectionsActionResponse,
  UpdateInfoSectionActionPayload,
} from './types'

export class InfoSectionAPI extends HttpService {
  static getInfoSections(
    request: PayloadAuth,
  ): Promise<AxiosResponse<GetInfoSectionsActionResponse>> {
    return this.request<GetInfoSectionsActionResponse>('GET_INFO_SECTIONS', {
      url: INFO_SECTION_URL.get_info_sections,
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static updateInfoSection(
    request: UpdateInfoSectionActionPayload,
  ): Promise<AxiosResponse<void>> {
    return this.request('UPDATE_INFO_SECTIONS', {
      url: INFO_SECTION_URL.updateInfoSection(request.id),
      method: 'PUT',
      data: request.data,
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }
}
