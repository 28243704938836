import { api } from '../config'

export const CATEGORIES_ADS_URL = {
  getCategoriesAds: (page: number, limit: number) =>
    `${api.withAPI}/admin/ads-categories/?page=${page}&limit=${limit}`,
  createCategoriesAds: `${api.withAPI}/admin/ads-categories`,
  updateCategoriesAds: (id: string) => `${api.withAPI}/admin/ads-categories/${id}`,
  deleteCategoriesAds: (id: string) => `${api.withAPI}/admin/ads-categories/${id}`,
  getSubCategoriesAds: (page: number, limit: number, category: string,) =>
    `${api.withAPI}/admin/ads-subcategories/?page=${page}&limit=${limit}${category ? `&category=${category}` : ''}`,
  createSubCategoriesAds: `${api.withAPI}/admin/ads-subcategories`,
  updateSubCategoriesAds: (id: string) => `${api.withAPI}/admin/ads-subcategories/${id}`,
  deleteSubCategoriesAds: (id: string) => `${api.withAPI}/admin/ads-subcategories/${id}`,
}
