import { createActionTypes, createApiActions } from '../actions-util'
import { AxiosError } from 'axios'

import {
  GetCategoriesClinicsActionResponse,
  GetCategoriesClinicsPayload,
  UpdateCategoriesClinicsActionPayload,
  CreateCategoriesClinicsActionPayload,
  DeleteCategoriesClinicsActionPayload,
} from './types'

const CLINICS_CATEGORY = 'CLINICS_CATEGORY'

export const getCategoriesClinicsCategoryAction = createApiActions<
  GetCategoriesClinicsPayload,
  GetCategoriesClinicsActionResponse,
  AxiosError
>(createActionTypes(`${CLINICS_CATEGORY}/GET_CATEGORIES_CLINICS_CATEGORY`))

export const createCategoriesClinicsCategoryAction = createApiActions<
  CreateCategoriesClinicsActionPayload,
  GetCategoriesClinicsActionResponse,
  AxiosError
>(createActionTypes(`${CLINICS_CATEGORY}/CREATE_CATEGORIES_CLINICS_CATEGORY`))

export const updateCategoriesClinicsCategoryAction = createApiActions<
  UpdateCategoriesClinicsActionPayload,
  GetCategoriesClinicsActionResponse,
  AxiosError
>(createActionTypes(`${CLINICS_CATEGORY}/UPDATE_CATEGORIES_CLINICS_CATEGORY`))

export const deleteCategoriesClinicsCategoryAction = createApiActions<
  DeleteCategoriesClinicsActionPayload,
  GetCategoriesClinicsActionResponse,
  AxiosError
>(createActionTypes(`${CLINICS_CATEGORY}/DELETE_CATEGORIES_CLINICS_CATEGORY`))
