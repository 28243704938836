export const ServicesArray = [
  {
    services: [],
    name: { ru: 'хирургия', uk: 'хірургія', en: 'surgery' },
    type: 9,
  },
  {
    services: [],
    name: {
      ru: 'лабораторная диагностика',
      uk: 'лабораторна діагностика',
      en: 'laboratory diagnostics',
    },
    type: 10,
  },
  {
    services: [],
    name: { ru: 'диагностика', uk: 'діагностика', en: 'diagnostics' },
    type: 11,
  },
  {
    services: [],
    name: { ru: 'офтальмология', uk: 'офтальмологія', en: 'ophthalmology' },
    type: 12,
  },
  {
    services: [],
    name: { ru: 'манипуляции', uk: 'маніпуляції', en: 'manipulation' },
    type: 13,
  },
  {
    services: [],
    name: { ru: 'вакцинация', uk: 'вакцинація', en: 'vaccination' },
    type: 14,
  },
  {
    services: [],
    name: { ru: 'стоматология', uk: 'стоматологія', en: 'dentistry' },
    type: 15,
  },
  {
    services: [],
    name: {
      ru: 'акушерство и гиникология',
      uk: 'акушерство та гінекологія',
      en: 'obstetrics and gynecology',
    },
    type: 16,
  },
  {
    services: [],
    name: { ru: 'груминг', uk: 'грумінг', en: 'grooming' },
    type: 17,
  },
  { services: [], name: { ru: 'другие', uk: 'інші', en: 'other' }, type: 18 },
  {
    services: [],
    name: { ru: 'консультации', uk: 'консультації', en: 'consultations' },
    type: 19,
  },
  {
    services: [],
    name: {
      ru: 'ветеринарная помощь',
      uk: 'ветеринарна допомога',
      en: 'veterinary care',
    },
    type: 20,
  },
  {
    services: [],
    name: { ru: 'zoo-отель', uk: 'zoo-готель', en: 'zoo-hotel' },
    type: 21,
  },
  {
    services: [],
    name: {
      ru: 'дрессировка и воспитание',
      uk: 'дресирування та виховання',
      en: 'training and education',
    },
    type: 22,
  },
  {
    services: [],
    name: { ru: 'питомник', uk: 'розплідник', en: 'nursery' },
    type: 23,
  },
  {
    services: [],
    name: { ru: 'приют', uk: 'притулок', en: 'shelter' },
    type: 24,
  },
  {
    services: [],
    name: { ru: 'терапия', uk: 'терапія', en: 'therapy' },
    type: 25,
  },
  {
    services: [],
    name: {
      ru: 'репродуктология',
      uk: 'репродуктологія',
      en: 'reproductology',
    },
    type: 26,
  },
  {
    services: [],
    name: {
      ru: 'разведение кошек',
      uk: 'розведення кішок',
      en: 'cat breeding',
    },
    type: 27,
  },
  {
    services: [],
    name: {
      ru: 'разведение собак',
      uk: 'розведення собак',
      en: 'dog breeding',
    },
    type: 28,
  },
]

export const languages = ['ru', 'uk', 'en', 'pl', 'cs', 'de', 'es', 'fr', 'it', 'ch', 'hi', 'ja', 'ko', 'tr', 'gr', 'kt', 'sv', 'sk', 'th', 'hr', 'fi', 'sl']

export const CategoryObject = {
  petStores: {
    ru: 'Зоомагазины',
    uk: 'Зоомагазини',
    en: 'Pet stores',
    type: 29,
  },
  zoopharmacies: {
    ru: 'Зооаптеки',
    uk: 'Зооаптеки',
    en: 'Zoopharmacies',
    type: 30,
  },
  placesForWalking: {
    ru: 'Места для прогулок',
    uk: 'Місця для прогулянок',
    en: 'Places for walking',
    type: 31,
  },
  petFriendlyPlaces: {
    ru: 'Пет френдли места',
    uk: 'Пет френдлі місця',
    en: 'Pet friendly places',
    type: 32,
  },
}

enum clinicSpezializationType {
  'Зоомагазины' = 29,
  'Зооаптеки' = 30,
  'Места для прогулок' = 31,
  'Пет френдли места' = 32,
}

enum clinicType {
  'хирургия' = 9,
  'лабораторная диагностика' = 10,
  'диагностика' = 11,
  'офтальмология' = 12,
  'манипуляции' = 13,
  'вакцинация' = 14,
  'стоматология' = 15,
  'акушерство и гинекология' = 16,
  'груминг' = 17,
  'другие' = 18,
  'консультации' = 19,
  'ветеринарная помощь' = 20,
  'zoo-отель' = 21,
  'дрессировка и воспитание' = 22,
  'питомник' = 23,
  'приют' = 24,
  'терапия' = 25,
  'репродуктология' = 26,
  'разведение кошек' = 27,
  'разведение собак' = 28,
}
