import { takeLatest, select, call, put, take } from '@redux-saga/core/effects'
import {
  deleteCustomerAction,
  getCustomerByIdAction,
  getCustomerEventsAction,
  getCustomersAction,
  updateCustomerAction,
} from './actions'
import { AuthorizationError } from '../../errors'
import { PromiseReturnType } from '../types'
import { CustomerAPI } from './api.service'
import { showToastAction } from '../toasts'
import { generateErrorToast, generateSuccessToast } from '../../helpers'
import { Log } from '../../utils'
import { getUserSelector } from '../user'
import { AxiosError } from 'axios'

function* verifyTokenWorker() {
  const { token }: ReturnType<typeof getUserSelector> = yield select(
    getUserSelector,
  )

  if (token) return token

  throw new AuthorizationError('verifyTokenWorker')
}

function* getCustomerWorker({
  payload,
}: ReturnType<typeof getCustomersAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof CustomerAPI.getCustomers>
    > = yield call([CustomerAPI, CustomerAPI.getCustomers], {
      authorization: token,
      ...payload,
    })

    yield put(
      getCustomersAction.success({
        customers: response.data.customers,
        total: response.data.total,
      }),
    )
  } catch (e) {
    Log.ruddy('Error: getCustomerWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения клиентов')),
    )

    yield put(getCustomersAction.failure(e as AxiosError))
  }
}

function* updateCustomerWorker({
  payload,
}: ReturnType<typeof updateCustomerAction>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof CustomerAPI.updateCustomer>
    > = yield call([CustomerAPI, CustomerAPI.updateCustomer], {
      name: payload.name,
      phone: payload.phone,
      email: payload.email,
      authorization: token,
      _id: payload._id,
    })

    yield put(
      showToastAction.request(
        generateSuccessToast('Пользователь обновлен успешно'),
      ),
    )
  } catch (e) {
    Log.ruddy('Error: updateCustomerWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка обновления пользователя'),
      ),
    )
  }
}

function* getCustomerByIdWorker({ payload }: { payload: { id: string } }) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const res: PromiseReturnType<
      ReturnType<typeof CustomerAPI.getCustomersById>
    > = yield call([CustomerAPI, CustomerAPI.getCustomersById], {
      authorization: token,
      id: payload.id,
    })

    yield put(getCustomerByIdAction.success({ data: res.data, status: 'ok' }))
  } catch (e) {
    Log.ruddy('Error: getCustomerByIdWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Error getting customer')),
    )

    yield put(getCustomerByIdAction.failure(e as AxiosError))
  }
}

function* getCustomerEventsWorker({ payload }: { payload: { id: string } }) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const res: PromiseReturnType<
      ReturnType<typeof CustomerAPI.getCustomerEvents>
    > = yield call([CustomerAPI, CustomerAPI.getCustomerEvents], {
      authorization: token,
      id: payload.id,
    })

    yield put(getCustomerEventsAction.success({ data: res.data.event }))
  } catch (e) {
    Log.ruddy('Error: getCustomerEventsWorker', e)

    yield put(getCustomerEventsAction.failure(e as AxiosError))
  }
}

function* deleteCustomerWorker({
  payload,
}: ReturnType<typeof deleteCustomerAction>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof CustomerAPI.deleteCustomer>
    > = yield call([CustomerAPI, CustomerAPI.deleteCustomer], {
      _id: payload._id,
      authorization: token,
    })

    if (response) {
      yield put(
        getCustomersAction.request({
          page: 1,
          limit: 20,
          search: '',
          sortBy: '',
        }),
      )

      yield take(getCustomersAction.success)

      yield put(
        showToastAction.request(
          generateSuccessToast('Пользователь удален успешно'),
        ),
      )
    }
  } catch (e) {
    Log.ruddy('Error: deleteCustomerWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка удаления пользователя'),
      ),
    )
  }
}

export function* customerWatcher() {
  yield takeLatest(getCustomersAction.request, getCustomerWorker)
  yield takeLatest(getCustomerByIdAction.request, getCustomerByIdWorker)
  yield takeLatest(getCustomerEventsAction.request, getCustomerEventsWorker)
  yield takeLatest(updateCustomerAction, updateCustomerWorker)
  yield takeLatest(deleteCustomerAction, deleteCustomerWorker)
}
