import { takeLatest, select, call, put } from '@redux-saga/core/effects'

import { AuthorizationError } from '../../errors'
import { PromiseReturnType } from '../types'
import { AdsAPI } from './api.service'
import { showToastAction } from '../toasts'
import { generateErrorToast, generateSuccessToast } from '../../helpers'
import { Log } from '../../utils'
import { getUserSelector } from '../user'
import { AxiosError } from 'axios'
import { createAdsAction, deleteAdsAction, deleteComplaintsAdsAction, getAdsAction, getAdsActionComplaints, getAdsByIdAction, updateAdsAction } from './actions'

function* verifyTokenWorker() {
  const { token }: ReturnType<typeof getUserSelector> = yield select(
    getUserSelector,
  )

  if (token) return token

  throw new AuthorizationError('verifyTokenWorker')
}

function* getAdsWorker({
  payload,
}: ReturnType<typeof getAdsAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<ReturnType<typeof AdsAPI.getAds>> =
      yield call([AdsAPI, AdsAPI.getAds], {
        authorization: token,
        ...payload,
      })

    yield put(
      getAdsAction.success({
        ads: response.data.models,
        totalCount: response.data.totalCount,
        response: 'GET_ADS'
      }),
    )
  } catch (e) {
    Log.ruddy('Error: getClinicsWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка получения Объявлений'),
      ),
    )

    yield put(getAdsAction.failure(e as AxiosError))
  }
}

function* getAdsComplaintsWorker({
  payload,
}: ReturnType<typeof getAdsActionComplaints['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<ReturnType<typeof AdsAPI.getComplainsAds>> =
      yield call([AdsAPI, AdsAPI.getComplainsAds], {
        authorization: token,
        ...payload,
      })

    yield put(
      getAdsActionComplaints.success({
        complaints: response.data.complaint,
        totalCountComplaints: response.data.total,
      }),
    )
  } catch (e) {
    Log.ruddy('Error: getClinicsWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка получения жалоб'),
      ),
    )

    yield put(getAdsActionComplaints.failure(e as AxiosError))
  }
}

function* createAdsWorker({
  payload,
}: ReturnType<typeof createAdsAction['request']>) {

  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

      yield call([AdsAPI, AdsAPI.createAds], {
        authorization: token,
        data: payload.data,
      })

    yield put(
      showToastAction.request(
        generateSuccessToast('Объявление успешно создано'),
      ),
    )
  } catch (e) {

    yield put(
      showToastAction.request(generateErrorToast('Ошибка создания объявления')),
    )

    yield put(createAdsAction.failure(e as AxiosError))
  }finally{
    yield put(createAdsAction.success({
      response: 'CREATE_ADS'
    }))
  }
}

function* updateAdsWorker({
  payload,
}: ReturnType<typeof updateAdsAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    yield call([AdsAPI, AdsAPI.updateAds], {
      authorization: token,
      data: payload.data,
      id: payload.id,
    })

    yield put(
      showToastAction.request(
        generateSuccessToast('Объявление успешно обновлено'),
      ),
    )
  } catch (e) {

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка обновления объявления'),
      ),
    )

    yield put(updateAdsAction.failure(e as AxiosError))
  }finally{
    yield put(createAdsAction.success({
      response: 'UPDATE_ADS'
    }))
  }
}

function* deleteAdsWorker({
  payload,
}: ReturnType<typeof deleteAdsAction>) {
  try {
    const token: string = yield call(verifyTokenWorker)

  yield call([AdsAPI, AdsAPI.deleteAds], {
      id: payload.id,
      authorization: token,
    })

    yield put(
      showToastAction.request(generateSuccessToast('Объявление удалено успешно')),
    )
  } catch (e) {

    yield put(
      showToastAction.request(generateErrorToast('Ошибка удаления объявления')),
    )
  }
}

function* getAdsByIdWorker({ payload }: { payload: { id: string } }) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const res: PromiseReturnType<ReturnType<typeof AdsAPI.getAdsById>> =
      yield call([AdsAPI, AdsAPI.getAdsById], {
        authorization: token,
        id: payload.id,
      })

    yield put(getAdsByIdAction.success(res.data))
  } catch (e) {
    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения объявления')),
    )

    yield put(getAdsByIdAction.failure(e as AxiosError))
  }
}

function* deleteComplaintsWorker({
  payload,
}: ReturnType<typeof deleteComplaintsAdsAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

  yield call([AdsAPI, AdsAPI.deleteComplaintAds], {
      id: payload.id,
      authorization: token,
    })

    yield put(
      showToastAction.request(generateSuccessToast('Жалоба удалена успешно')),
    )
  } catch (e) {

    yield put(
      showToastAction.request(generateErrorToast('Ошибка удалении жалобы')),
    )
  }
}

export function* adsWatcher() {
  yield takeLatest(getAdsAction.request, getAdsWorker);
  yield takeLatest(getAdsByIdAction.request, getAdsByIdWorker);
  yield takeLatest(createAdsAction.request, createAdsWorker)
  yield takeLatest(updateAdsAction.request, updateAdsWorker)
  yield takeLatest(getAdsActionComplaints.request, getAdsComplaintsWorker)
  yield takeLatest(deleteAdsAction, deleteAdsWorker)
  yield takeLatest(deleteComplaintsAdsAction.request, deleteComplaintsWorker)
}
