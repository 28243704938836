import React, { useEffect, useState } from 'react'
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
} from '@react-google-maps/api'
import { Coord } from '../types'
import { containerStyle } from '../styled'
import Geocode from 'react-geocode'
import { lib } from '../../../consts'

// eslint-disable-next-line react/prop-types
const Map: React.FC<{ coordinates: { lat: number; lng: number } }> = ({
  coordinates,
}) => {
  const [center, setCenter] = useState<Coord>({
    lat: 50,
    lng: 30,
  })

  Geocode.setLanguage('ru')
  Geocode.setApiKey('AIzaSyDiVh218waTaUJqMExezt3uALFphAmjwsM')

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyDiVh218waTaUJqMExezt3uALFphAmjwsM',
    libraries: lib as any,
  })

  useEffect(() => {
    setCenter(coordinates)
  }, [coordinates])

  return isLoaded ? (
    <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={14}>
      <Marker position={center} />
    </GoogleMap>
  ) : (
    <></>
  )
}

export default React.memo(Map)
