import { AxiosResponse } from 'axios'

import { setAuthorizationHeadersProp } from '../../helpers'
import { PayloadAuth } from '../types'
import { HttpService } from '../../services'

import {
  GetCategoriesClinicsActionResponse,
  GetCategoriesClinicsPayload,
  DeleteCategoriesClinicsActionPayload
} from './types'
import { CLINICS_CATEGORY_URL } from './config'

export class ClinicsCategoryAPI extends HttpService {
  static getClinicsCategory(
    request: GetCategoriesClinicsPayload & PayloadAuth,
  ): Promise<AxiosResponse<GetCategoriesClinicsActionResponse>> {
    return this.request<GetCategoriesClinicsActionResponse>('GET_CLINICS_CATEGORY', {
      url: CLINICS_CATEGORY_URL.getClinicsCategory(
        request.page,
        request.limit,
      ),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static createClinicsCategory({
    authorization,
    data,
  }: PayloadAuth & any): Promise<AxiosResponse<GetCategoriesClinicsActionResponse>> {
    return this.request<GetCategoriesClinicsActionResponse>('CREATE_CLINICS_CATEGORY', {
      url: CLINICS_CATEGORY_URL.createClinicsCategory,
      method: 'POST',
      data,
      headers: {
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static updateClinicsCategory({
    authorization,
    data,
    id,
  }: PayloadAuth & any): Promise<AxiosResponse<GetCategoriesClinicsActionResponse>> {
    return this.request<GetCategoriesClinicsActionResponse>('UPDATE_CLINICS_CATEGORY', {
      url: CLINICS_CATEGORY_URL.updateClinicsCategory(id),
      method: 'PATCH',
      data,
      headers: {
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static deleteClinicsCategory(
    request: DeleteCategoriesClinicsActionPayload,
  ): Promise<AxiosResponse<void>> {
    return this.request('DELETE_CLINICS_CATEGORY', {
      url: CLINICS_CATEGORY_URL.deleteClinicsCategory(request.id),
      method: 'DELETE',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }
}