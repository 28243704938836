import React, { FC, useState } from 'react'
import {
  AddButton,
  ButtonField,
  StyledAccordion,
  StyledDeleteButton,
  StyledEntity,
  StyledPhoneNumber,
} from '../styled'
import { Accordion } from 'react-bootstrap'
import { Divider, EInputTemplate, Input } from '../../../components'
import { PhonesProps } from '../types'

export const PhoneNumbers: FC<PhonesProps> = ({ form, setForm }) => {
  const [addNumberIndicator, setAddNumberIndicator] = useState<boolean>(false)
  const [temporaryNumber, setTemporaryNumber] = useState<string>('')

  const addIndicatorHandler = () => {
    setAddNumberIndicator(prev => !prev)
  }

  const temporaryNumberHandler = (e: any) => {
    setTemporaryNumber(e.target.value)
  }

  const addNumberToStack = () => {
    if (temporaryNumber !== '' && form.phones.indexOf(temporaryNumber) === -1)
      setForm(prev => ({ ...prev, phones: [...prev.phones, temporaryNumber] }))
  }

  const deleteNumber = (number: string) => {
    setForm(prev => ({
      ...prev,
      phones: prev.phones.filter(el => el !== number),
    }))
  }

  return (
    <StyledAccordion>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="1">
          <Accordion.Header>Контактные номера</Accordion.Header>
          <Accordion.Body>
            {form.phones.length !== 0 ? (
              <div>
                {form.phones.map(el => (
                  <StyledPhoneNumber key={el}>
                    <div>{el}</div>
                    <Divider width={10} />
                    <StyledDeleteButton onClick={() => deleteNumber(el)}>
                      Удалить
                    </StyledDeleteButton>
                  </StyledPhoneNumber>
                ))}
                <Divider height={10} />

                {addNumberIndicator ? (
                  <>
                    <StyledEntity>
                      <Input
                        label="Номер"
                        name="number"
                        value={temporaryNumber ?? ''}
                        placeholder="Введите номер"
                        template={EInputTemplate.NAME}
                        onChange={v => temporaryNumberHandler(v)}
                      />
                    </StyledEntity>
                    <ButtonField>
                      <AddButton onClick={addNumberToStack}>Добавить</AddButton>

                      <Divider width={10} />

                      <StyledDeleteButton onClick={addIndicatorHandler}>
                        Отмена
                      </StyledDeleteButton>
                    </ButtonField>
                  </>
                ) : (
                  <AddButton onClick={addIndicatorHandler}>
                    Добавить номер
                  </AddButton>
                )}
              </div>
            ) : addNumberIndicator ? (
              <>
                <StyledEntity>
                  <Input
                    label="Номер"
                    name="number"
                    value={temporaryNumber ?? ''}
                    placeholder="Введите номер"
                    template={EInputTemplate.NAME}
                    onChange={v => temporaryNumberHandler(v)}
                  />
                </StyledEntity>
                <ButtonField>
                  <AddButton onClick={addNumberToStack}>Добавить</AddButton>

                  <Divider width={10} />

                  <StyledDeleteButton onClick={addIndicatorHandler}>
                    Отмена
                  </StyledDeleteButton>
                </ButtonField>
              </>
            ) : (
              <AddButton onClick={addIndicatorHandler}>
                Добавить номер
              </AddButton>
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </StyledAccordion>
  )
}
