import { createActionTypes, createApiActions } from '../actions-util'
import { AxiosError } from 'axios'

import {
  GetActiveAndroidUsersPayload,
  GetActiveAndroidUsersResponse,
  GetActiveIosUsersPayload,
  GetActiveIosUsersResponse,
  GetActiveUsersSummaryPayload,
  GetActiveUsersSummaryResponse,
  GetDashboardInfoActionPayload,
  GetDashboardInfoActionResponse,
  GetIosModelsPayload,
  GetIosModelsResponse,
  GetOsesPayload,
  GetOSesResponse,
  GetSessionCountsPayload,
  GetSessionCountsResponse,
  GetYearInfoActionPayload,
  GetYearInfoActionResponse,
  GetAndroidModelsPayload,
  GetAndroidModelsResponse,
  GetIosSessionDurationPayload,
  GetIosSessionDurationResponse,
  GetAndroidSessionDurationPayload,
  GetAndroidSessionDurationResponse,
  GetVersionsPayload,
  GetVersionsResponse,
  GetUsersByMonthResponse,
  GetUsersByDateResponse,
  GetPetsByMonthResponse,
  VersionsResponse,
} from './types'
import { createAction } from '@reduxjs/toolkit'

export const getDashboardInfoAction = createApiActions<
  GetDashboardInfoActionPayload,
  GetDashboardInfoActionResponse,
  AxiosError
>(createActionTypes('DASHBOARD/GET_STATISTICS'))

export const getYearInfoAction = createApiActions<
  GetYearInfoActionPayload,
  GetYearInfoActionResponse,
  AxiosError
>(createActionTypes('DASHBOARD/GET_YEAR_INFO'))

export const getPrevYearInfoAction = createApiActions<
  GetYearInfoActionPayload,
  GetYearInfoActionResponse,
  AxiosError
>(createActionTypes('DASHBOARD/GET_PREV_YEAR_INFO'))

export const getActiveIosUsersAction = createApiActions<
  GetActiveIosUsersPayload,
  GetActiveIosUsersResponse[],
  AxiosError
>(createActionTypes('DASHBOARD/GET_ACTIVE_IOS_USERS'))

export const getActiveAndroidUsersAction = createApiActions<
  GetActiveAndroidUsersPayload,
  GetActiveAndroidUsersResponse[],
  AxiosError
>(createActionTypes('DASHBOARD/GET_ACTIVE_ANDROID_USERS'))

export const GetActiveIosUsersMonthAction = createApiActions<
  GetActiveIosUsersPayload,
  GetActiveIosUsersResponse[],
  AxiosError
>(createActionTypes('DASHBOARD/GET_ACTIVE_IOS_USERS_MONTH'))

export const GetActiveAndroidUsersMonthAction = createApiActions<
  GetActiveAndroidUsersPayload,
  GetActiveAndroidUsersResponse[],
  AxiosError
>(createActionTypes('DASHBOARD/GET_ACTIVE_ANDROID_USERS_MONTH'))

export const getActiveUsersSummary = createApiActions<
  GetActiveUsersSummaryPayload,
  GetActiveUsersSummaryResponse[],
  AxiosError
>(createActionTypes('DASHBOARD/GET_ACTIVE_USERS_SUMMARY'))

export const GetOsesAction = createApiActions<
  GetOsesPayload,
  GetOSesResponse[],
  AxiosError
>(createActionTypes('DASHBOARD/GET_OSES'))

export const GetSessionCountsAction = createApiActions<
  GetSessionCountsPayload,
  GetSessionCountsResponse[],
  AxiosError
>(createActionTypes('DASHBOARD/GET_SESSION_COUNTS'))

export const GetIosModelsAction = createApiActions<
  GetIosModelsPayload,
  GetIosModelsResponse[],
  AxiosError
>(createActionTypes('DASHBOARD/GET_IOS_MODELS'))

export const GetAndroidModelsAction = createApiActions<
  GetAndroidModelsPayload,
  GetAndroidModelsResponse[],
  AxiosError
>(createActionTypes('DASHBOARD/GET_ANDROID_MODELS'))

export const GetIosSessionDurationAction = createApiActions<
  GetIosSessionDurationPayload,
  GetIosSessionDurationResponse[],
  AxiosError
>(createActionTypes('DASHBOARD/GET_IOS_SESSION_DURATION'))

export const GetAndroidSessionDurationAction = createApiActions<
  GetAndroidSessionDurationPayload,
  GetAndroidSessionDurationResponse[],
  AxiosError
>(createActionTypes('DASHBOARD/GET_ANDROID_SESSION_DURATION'))

export const GetIosVersionsAction = createApiActions<
  GetVersionsPayload,
  GetVersionsResponse[],
  AxiosError
>(createActionTypes('DASHBOARD/GET_IOS_VERSIONS'))

export const GetAndroidVersionsAction = createApiActions<
  GetVersionsPayload,
  GetVersionsResponse[],
  AxiosError
>(createActionTypes('DASHBOARD/GET_ANDROID_VERSIONS'))

export const SetPlatformFilterexport = createAction<
  string,
  'DASHBOARD/SET_PLATFORM_FILTER'
>('DASHBOARD/SET_PLATFORM_FILTER')

export const GetUsersByMonthAction = createApiActions<
  GetVersionsPayload,
  GetUsersByMonthResponse[],
  AxiosError
>(createActionTypes('DASHBOARD/GET_USERS_BY_MONTH'))

export const GetPetsByMonthAction = createApiActions<
  GetVersionsPayload,
  GetPetsByMonthResponse[],
  AxiosError
>(createActionTypes('DASHBOARD/GET_PETS_BY_MONTH'))

export const GetUsersByDateAction = createApiActions<
  GetVersionsPayload,
  GetUsersByDateResponse[],
  AxiosError
>(createActionTypes('DASHBOARD/GET_USERS_BY_DATE'))

export const GetAllIosVersionsAction = createApiActions<
  GetVersionsPayload,
  VersionsResponse[],
  AxiosError
>(createActionTypes('DASHBOARD/GET_ALL_IOS_VERSIONS'))

export const GetAllAndroidVersionsAction = createApiActions<
  GetVersionsPayload,
  VersionsResponse[],
  AxiosError
>(createActionTypes('DASHBOARD/GET_ALL_ADNROID_VERSIONS'))
