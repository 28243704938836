import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { COLORS } from '../../assets'
import { FLEX, FONT } from '../../utils'

import { TItemBlock } from './types'

export const Main = styled.div`
  padding: 42px 30px;
`

export const StyledLink = styled(Link)`
  padding: 10px;
  border: 1px solid;
  text-decoration: none;
  border-radius: 10px;

  transition: 0.15s ease-in-out;
  cursor: pointer;

  &:hover {
    background: ${COLORS.gray};
  }

  ${FONT({ weight: '700', size: '16px', color: COLORS.lightBlue })}

  ${FLEX({})}
`

export const DeleteButton = styled.button`
  padding: 13px 10px;
  border: 1px solid red;

  background-color: white;

  border-radius: 10px;

  &:hover {
    background: ${COLORS.gray};
  }

  transition: 0.15s ease-in-out;
  cursor: pointer;

  ${FONT({ weight: '700', size: '16px', color: COLORS.red })};

  ${FLEX({})};
`;

export const ItemBlock = styled.div<TItemBlock>`
  max-width: ${({ width }) => width};
  width: 100%;
  margin: 0 4px;

  word-break: break-all;

  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const SupportWrapper = styled.div`
  width: 100%;
  margin-top: 30px;
  padding: 20px 20px;
  border-radius: 12px;
  background-color: white;

  @media (max-width: 500px) {
    margin-top: 10px;
  }
`;

export const HeaderSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 20px 0;

  border-bottom: 1px solid gray;
`;

export const MainBlock = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  background-color: transparent;

  padding: 20px 0;

  width: 100%;

  cursor: pointer;

  border-bottom: 1px solid gray;
`;

export const StyledButton = styled.div`
  padding: 10px 10px 10px 10px;
  border: 1px solid;
  text-decoration: none;
  border-radius: 10px;

  width: 30%;

  transition: 0.15s ease-in-out;
  cursor: pointer;

  &:hover {
    background: ${COLORS.aqua};
  }

  ${FONT({ weight: '700', size: '16px', color: COLORS.lightBlue })}

  ${FLEX({})}
`

export const StyledButtonEnd = styled.div`
  padding: 10px 10px 10px 10px;
  border: 1px solid;
  text-decoration: none;
  border-radius: 10px;
  border-color: ${COLORS.lightGray};

  width: 30%;

  transition: 0.15s ease-in-out;

  ${FONT({ weight: '700', size: '16px', color: COLORS.lightGray })}

  ${FLEX({})}
`

export const StyledPagination = styled.div`
  margin-bottom: 10px;
  ${FLEX({ justify: 'space-between' })}
`

export const FullWidth = styled.div`
  width: 100%;

  margin-top: 40px;
`;