import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import moment from 'moment'

import { Title, Text, Divider } from '../../components'
import { getSupport, supportActions } from '../../store/support'
import { useTypedSelector } from '../../hooks'

import {
  Main,
  SupportWrapper,
  HeaderSection,
  MainBlock,
  ItemBlock,
  StyledLink,
  DeleteButton,
  StyledButton,
  StyledButtonEnd,
  StyledPagination,
  FullWidth,
} from './styled'
import { PAGINATION_LIMIT } from './consts'
import { Redirect } from 'react-router-dom'
import { RoutesTree } from '../../router'

export const FeedbacksPage = () => {
  const dispatch = useDispatch()

  const { supports } = useTypedSelector(getSupport)
  const { user } = useTypedSelector(state => state.user)

  const [currentPage, setCurrentPage] = useState<number>(1)

  const getSupportData = () => {
    dispatch(
      supportActions.getSupports({
        page: currentPage === 0 ? 1 : currentPage,
        limit: PAGINATION_LIMIT,
      }),
    )
  }

  useEffect(() => {
    getSupportData()
  }, [currentPage])

  const choosePage = (page: number) => {
    setCurrentPage(page === 0 ? 1 : page)
  }

  const onDeleteFeedback = async (id: string) => {
    try {
      const result = window.confirm('Удалить фидбек?')

      if (result) {
        dispatch(supportActions.deleteSupport({ _id: id }))
        getSupportData()
        getSupportData()
      }
    } catch (error) {}
  }

  if (user?.access?.feedbacks) {
    return <Redirect to={RoutesTree.notFound.path} />
  }

  return (
    <Main>
      <Title title={`Техподдержка (${supports.totalCount})`} />
      <SupportWrapper>
        <HeaderSection>
          <ItemBlock width="150px">
            <Text
              fontSize="16px"
              fontWeight={700}
              color={`#1F166A`}
              text="Дата"
            />
          </ItemBlock>
          <ItemBlock width="150px">
            <Text
              fontSize="16px"
              fontWeight={700}
              color={`#1F166A`}
              text="Тип проблемы"
            />
          </ItemBlock>
          <ItemBlock width="200px">
            <Text
              fontSize="16px"
              fontWeight={700}
              color={`#1F166A`}
              text="Электронная почта"
            />
          </ItemBlock>
          <ItemBlock width="200px">
            <Text
              fontSize="16px"
              fontWeight={700}
              color={`#1F166A`}
              text="Текст"
            />
          </ItemBlock>
          <ItemBlock width="150px"></ItemBlock>
          <ItemBlock width="150px"></ItemBlock>
        </HeaderSection>
        <>
          {supports?.docs?.map((d: any) => (
            <MainBlock key={d?._id}>
              <ItemBlock width="150px">
                <Text
                  text={`${moment(d?.createdAt).format('DD-MM-YYYY HH:mm')}`}
                  fontSize="16px"
                  color={`#1F166A`}
                />
              </ItemBlock>
              <ItemBlock width="150px">
                <Text text={`${d?.type}`} fontSize="16px" color={`#1F166A`} />
              </ItemBlock>
              <ItemBlock width="200px">
                <Text text={`${d?.email}`} fontSize="16px" color={`#1F166A`} />
              </ItemBlock>
              <ItemBlock width="200px">
                <Text
                  text={`${d?.message}`}
                  fontSize="16px"
                  color={`#1F166A`}
                />
              </ItemBlock>
              <ItemBlock width="150px">
                <StyledLink
                  to={{
                    pathname: `/support-profile/${d._id}`,
                  }}
                >
                  Подробнее
                </StyledLink>
              </ItemBlock>
              <ItemBlock width="150px">
                <DeleteButton onClick={() => onDeleteFeedback(d?._id)}>
                  Удалить
                </DeleteButton>
              </ItemBlock>
            </MainBlock>
          ))}
        </>
        <FullWidth>
          <StyledPagination>
            {currentPage === 1 ? (
              <StyledButtonEnd>Назад</StyledButtonEnd>
            ) : (
              <StyledButton onClick={() => choosePage(currentPage - 1)}>
                Назад
              </StyledButton>
            )}

            <div>
              {currentPage}/
              {!supports.totalCount
                ? 1
                : Math.ceil(supports.totalCount / PAGINATION_LIMIT) === 0
                ? 1
                : Math.ceil(supports.totalCount / PAGINATION_LIMIT)}
            </div>

            {currentPage ===
            (!supports.totalCount
              ? 1
              : Math.ceil(supports.totalCount / PAGINATION_LIMIT)) ? (
              <StyledButtonEnd>Далее</StyledButtonEnd>
            ) : (
              <StyledButton onClick={() => choosePage(currentPage + 1)}>
                Далее
              </StyledButton>
            )}
          </StyledPagination>
          <Divider height={10} />
        </FullWidth>
      </SupportWrapper>
    </Main>
  )
}
