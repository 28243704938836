import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Table from 'rc-table'
import moment from 'moment'

import { Divider, EInputTemplate, Input } from '../../components'
import { useTypedSelector } from '../../hooks'
import { Assets } from '../../assets'

import {
  createCategoriesClinicsCategoryAction,
  deleteCategoriesClinicsCategoryAction,
  getCategoriesClinicsCategoryAction,
  updateCategoriesClinicsCategoryAction,
} from '../../store/clinicsCategory'

import { Modal } from '../../components/Modal'
import { PhotoPicker } from '../../components/PhotoPicker'

import {
  Header,
  StyledHeader,
  StyledRow,
  StyledTable,
  TableWrapper,
  Title,
  StyledPagination,
  StyledButtonEnd,
  StyledButton,
  PhotoCateogry,
  FullWidth,
  StyledEntity,
  Image,
  StyledSort,
  HeaderTitle,
  HeaderModal,
  FooterModal,
  ButtonCreate,
  ButtonDelete,
  ButtonUpdate,
  Close,
  NavigateButton,
  BodyModal,
  Description,
  Label,
} from './styled'
import { PAGE_LiMIT } from './consts'

export const ClinicsCategory = () => {
  const [typeModal, setTypeModal] = useState<string | null>(null)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [cateoriesArray, setCategoriesArray] = useState<any>([])
  const [searchPage, setSearchPage] = useState<number>(1)
  const [currentData, setCurrentData] = useState<any>(null)
  const [formData, setFormData] = useState<{
    title: string
    photo: null | File | string
    description: string
  }>({
    title: '',
    photo: null,
    description: '',
  })

  const dispatch = useDispatch()

  const { totalCount, categoriesClinics, response } = useTypedSelector(
    state => state.clinicsCategory,
  )

  const columns = [
    {
      title: <div>Имя</div>,
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: <div>Фото</div>,
      dataIndex: 'image',
      key: 'image',
    },
    {
      title: (
        <StyledSort>
          <div>Дата создания</div>

          <Divider width={10} />
        </StyledSort>
      ),
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Подробнее',
      dataIndex: 'details',
      key: 'details',
    },
  ]

  const choosePage = (page: number) => {
    dispatch(
      getCategoriesClinicsCategoryAction.request({
        page: page === 0 ? 1 : page,
        limit: PAGE_LiMIT,
        sortBy: '',
      }),
    )
    setCurrentPage(page === 0 ? 1 : page)
  }

  const putSearchPage = (e: React.SyntheticEvent<Element, Event> | any) => {
    setSearchPage(isNaN(+e.target.value) ? 1 : +e.target.value)
  }

  const enterKeyPress = (e: any) => {
    e?.code === 'Enter' &&
      choosePage(
        searchPage <=
          (totalCount === 0 ? 1 : Math.ceil(totalCount / PAGE_LiMIT))
          ? searchPage !== currentPage
            ? searchPage
            : 1
          : 1,
      )
  }

  const createCategories = () => {
    const data = new FormData()

    if (formData.photo) {
      data.append('image', formData.photo)
    }
    if (formData.title) {
      data.append('title', JSON.stringify({ uk: formData.title }))
    }
    if (formData.description) {
      data.append('description', JSON.stringify({ uk: formData.description }))
    }

    dispatch(createCategoriesClinicsCategoryAction.request({ data }))
  }

  const updateCategories = () => {
    const data = new FormData()

    if (typeof formData.photo !== 'string') {
      data.append('image', formData.photo as File)
    }
    
    if (formData.title) {
      data.append('title', JSON.stringify({ uk: formData.title }))
    }
    if (formData.description) {
      data.append('description', JSON.stringify({ uk: formData.description }))
    }

    dispatch(
      updateCategoriesClinicsCategoryAction.request({
        id: currentData._id,
        data,
      }),
    )
  }

  const deleteCategories = () => {
    dispatch(
      deleteCategoriesClinicsCategoryAction.request({ id: currentData._id }),
    )
  }

  const handleInputChange = (e: any) => {
    const target = e.target as HTMLInputElement
    setFormData({ ...formData, title: target.value })
  }

  const handlePhotoChange = (file: File | null) => {
    setFormData({ ...formData, photo: file })
  }

  const getCategoriesClinics = () => {
    dispatch(
      getCategoriesClinicsCategoryAction.request({
        page: currentPage === 0 ? 1 : currentPage,
        limit: PAGE_LiMIT,
        sortBy: 'createdAt',
      }),
    )
  }
  // eslint-disable-next-line
  useEffect(() => {
    getCategoriesClinics()
  }, [])

  useEffect(() => {
    if (
      response === 'CREATE_CATEGORIES' ||
      response === 'UPDATE_CATEGORIES' ||
      response === 'DELETE_CATEGORIES'
    ) {
      setTypeModal('')
      setFormData({
        photo: null,
        title: '',
        description: '',
      })
      getCategoriesClinics()
    }
  }, [response])

  const openCurrentCategory = (data: any) => {
    setTypeModal('update')
    setCurrentData(data)
  }

  const closeModal = () => {
    setTypeModal(null)
    setCurrentData(null)
    setFormData({
      photo: null,
      title: '',
      description: '',
    })
  }

  useEffect(() => {
    setCategoriesArray(
      categoriesClinics?.map((c: any) => ({
        image: (
          <PhotoCateogry
            src={`https://dev-petshealth.api.kitg.com.ua/public/service-categories/${c?.image}`}
            alt="categories-image"
          />
        ),
        title: c?.title?.uk ?? '-',
        createdAt: moment(c?.createdAt).isValid()
          ? moment(c?.createdAt).format('HH:mm DD.MM.YYYY')
          : '-',
        key: c?.id,
        details: (
          <NavigateButton onClick={() => openCurrentCategory(c)}>
            Подробнее
          </NavigateButton>
        ),
      })),
    )
  }, [categoriesClinics])

  useEffect(() => {
    if (currentData) {
      setFormData({
        title: currentData?.title?.uk,
        photo: `https://dev-petshealth.api.kitg.com.ua/public/service-categories/${currentData?.image}`,
        description: currentData?.description?.uk,
      })
    }
  }, [currentData])

  return (
    <>
      <div>
        <Header>
          <Title>{`Категории клиник (${totalCount})`}</Title>
          <StyledPagination>
            <StyledEntity onKeyPress={enterKeyPress}>
              <Input
                label="Страница"
                name="searchPage"
                value={searchPage.toString()}
                placeholder="Страница"
                template={EInputTemplate.NAME}
                onChange={putSearchPage}
              />
            </StyledEntity>
            <Divider width={10} />
            <Image
              src={Assets.SEARCH_ICON}
              onClick={() =>
                choosePage(
                  searchPage <=
                    (totalCount === 0 ? 1 : Math.ceil(totalCount / PAGE_LiMIT))
                    ? searchPage
                    : 1,
                )
              }
            />
          </StyledPagination>
          <ButtonCreate onClick={() => setTypeModal('create')}>
            Создать
          </ButtonCreate>
        </Header>

        <Divider height={30} />

        <TableWrapper>
          <Table
            columns={columns}
            data={cateoriesArray}
            scroll={{ y: 650 }}
            components={{
              body: {
                row: StyledRow,
              },
              header: {
                cell: StyledHeader,
              },
              table: StyledTable,
            }}
          />
        </TableWrapper>

        <Divider height={20} />
      </div>

      <FullWidth>
        <StyledPagination>
          {currentPage === 1 ? (
            <StyledButtonEnd>Назад</StyledButtonEnd>
          ) : (
            <StyledButton onClick={() => choosePage(currentPage - 1)}>
              Назад
            </StyledButton>
          )}

          <div>
            {currentPage}/
            {!totalCount
              ? 1
              : Math.ceil(totalCount / PAGE_LiMIT) === 0
              ? 1
              : Math.ceil(totalCount / PAGE_LiMIT)}
          </div>

          {currentPage ===
          (!totalCount ? 1 : Math.ceil(totalCount / PAGE_LiMIT)) ? (
            <StyledButtonEnd>Далее</StyledButtonEnd>
          ) : (
            <StyledButton onClick={() => choosePage(currentPage + 1)}>
              Далее
            </StyledButton>
          )}
        </StyledPagination>
        <Divider height={10} />
      </FullWidth>
      {typeModal && (
        <Modal>
          <HeaderModal>
            <HeaderTitle>
              {typeModal === 'create'
                ? 'Создать Категорию'
                : 'Обновить категорию'}
            </HeaderTitle>
            <Close onClick={closeModal}>&#x2716;</Close>
          </HeaderModal>
          <BodyModal>
            <Input
              label="Название"
              name="title"
              value={formData.title}
              placeholder="Введите имя"
              template={EInputTemplate.NAME}
              onChange={handleInputChange}
            />
            <PhotoPicker
              width={315}
              height={150}
              onChange={handlePhotoChange}
              value={formData.photo ? formData.photo : undefined}
            />
            <Label>Описание</Label>
            <Description
              value={formData.description}
              onChange={(e) =>
                setFormData((prevState: any) => ({
                  ...prevState,
                  description: e.target.value,
                }))
              }
            />
          </BodyModal>
          <FooterModal>
            {typeModal === 'create' ? (
              <ButtonCreate onClick={createCategories}>Создать</ButtonCreate>
            ) : (
              <>
                <ButtonDelete onClick={deleteCategories}>Удалить</ButtonDelete>
                <ButtonUpdate onClick={updateCategories}>Обновить</ButtonUpdate>
              </>
            )}
          </FooterModal>
        </Modal>
      )}
    </>
  )
}
