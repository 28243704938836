export const COLORS = {
  black: '#000000',
  green: '#00C58D',
  white: '#FFFFFF',
  gray: '#EEEEEE',
  red: '#FC5C63',
  darkWhite: '#F7F7F7',
  lightBlue: '#1F166A',
  lightGray: '#767676',
  aqua: '#EEEEEE',
}
