import { AxiosResponse } from 'axios'
import {
  ChangePasswordActionPayload,
  ForgotPasswordActionPayload,
  GetUserActionResponse,
  LoginUserActionPayload,
  LoginUserActionResponse,
} from './types'

import { setAuthorizationHeadersProp } from '../../helpers'
import { PayloadAuth } from '../types'
import { HttpService } from '../../services'
import { USER_URL } from './config'

export class UserAPI extends HttpService {
  static login(
    request: LoginUserActionPayload,
  ): Promise<AxiosResponse<LoginUserActionResponse>> {
    return this.request<LoginUserActionResponse>('LOGIN', {
      url: USER_URL.login,
      method: 'POST',
      data: request,
    })
  }

  static getUser(
    request: PayloadAuth,
  ): Promise<AxiosResponse<GetUserActionResponse>> {
    return this.request<GetUserActionResponse>('GET_USER', {
      url: USER_URL.get_user,
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static forgotPassword(
    request: ForgotPasswordActionPayload,
  ): Promise<AxiosResponse<void>> {
    return this.request('FORGOT_PASSWORD', {
      url: USER_URL.forgot_password,
      method: 'POST',
      data: request,
    })
  }

  static changePassword(
    request: ChangePasswordActionPayload,
  ): Promise<AxiosResponse<void>> {
    return this.request('CHANGE_PASSWORD', {
      url: USER_URL.change_password,
      method: 'POST',
      data: {
        password: request.password,
      },
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }
}
