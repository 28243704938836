import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Table from 'rc-table'
import { Divider, EInputTemplate, Input } from '../../components'
import {
  Container,
  Header,
  StyledHeader,
  StyledRow,
  StyledTable,
  TableWrapper,
  Title,
  StyledLink,
  StyledPagination,
  StyledButtonEnd,
  StyledButton,
  FullWidth,
  StyledEntity,
  Image,
  StyledSort,
  SortImage,
} from './styled'
import { getCustomersAction, getPetsAction } from '../../store'
import { usePagination, useTypedSelector } from '../../hooks'
import { TypedPets, TPet, TSort } from './types'
import { RoutesTree } from '../../router'
import moment from 'moment'
import { Redirect } from 'react-router-dom'
import { Assets } from '../../assets'

const petType = {
  cat: 'Кот',
  dog: 'Собака',
  bird: 'Птица',
  humster: 'Хомяк',
  ferret: 'Хорек',
  toad: 'Рептилия',
  snail: 'Беспозвоночный питомец',
  fish: 'Рыбки',
  spider: 'Членистоногий питомец',
  amphibian: 'Амфибия',
  monkey: 'Экзотический питомец',
  dinosaur: 'Особенный питомец',
  rabbit: 'Кролик',
}

const Customers: React.FC = () => {
  const [typedPets, setTypedPets] = useState<TypedPets[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [paginationLimit, setPaginationLimit] = useState<number>(20)
  const [searchPage, setSearchPage] = useState<number>(1)
  const [sort, setSort] = useState<TSort>({
    type: false,
    name: false,
    breed: false,
    gender: false,
    createdAt: false,
  })
  const [sortName, setSortName] = useState<string>('')
  const [search, setSearch] = useState<string>('')

  const dispatch = useDispatch()

  const { pets, total } = useTypedSelector(state => state.pets)
  const { user } = useTypedSelector(state => state.user)

  if (!user?.access?.pets) {
    return <Redirect to={RoutesTree.notFound.path} />
  }
  // eslint-disable-next-line
  useEffect(() => {
    const localValues = localStorage.getItem('pets') ?? ''
    const parseLocalValues = !!localValues ? JSON.parse(localValues) : ''
    // eslint-disable-next-line
    !!localValues
      ? (setCurrentPage(parseLocalValues.page),
        setSearchPage(parseLocalValues.page),
        setSort(parseLocalValues.sort),
        setSortName(parseLocalValues.sortName),
        setSearch(parseLocalValues.search),
        dispatch(
          getPetsAction.request({
            page: parseLocalValues.page,
            limit: paginationLimit,
            search: parseLocalValues.search,
            sortBy: parseLocalValues.sortName,
          }),
        ))
      : dispatch(
          getPetsAction.request({
            page: currentPage,
            limit: paginationLimit,
            search,
            sortBy: '',
          }),
        )
  }, [])

  const sortByParam = (name: string) => {
    setSortName(sort[name as keyof TSort] ? name + 'ASC' : name + 'DESC')

    setSort(prev => ({ ...prev, [name]: !prev[name as keyof TSort] }))

    localStorage.setItem(
      'pets',
      JSON.stringify({
        page: currentPage,
        sort: { ...sort, [name]: !sort[name as keyof TSort] },
        sortName: sort[name as keyof TSort] ? name + 'ASC' : name + 'DESC',
        search,
      }),
    )

    dispatch(
      getPetsAction.request({
        page: currentPage === 0 ? 1 : currentPage,
        limit: paginationLimit,
        search,
        sortBy: sort[name as keyof TSort] ? name + 'ASC' : name + 'DESC',
      }),
    )
  }

  const columns = [
    {
      title: (
        <StyledSort onClick={() => sortByParam('name')}>
          <div>Имя</div>

          <Divider width={10} />

          <SortImage
            src={sort.name ? Assets.UP_ARROW_ICON : Assets.DOWN_ARROW_ICON}
          />
        </StyledSort>
      ),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: (
        <StyledSort onClick={() => sortByParam('type')}>
          <div>Тип</div>

          <Divider width={10} />

          <SortImage
            src={sort.type ? Assets.UP_ARROW_ICON : Assets.DOWN_ARROW_ICON}
          />
        </StyledSort>
      ),
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: (
        <StyledSort onClick={() => sortByParam('breed')}>
          <div>Порода</div>

          <Divider width={10} />

          <SortImage
            src={sort.breed ? Assets.UP_ARROW_ICON : Assets.DOWN_ARROW_ICON}
          />
        </StyledSort>
      ),
      dataIndex: 'breed',
      key: 'breed',
    },
    {
      title: (
        <StyledSort onClick={() => sortByParam('gender')}>
          <div>Пол</div>

          <Divider width={10} />

          <SortImage
            src={sort.gender ? Assets.UP_ARROW_ICON : Assets.DOWN_ARROW_ICON}
          />
        </StyledSort>
      ),
      dataIndex: 'gender',
      key: 'gender',
    },
    {
      title: (
        <StyledSort onClick={() => sortByParam('createdAt')}>
          <div>Дата создания</div>

          <Divider width={10} />

          <SortImage
            src={sort.createdAt ? Assets.UP_ARROW_ICON : Assets.DOWN_ARROW_ICON}
          />
        </StyledSort>
      ),
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Подробнее',
      dataIndex: 'details',
      key: 'details',
    },
  ]

  const choosePage = (page: number) => {
    localStorage.setItem(
      'pets',
      JSON.stringify({
        page: page === 0 ? 1 : page,
        sort: sort,
        sortName: sortName,
        search,
      }),
    )

    dispatch(
      getPetsAction.request({
        page: page === 0 ? 1 : page,
        limit: paginationLimit,
        search,
        sortBy: sortName,
      }),
    )

    setCurrentPage(page === 0 ? 1 : page)
  }

  const putSearchPage = (e: React.SyntheticEvent<Element, Event> | any) => {
    setSearchPage(isNaN(+e.target.value) ? 1 : +e.target.value)
  }

  const enterKeyPress = (e: any) => {
    e?.code === 'Enter' &&
      choosePage(
        searchPage <= (total === 0 ? 1 : Math.ceil(total / paginationLimit))
          ? searchPage !== currentPage
            ? searchPage
            : 1
          : 1,
      )
  }

  const data = () => {
    return pets?.map(pet => {
      return {
        type: !!pet?.type ? petType[pet?.type] : '-',
        name: pet?.name ?? '-',
        breed: pet?.breed ?? '-',
        gender: pet?.gender ?? '-',
        createdAt: moment(pet?.createdAt).isValid()
          ? moment(pet?.createdAt).format('HH:mm DD.MM.YYYY')
          : '-',
        key: pet?.id,
        details: (
          <StyledLink
            to={{
              pathname: `/pet-details/${pet?.id}`,
            }}
          >
            Подробнее
          </StyledLink>
        ),
      }
    })
  }
  // eslint-disable-next-line
  useEffect(() => {
    setTypedPets(data())
  }, [pets])

  const putTitle = (e: any) => {
    setSearch(e.target.value)
  }

  return (
    <Container>
      <div>
        <Header>
          <Title>Питомцы ({total ?? 0})</Title>

          <StyledPagination>
            <StyledEntity onKeyPress={enterKeyPress}>
              <Input
                label="Поиск"
                name="search"
                value={search}
                placeholder="Название"
                template={EInputTemplate.NAME}
                onChange={putTitle}
              />
            </StyledEntity>
            <Divider width={10} />
            <Image
              src={Assets.SEARCH_ICON}
              onClick={() =>
                choosePage(
                  searchPage <=
                    (total === 0 ? 1 : Math.ceil(total / paginationLimit))
                    ? searchPage
                    : 1,
                )
              }
            />
          </StyledPagination>

          <StyledPagination>
            <StyledEntity onKeyPress={enterKeyPress}>
              <Input
                label="Страница"
                name="searchPage"
                value={searchPage.toString()}
                placeholder="Страница"
                template={EInputTemplate.NAME}
                onChange={putSearchPage}
              />
            </StyledEntity>
            <Divider width={10} />
            <Image
              src={Assets.SEARCH_ICON}
              onClick={() =>
                choosePage(
                  searchPage <=
                    (total === 0 ? 1 : Math.ceil(total / paginationLimit))
                    ? searchPage
                    : 1,
                )
              }
            />
          </StyledPagination>
        </Header>

        <Divider height={30} />

        <TableWrapper>
          <Table
            columns={columns}
            data={typedPets}
            scroll={{ y: 650 }}
            components={{
              body: {
                row: StyledRow,
              },
              header: {
                cell: StyledHeader,
              },
              table: StyledTable,
            }}
          />
        </TableWrapper>

        <Divider height={20} />
      </div>

      <FullWidth>
        <StyledPagination>
          {currentPage === 1 ? (
            <StyledButtonEnd>Назад</StyledButtonEnd>
          ) : (
            <StyledButton onClick={() => choosePage(currentPage - 1)}>
              Назад
            </StyledButton>
          )}

          <div>
            {currentPage}/
            {!total
              ? 1
              : Math.ceil(total / paginationLimit) === 0
              ? 1
              : Math.ceil(total / paginationLimit)}
          </div>

          {currentPage === (!total ? 1 : Math.ceil(total / paginationLimit)) ? (
            <StyledButtonEnd>Далее</StyledButtonEnd>
          ) : (
            <StyledButton onClick={() => choosePage(currentPage + 1)}>
              Далее
            </StyledButton>
          )}
        </StyledPagination>
        <Divider height={10} />
      </FullWidth>
    </Container>
  )
}

export default Customers
