export const translates = {
  MoreStack: 'Просмотры таба "Больше"',
  ServicesStack: 'Просмотры экранов с сервисами',
  PetsStack: 'Просмотры экранов Таба питомцев',
  ProfileSections: 'Просмотры "Профиль"',
  HomeStack: 'Просмотры экранов "Главной страницы"',
  PetProfileSections: 'Просмотры "Профиль питомца"',
  OpenSubscriptionModal: 'Клик на открытие модалки с подпиской',
  PetsSharing: 'Кол-во кликов на шеринг приложения',
  CreateEvent: 'Кол-во кликов на кнопку "Создать событие"',
  PetsSharingInApp: 'Кол-во кликов на кнопку шеринга питомца',
  Services: 'Переходы на таб с сервисами',
  CategoryTab: 'Переходы на вкладку с списком сервисов',
  MapTab: 'Переход на вкладку с сервисами на карте',
  ListTab: 'Переход на экран со списком сервисов соответственно категории',
  Complain: 'Нажатие на кнопку "Жалоба" в клинике',
  Inaccuracy: 'Переход на экран написания вопроса',
  ServiceProfile: "Переход на экран 'Клиники'",
  TilesTab: 'Переходы на экран с галереей картинок в клинике',
  ClinicDocs: 'Переходы на экран с документами клиники',
  Feedback: 'Переход на экран с отзывами "Клиники"',
  ReplyFeedback: 'Переход на экран с ответами на отзыв "Клиники"',
  MainEvents: 'Переход на стак с действиями',
  MoreAccount: 'Переход на главный  экран таба "Больше"',
  MoreSupport: 'Переход на экран "Поддержка"',
  MoreAbout: 'Переход на экран с информацией про приложение',
  MoreChooseActions: 'Переход на экран "Выбора языка"',
  EditProfile: 'Переход на экран "Редактирования профиля"',
  MoreСonfidentiality: 'Конфиденциальность',
  all: 'Все сервисы',
  veterinary: 'Ветеринарная помощь',
  grooming: 'Груминг',
  training: 'Дрессировка и воспитание',
  hotels: 'Zo:отели',
  shelters: 'Приюты',
  nurseries: 'Питомники',
  other: 'Другие сервисы',
  store: 'Зоомагазины',
  pharmacy: 'Зооаптеки',
  walking: 'Места для прогулок',
  petFriendly: 'Пет френдли места',
  vetLab: 'Вет. Лаборатория',
  AddEvent: 'Добавить ивент',
  HomeMain: 'Главный экран',
  Passport: 'Паспорт питомца',
  EventsCalendar: 'Календарь ивентов',
  MedicalHistory: 'Мед. история',
  PetsMain: 'Экран с списком питомцев',
  PetsProfile: 'Экран с паспортом питомца',
  InfoTab: 'Переход в паспорт питомца',
  InfoTabMain: 'Экран "Информация про питомца в паспорте"',
  AddPet: 'Кнопка "Добавить питомца"',
  VaccinationTab: 'Экран "Вакцины" при закрытом доступе к функционалу ',
  VaccineTabMain: 'Экран "Вакцины"',
  Accesses: 'Экран "Доступ к питомцу (шеринг питомца)"',
  AllergiesTab: 'Экран "Аллергии" при закрытом доступе (отсутствие пермишена)',
  AddAccess: 'Экран "Предоставления доступа к питомцу"',
  DocsTab: 'Экран "Мед. Документы" при закрытом доступе (отсутствие пермишена)',
  DocsTabMain: 'Экран "Мед. Документы"',
  AllergyTabMain: 'Экран "Аллергии"',
  PetsMedical: 'Экран с "Мед документами"',
  DocsList: 'Просмотр списка документов',
  AddEditAllergie: 'Kлик на редактирование аллергий',
  Events: 'Kлик на ивенты',
  EditPetDetail: 'Kлик на редактирование питомца',
  Regularity: 'Kлики по выбору регулярности события',
  Support: 'Клики на "Обратиться в поддержку"',
  BusinessProfile: 'Клики на "Как стать бизнес профилем?"',
  FrequentlyQuestions: 'Клики на "Часто задаваемые вопросы"',
  InviteFriends: 'Клики на "Приглашение друзей"',
  PetsForum: 'Клики на "Клики на кнопку "Pets Forum"',
  CareTips: 'Клики на "Рекомендации по уходу"',
}
