import { takeLatest, select, call, put, take } from '@redux-saga/core/effects'
import {
  addDocumentToClinicAction,
  addPhotoToClinicAction,
  createClinicAction,
  deleteClinicAction,
  deleteDocumentFromClinicAction,
  deleteImageFromClinicAction,
  getClinicByIdAction,
  getClinicsAction,
  putClinicLogoAction,
  searchClinicAction,
  updateClinicAction,
} from './actions'
import { AuthorizationError } from '../../errors'
import { PromiseReturnType } from '../types'
import { ClinicsAPI } from './api.service'
import { showToastAction } from '../toasts'
import { generateErrorToast, generateSuccessToast } from '../../helpers'
import { Log } from '../../utils'
import { getUserSelector } from '../user'
import { AxiosError } from 'axios'

function* verifyTokenWorker() {
  const { token }: ReturnType<typeof getUserSelector> = yield select(
    getUserSelector,
  )

  if (token) return token

  throw new AuthorizationError('verifyTokenWorker')
}

function* getClinicsWorker({
  payload,
}: ReturnType<typeof getClinicsAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof ClinicsAPI.getClinics>
    > = yield call([ClinicsAPI, ClinicsAPI.getClinics], {
      authorization: token,
      ...payload,
    })

    yield put(
      getClinicsAction.success({
        clinics: response.data.clinics,
        total: response.data.total,
      }),
    )
  } catch (e) {
    Log.ruddy('Error: getClinicsWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения клиник')),
    )

    yield put(getClinicsAction.failure(e as AxiosError))
  }
}

function* addImageToClinicWorker({
  payload,
}: ReturnType<typeof addPhotoToClinicAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof ClinicsAPI.addImageToClinic>
    > = yield call([ClinicsAPI, ClinicsAPI.addImageToClinic], {
      authorization: token,
      data: payload.data,
      id: payload.id,
    })

    yield put(getClinicByIdAction.request({ id: payload.id }))

    yield take(getClinicByIdAction.success)

    yield put(addPhotoToClinicAction.success(response.data))
  } catch (e) {
    console.log('Error: addPhotoToClinicWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка добавления фото')),
    )

    yield put(addPhotoToClinicAction.failure(e as AxiosError))
  }
}

function* addDocumentToClinicWorker({
  payload,
}: ReturnType<typeof addDocumentToClinicAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof ClinicsAPI.addDocumentToClinic>
    > = yield call([ClinicsAPI, ClinicsAPI.addDocumentToClinic], {
      authorization: token,
      data: payload.data,
      id: payload.id,
    })

    yield put(getClinicByIdAction.request({ id: payload.id }))

    yield take(getClinicByIdAction.success)

    yield put(addDocumentToClinicAction.success(response.data))
  } catch (e) {
    console.log('Error: addDocumentToClinicWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка добавления документа'),
      ),
    )

    yield put(addDocumentToClinicAction.failure(e as AxiosError))
  }
}

function* deleteDocumentFromClinicWorker({
  payload,
}: ReturnType<typeof deleteDocumentFromClinicAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof ClinicsAPI.deleteDocumentFromClinic>
    > = yield call([ClinicsAPI, ClinicsAPI.deleteDocumentFromClinic], {
      authorization: token,
      image: payload.image,
      id: payload.id,
    })

    yield put(deleteDocumentFromClinicAction.success(response.data))

    yield put(getClinicByIdAction.request({ id: payload.id }))

    yield take(getClinicByIdAction.success)
  } catch (e) {
    console.log('Error: deleteDocumentFromClinicWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка удаления документа')),
    )

    yield put(deleteDocumentFromClinicAction.failure(e as AxiosError))
  }
}

function* deleteImageFromClinicWorker({
  payload,
}: ReturnType<typeof deleteImageFromClinicAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof ClinicsAPI.deleteImageFromClinic>
    > = yield call([ClinicsAPI, ClinicsAPI.deleteImageFromClinic], {
      authorization: token,
      image: payload.image,
      id: payload.id,
    })

    yield put(deleteImageFromClinicAction.success(response.data))

    yield put(getClinicByIdAction.request({ id: payload.id }))

    yield take(getClinicByIdAction.success)
  } catch (e) {
    console.log('Error: deleteImageFromClinicWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка удаления картинки')),
    )

    yield put(deleteImageFromClinicAction.failure(e as AxiosError))
  }
}

function* createClinicWorker({
  payload,
}: ReturnType<typeof createClinicAction['request']>) {
  console.log(payload.data)

  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const response: PromiseReturnType<
      ReturnType<typeof ClinicsAPI.createClinic>
    > = yield call([ClinicsAPI, ClinicsAPI.createClinic], {
      authorization: token,
      data: payload.data,
    })

    yield put(createClinicAction.success(response.data.data))

    if (response) {
      yield put(
        putClinicLogoAction.request({
          data: payload.image,
          id: response.data.data._id,
        }),
      )

      yield take(putClinicLogoAction.success)

      if (!!payload.dataImage) {
        yield put(
          addPhotoToClinicAction.request({
            data: payload.dataImage as FormData,
            id: response.data.data._id,
          }),
        )

        yield take(addPhotoToClinicAction.success)
      }

      if (!!payload.dataDocuments) {
        yield put(
          addDocumentToClinicAction.request({
            data: payload.dataDocuments as FormData,
            id: response.data.data._id,
          }),
        )

        yield take(addDocumentToClinicAction.success)
      }
    }

    yield put(
      showToastAction.request(generateSuccessToast('Клиника успешно создана')),
    )
  } catch (e) {
    console.log('Error: createClinicWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка создания клиники')),
    )

    yield put(createClinicAction.failure(e as AxiosError))
  }
}

function* updateClinicWorker({
  payload,
}: ReturnType<typeof updateClinicAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const response: PromiseReturnType<
      ReturnType<typeof ClinicsAPI.updateClinic>
    > = yield call([ClinicsAPI, ClinicsAPI.updateClinic], {
      authorization: token,
      data: payload.data,
      id: payload.id,
    })

    if (response) {
      yield put(updateClinicAction.success(response.data.data))

      if (!!payload.image) {
        yield put(
          putClinicLogoAction.request({ data: payload.image, id: payload.id }),
        )

        yield take(putClinicLogoAction.success)
      }

      if (!!payload.dataImage) {
        yield put(
          addPhotoToClinicAction.request({
            data: payload.dataImage as FormData,
            id: payload.id,
          }),
        )

        yield take(addPhotoToClinicAction.success)
      }

      if (!!payload.dataDocuments) {
        yield put(
          addDocumentToClinicAction.request({
            data: payload.dataDocuments as FormData,
            id: payload.id,
          }),
        )

        yield take(addDocumentToClinicAction.success)
      }

      const res: PromiseReturnType<
        ReturnType<typeof ClinicsAPI.getClinicById>
      > = yield call([ClinicsAPI as any, ClinicsAPI.getClinicById as any], {
        authorization: token,
        id: payload.id,
      })

      yield put(getClinicByIdAction.success(res.data))
    }

    yield put(
      showToastAction.request(
        generateSuccessToast('Клиника успешно обновлёна'),
      ),
    )
  } catch (e) {
    console.log('Error: updateClinicWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка обновления клиники')),
    )

    yield put(updateClinicAction.failure(e as AxiosError))
  }
}

function* putClinicLogoWorker({
  payload,
}: ReturnType<typeof putClinicLogoAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const response: PromiseReturnType<
      ReturnType<typeof ClinicsAPI.putClinicLogo>
    > = yield call([ClinicsAPI, ClinicsAPI.putClinicLogo], {
      authorization: token,
      data: payload.data,
      id: payload.id,
    })

    yield put(putClinicLogoAction.success(response.data.data))
  } catch (e) {
    console.log('Error: putClinicLogoWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка добавления фотографии'),
      ),
    )

    yield put(putClinicLogoAction.failure(e as AxiosError))
  }
}

function* deleteClinicWorker({
  payload,
}: ReturnType<typeof deleteClinicAction>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof ClinicsAPI.deleteClinic>
    > = yield call([ClinicsAPI, ClinicsAPI.deleteClinic], {
      id: payload.id,
      authorization: token,
    })

    yield put(
      showToastAction.request(generateSuccessToast('Клиника удалена успешно')),
    )
  } catch (e) {
    Log.ruddy('Error: deleteClinicWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка удаления клиники')),
    )
  }
}

function* searchClinicWorker({
  payload,
}: ReturnType<typeof searchClinicAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof ClinicsAPI.searchClinic>
    > = yield call([ClinicsAPI, ClinicsAPI.searchClinic], {
      authorization: token,
      page: payload.page,
      limit: payload.limit,
      title: payload.title,
    })

    yield put(
      searchClinicAction.success({
        data: response.data.clinics,
        total: response.data.total,
      }),
    )
  } catch (e) {
    Log.ruddy('Error: searchClinicWorker', e)

    yield put(searchClinicAction.failure(e as AxiosError))
  }
}

function* getClinicByIdWorker({ payload }: { payload: { id: string } }) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const res: PromiseReturnType<ReturnType<typeof ClinicsAPI.getClinicById>> =
      yield call([ClinicsAPI, ClinicsAPI.getClinicById], {
        authorization: token,
        id: payload.id,
      })

    yield put(getClinicByIdAction.success(res.data))
  } catch (e) {
    Log.ruddy('Error: getClinicByIdWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения клиники')),
    )

    yield put(getClinicByIdAction.failure(e as AxiosError))
  }
}

export function* clinicsWatcher() {
  yield takeLatest(getClinicsAction.request, getClinicsWorker)
  yield takeLatest(getClinicByIdAction.request, getClinicByIdWorker)
  yield takeLatest(createClinicAction.request, createClinicWorker)
  yield takeLatest(updateClinicAction.request, updateClinicWorker)
  yield takeLatest(deleteClinicAction, deleteClinicWorker)
  yield takeLatest(putClinicLogoAction.request, putClinicLogoWorker)
  yield takeLatest(addPhotoToClinicAction.request, addImageToClinicWorker)
  yield takeLatest(addDocumentToClinicAction.request, addDocumentToClinicWorker)
  yield takeLatest(searchClinicAction.request, searchClinicWorker)
  yield takeLatest(
    deleteImageFromClinicAction.request,
    deleteImageFromClinicWorker,
  )
  yield takeLatest(
    deleteDocumentFromClinicAction.request,
    deleteDocumentFromClinicWorker,
  )
}
