import styled from 'styled-components'
import { FLEX, FONT } from '../../utils'
import { COLORS } from '../../assets'

export const Container = styled.div`
  width: 100%;
  /* height: 100%; */

  /* ${FLEX({})} */
`

export const SaveButton = styled.div`
  padding: 10px 10px 10px 10px;
  border: 1px solid;
  text-decoration: none;
  border-radius: 10px;

  width: 25%;

  transition: 0.15s ease-in-out;
  cursor: pointer;

  &:hover {
    background: ${COLORS.green};
  }

  ${FONT({ weight: '700', size: '16px', color: COLORS.lightBlue })}

  ${FLEX({})}
`

export const DeleteButton = styled.div`
  padding: 10px 10px 10px 10px;
  border: 1px solid;
  text-decoration: none;
  border-radius: 10px;

  width: 25%;

  transition: 0.15s ease-in-out;
  cursor: pointer;

  &:hover {
    background: ${COLORS.red};
  }

  ${FONT({ weight: '700', size: '16px', color: COLORS.lightBlue })}

  ${FLEX({})}
`

export const Header = styled.div`
  width: 100%;

  padding: 25px;

  background: ${COLORS.white};

  ${FLEX({ justify: 'space-between' })}
`

export const StyledContainer = styled.div`
  width: 100%;

  ${FLEX({ align: 'flex-start', justify: 'space-around' })}
`

export const StyledButton = styled.div`
  cursor: pointer;

  padding: 10px 10px;

  border-radius: 3px;

  width: 100%;

  background: ${COLORS.green};

  ${FLEX({})}

  ${FONT({ size: '14px', weight: '700', color: COLORS.white })}
`

export const StyledText = styled.div`
  ${FONT({ size: '16px', color: COLORS.lightBlue, weight: '700' })}
`

export const StyledDropDown = styled.div`
  width: 270px;
`

export const LoginWrapper = styled.div`
  width: 470px;

  padding: 50px;

  border-radius: 3px;

  background: ${COLORS.white};
`

export const Title = styled.h1`
  ${FONT({ size: '18px', color: COLORS.lightBlue, weight: '700' })}
`

export const Link = styled.p`
  text-decoration: none;

  cursor: pointer;

  ${FONT({
    size: '14px',
    weight: '700',
    align: 'center',
    color: COLORS.lightBlue,
  })}
`
