import { FC } from 'react'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { Container } from './styled'
import { HorizontalBarChartProps } from './types'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const HorizantalBarChart: FC<HorizontalBarChartProps> = ({
  iosModelsLabelArray,
  androidModelsLabelArray,
  iosModelsData,
  androidModelsData,
}) => {
  const options = {
    indexAxis: 'y' as const,
    elements: {
      bar: {
        borderWidth: 1,
        barThickness: '5px',
        width: '5px',
        height: '5px',
      },
    },
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Устройства',
      },
      legend: {
        display: false,
      },
    },
    maintainAspectRatio: false,
  }

  const data = {
    labels: [
      ...(iosModelsLabelArray as string[]),
      ...(androidModelsLabelArray as string[]),
    ],
    datasets: [
      {
        data: [
          ...(iosModelsData as number[]),
          ...(androidModelsData as number[]),
        ],
        borderColor: [
          ...(iosModelsData?.map(item => '#2c7cb8') as string[]),
          ...(androidModelsData?.map(item => '#1fac18') as string[]),
        ],
        backgroundColor: [
          ...(iosModelsData?.map(item => '#2c7cb8') as string[]),
          ...(androidModelsData?.map(item => '#1fac18') as string[]),
        ],
      },
    ],
  }

  return (
    <Container>
      <Bar options={options} data={data} height={350} />
    </Container>
  )
}
