import { FC, useEffect, useState } from 'react'
import {
  Container,
  Image,
  LineBlue,
  LineContainer,
  LineGrey,
  StyledContainer,
  StyledLinkDiv,
  StyledTextContainer,
  Title,
} from './styled'
import { LocalizationProvider } from '@mui/lab'
import ruLocale from 'date-fns/locale/ru'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { Assets } from '../../assets'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { GetMoreStackEventPropAction } from '../../store/stackprops'
import { Divider } from '../../components'
import { useTypedSelector } from '../../hooks'
import { translates } from './translates'
import { RoutesTree } from '../../router'

export const EventsboardInfo: FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { stackProps, OS } = useTypedSelector(state => state.stackprops)

  const id = useParams<{ id: string }>()

  const Events = {
    handleBack: () => {
      history.goBack()
    },
  }

  const Requests = {
    getMoreStackEventProp: () => {
      dispatch(
        GetMoreStackEventPropAction.request({
          start: '2023-01-01',
          end: '',
          event: id.id,
          app: OS,
        }),
      )
      utils.getRestPercentValue()
    },
  }

  const utils = {
    getRestPercentValue: () => {
      if (stackProps && stackProps.length >= 1) {
        const all: number = stackProps
          .map(item => item.total)
          .reduce((a, b) => {
            return a + b
          })
        const res: { name: string; percent: number }[] = stackProps.map(
          item => ({
            name: item._id,
            percent: Number(((item.total * 100) / (all as number)).toFixed(2)),
          }),
        )

        return res.sort((a, b) => b.percent - a.percent)
      }
    },
  }

  useEffect(() => {
    Requests.getMoreStackEventProp()
    utils.getRestPercentValue()
  }, [])

  return (
    <Container>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
        <StyledLinkDiv onClick={Events.handleBack}>
          <Image src={Assets.LEFT_ARROW_ICON} />
        </StyledLinkDiv>
        <Title>
          {
            //@ts-ignore
            translates[id.id]
          }
        </Title>

        <Divider height={60} />

        <StyledContainer>
          {utils.getRestPercentValue()?.map(item => (
            <>
              <StyledTextContainer>
                <p>
                  {
                    //@ts-ignore
                    translates[item.name] ? translates[item.name] : item.name
                  }
                </p>
                <p>{item.percent + '%'}</p>
              </StyledTextContainer>
              <LineContainer>
                <LineBlue width={item.percent} />
                <LineGrey width={100 - item.percent} />
              </LineContainer>
            </>
          ))}
        </StyledContainer>
      </LocalizationProvider>
    </Container>
  )
}
