import { AxiosResponse } from 'axios'

import { setAuthorizationHeadersProp } from '../../helpers'
import { PayloadAuth } from '../types'
import { HttpService } from '../../services'

import { ADS_URL } from './config';
import {
  DeleteAdsActionPayload,
  GetAdsActionResponse,
  GetAdsByIdActionResponse,
  GetAdsPayload,
  GetComplaintsAdsPayload,
} from './types';

export class AdsAPI extends HttpService {
  static getAds(
    request: GetAdsPayload & PayloadAuth,
  ): Promise<AxiosResponse<GetAdsActionResponse>> {
    return this.request<GetAdsActionResponse>('GET_ADS', {
      url: ADS_URL.get_ads(
        request.page,
        request.limit,
        request.sortBy,
      ),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static getComplainsAds(
    request: GetComplaintsAdsPayload & PayloadAuth,
  ): Promise<AxiosResponse<GetAdsActionResponse>> {
    return this.request<GetAdsActionResponse>('GET_ADS_COMPLAINTS', {
      url: ADS_URL.getComplaints(
        request.page,
        request.limit,
      ),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static createAds({
    authorization,
    data,
  }: PayloadAuth & any): Promise<AxiosResponse<GetAdsActionResponse>> {
    return this.request<GetAdsActionResponse>('CREATE_ADS', {
      url: ADS_URL.createAds,
      method: 'POST',
      data,
      headers: {
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static updateAds({
    authorization,
    data,
    id,
  }: PayloadAuth & any): Promise<AxiosResponse<GetAdsActionResponse>> {
    return this.request<GetAdsActionResponse>('UPDATE_ADS', {
      url: ADS_URL.updateAds(id),
      method: 'PUT',
      data,
      headers: {
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static getAdsById(
    request: {id:string, authorization: string},
  ): Promise<AxiosResponse<GetAdsByIdActionResponse>> {
    return this.request<GetAdsByIdActionResponse>('GET_ADS_BY_ID', {
      url: ADS_URL.getAdsById(request.id),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static deleteAds(
    request: DeleteAdsActionPayload,
  ): Promise<AxiosResponse<void>> {
    return this.request('DELETE_ADS', {
      url: ADS_URL.deleteAds(request.id),
      method: 'DELETE',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization ?? ""),
      },
    })
  }

  static deleteComplaintAds(
    request: DeleteAdsActionPayload,
  ): Promise<AxiosResponse<void>> {
    return this.request('COMPLAINTS_DELETE', {
      url: ADS_URL.deleteComplaintAds(request.id),
      method: 'DELETE',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization ?? ""),
      },
    })
  }
}
