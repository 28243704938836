import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'

import { useTypedSelector } from '../../hooks'
import { RoutesTree } from '../../router'

import { Container, TabBlock, TabText, TabsWrapper } from './styled'
import { CategoriesTab } from './CategoriesTab'
import { SubCategoriesTab } from './SubCategoriesTab'


export const Categories: React.FC = () => {
  const { user } = useTypedSelector(state => state.user)
  const [tab, setTab] = useState('1')

  if (!user?.access?.categories) {
    return <Redirect to={RoutesTree.notFound.path} />
  }

  return (
    <Container>
      <TabsWrapper>
        <TabBlock
          className={tab === '1' ? 'active' : ''}
          onClick={() => setTab('1')}
        >
          <TabText>Категории</TabText>
        </TabBlock>
        <TabBlock
          className={tab === '2' ? 'active' : ''}
          onClick={() => setTab('2')}
        >
          <TabText>Подкатегории</TabText>
        </TabBlock>
      </TabsWrapper>
      {tab === '1' && <CategoriesTab />}
      {tab === '2' && <SubCategoriesTab />}
    </Container>
  )
}
