import React, { ChangeEvent, FC, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Divider, EInputTemplate, Input } from '../../components'
import { useValidation } from '../../hooks'
import { sendNotificationAction } from '../../store'
import {
  Container,
  Label,
  StyledEntity,
  StyledInputWrapper,
  StyledTextArea,
  StyledTitle,
  Title,
  Error,
  StyledButton,
} from './styled'
import { TForm, TLoginValidationSchema } from './types'

const Notifications: FC = () => {
  const dispatch = useDispatch()

  const [form, setForm] = useState<TForm>({
    title: '',
    message: '',
  })

  const inputHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setForm(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const sendMessage = async () => {
    try {
      enableValidation()
      await validation()

      const result = window.confirm('Отправить уведомление?')

      if (result) {
        dispatch(
          sendNotificationAction.request({
            data: {
              title: form.title,
              message: form.message,
            },
          }),
        )
      }

      disableValidation()
    } catch (error) {
      console.log(error)
    }
  }

  //eslint-disable-next-line
  const schema = useMemo<TLoginValidationSchema>(
    () => ({
      title: {
        condition: !!form.title,
        error: 'Введите название',
      },
      message: {
        condition: !!form.message,
        error: 'Введите сообщение',
      },
    }),
    [form],
  )

  const {
    validationSchema,
    validation,
    enableValidation,
    disableValidation,
    isEnabled,
    //eslint-disable-next-line
  } = useValidation(schema)

  return (
    <Container>
      <StyledTitle>
        <Title>Уведомления</Title>
      </StyledTitle>

      <Divider height={30} />

      <StyledEntity>
        <Input
          label="Название"
          name="title"
          value={form.title ?? ''}
          placeholder="Введите название"
          template={EInputTemplate.DEFAULT}
          error={schema.title.error || ''}
          //@ts-ignore
          onChange={inputHandler}
          isValid={!isEnabled || validationSchema.title}
        />
      </StyledEntity>

      <Divider height={10} />

      <StyledInputWrapper>
        <Label>Уведомление</Label>

        <Divider height={10} />

        <StyledTextArea
          required
          name="message"
          placeholder="Введите уведомление"
          value={form.message ?? ''}
          //@ts-ignore
          onChange={inputHandler}
        ></StyledTextArea>

        <Divider height={5} />

        <Error>
          {isEnabled && !validationSchema.message
            ? schema.message.error || ''
            : ''}
        </Error>
      </StyledInputWrapper>

      <Divider height={30} />

      <StyledButton onClick={sendMessage}>Отправить</StyledButton>

      <Divider height={30} />
    </Container>
  )
}

export default Notifications
